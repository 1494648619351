import React, { useState, useEffect } from "react";
import "../../assets/style.css";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import $ from "jquery";
import TradingView from "./tradeChart/trading-view";
import Coin from "../../assets/images/coin-game.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config";
import { useContextData } from "../../core/context";
import { toast } from "../../core/lib/toastAlert";
import "../../pagination.css";
import moment from "moment";
import socketIOClient from "socket.io-client";
import CustomPagination from "./CustomPagination";
import { Tab } from 'bootstrap';
import { useNavigate } from "react-router-dom";




export default function Prediction(props) {

  const values = [500, 750, 1000];
  const { myProfile } = useContextData();
  const [wager, setWager] = useState(0);
  const [actualWager, setActualWager] = useState(0);
  const [direction, setDirection] = useState("Calls");
  const [errorMessage, setErrorMessage] = useState("");
  const [bustPrice, setBustPrice] = useState(0.0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [activeBets, setActiveBets] = useState([]);
  const [userId, setUserId] = useState("");
  //==============Socket Io====
  const [socketConnection, setSocketConnection] = useState(null);
  const [apiMarketPrice, setMarketPrice] = useState(0);
  const [highPrice24h, setHighPrice24h] = useState([]);
  const [lowPrice24h, setLowPrice24h] = useState([]);
  const [turnover24h, setTurnover24h] = useState([]);
  const [getUserGamePrediction, setGetUserGamePrediction] = useState([]);
  const [userGameWalletBal, setUserGameWalletBal] = useState(null);
  const [flatFeeCalculation, setFlatFeeCalculation] = useState(null);
  const [exactFeeAmt, setExactFeeAmt] = useState(null);
  const [closeBetData, setCloseBetData] = useState([]);
  const [closeBetTotal, setCloseBetTotal] = useState(0);
  const [isCloseButtonDisabled, setIsCloseButtonDisabled] = useState(false);
  const [disabledButtonId, setDisabledButtonId] = useState(null);
  const [maintainMultiplier, setMaintainMultiplier] = useState(0);
  const [userManualInput, setUserManualInput] = useState(0);
  const recordsPerPage = 5; // Set the number of records to display per page
  const [activeBetData, setActiveBetData] = useState([]);
  const [activeBetTotal, setactiveBetTotal] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [closeBetPage, setCloseBetPage] = useState(1);
  const [pairData, setPairData] = useState([]);
  const [pairImages, setPairImages] = useState([]);
  const [selectedPair, setSelectedPair] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [marketPrices, setMarketPrices] = useState([]);
  const [previousPrice, setPreviousPrice] = useState(null);
  const [color, setColor] = useState('black');
  const [optionFlatFee, setoptionFlatFee] = useState(0);
  const [closeTime, setCloseTime] = useState(0);
  const [priceDecimal, setPriceDecimal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (previousPrice !== null) {
      // Compare current price with the previous price
      if (apiMarketPrice > previousPrice) {
        setColor('green');
      } else if (apiMarketPrice < previousPrice) {
        setColor('red');
      }
    }
    // Update previous price
    setPreviousPrice(apiMarketPrice);
  }, [apiMarketPrice, previousPrice]);

  //** user Id fetch to using useEffect */
  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      if (myProfile && myProfile._id) {
        setUserId(myProfile._id);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      if (marketPrices) {
        setListData();
      }
    }
  }, [marketPrices]);


  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      getCurrency();
      getTradingData();
    }
  }, []);


  useEffect(() => {
    if (currencySymbol) {
      // userGameWalletBalance(currencySymbol);
      getAllPairData(currencySymbol);
    }
  }, [currencySymbol]);

  // socket load //
  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      if (socketConnection != null) {
        // loadSocket();
        calculateBustPrice(apiMarketPrice, userManualInput, currentStepIndex, direction)
      }

    }
  }, [socketConnection, bustPrice, wager, currentStepIndex, userManualInput, apiMarketPrice, direction]);

  /*  pagination functionalities */
  const noOfPagesActiveBet = Math.ceil(activeBetTotal / recordsPerPage);

  const noOfPagesCloseBet = Math.ceil(closeBetTotal / recordsPerPage);

  const clickPageNo = (pgNumber, betType) => {
    pageChange(pgNumber, betType);
  };

  const handleButtonClick = (direction) => {
    setDirection(direction);
  };

  const pageChange = (newCurrentPage, betType) => {
    if (betType == "activeBets") {
      if (newCurrentPage >= 1 && newCurrentPage <= Math.ceil(activeBetTotal / recordsPerPage)) {
        setActivePage(newCurrentPage);
        getPagination(newCurrentPage, betType);
      }
    } else if (betType == "closeBets") {
      if (newCurrentPage >= 1 && newCurrentPage <= Math.ceil(closeBetTotal / recordsPerPage)) {
        setCloseBetPage(newCurrentPage);
        getPagination(newCurrentPage, betType);
      }
    }
  };

  const getPagination = async (page, betType) => {
    try {
      const userId = myProfile ? myProfile._id : "";
      const startIndex = (activePage - 1) * recordsPerPage;
      const startIndex1 = (closeBetPage - 1) * recordsPerPage;
      let activeBetStatus = getUserGamePrediction.filter(
        (activebet) => activebet.predictionStatus == 0
      );
      let closeBetStatus = getUserGamePrediction.filter(
        (closebet) => closebet.predictionStatus == 1
      );

      const paginatedData = activeBetStatus.slice(
        startIndex,
        startIndex + recordsPerPage
      );
      const paginatedData1 = closeBetStatus.slice(
        startIndex1,
        startIndex1 + recordsPerPage
      );

      if (paginatedData && paginatedData.length > 0) {

        setActiveBetData(paginatedData);
        setactiveBetTotal(activeBetStatus.length);
      }
      if (paginatedData1 && paginatedData1.length > 0) {

        setCloseBetData(paginatedData1);
        setCloseBetTotal(closeBetStatus.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts or when currentPage changes
    getPagination();
  }, [activePage, closeBetPage]);


  function toFixedValue(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }


  const bustPriceCalc = async (newBustVal, index) => {
    if (wager)
      var payoutMultiplier = await payoutMultiplierData(index);
    let newBustPrice = newBustVal
      ? newBustVal * payoutMultiplier
      : wager * payoutMultiplier;
    let recentBust = newBustVal
      ? newBustVal / newBustPrice
      : wager / newBustPrice;

    let findBitAmt = apiMarketPrice * recentBust;

    let total,
      val = "",
      newFlatFee;
    if (direction == "Calls") {
      total = apiMarketPrice - findBitAmt;
    } else {
      total = apiMarketPrice + findBitAmt;
    }
    if (isNaN(Number(total)) || total == 0 || total == "") {
      val = 0.0;
    } else {
      val = total;
    }
  };

  const betDataSeperated = async () => {
    try {
      if (getUserGamePrediction && getUserGamePrediction?.length > 0) {
        const startIndex = (activePage - 1) * recordsPerPage;
        const startIndex1 = (closeBetPage - 1) * recordsPerPage;
        let activeTable = getUserGamePrediction.filter(
          (activeData) => activeData.predictionStatus == 0
        );
        let closeTable = getUserGamePrediction.filter(
          (activeData) => activeData.predictionStatus == 1
        );
        const paginatedData = activeTable.slice(
          startIndex,
          startIndex + recordsPerPage
        );
        const paginatedData1 = closeTable.slice(
          startIndex1,
          startIndex1 + recordsPerPage
        );

        if (paginatedData && paginatedData.length > 0) {

          setActiveBetData(paginatedData);
          setactiveBetTotal(activeTable.length);
        } else {
          setActiveBetData(paginatedData);
          setactiveBetTotal(activeTable.length);
        }
        if (paginatedData1 && paginatedData1.length > 0) {

          setCloseBetData(paginatedData1);
          setCloseBetTotal(closeTable.length);
        } else {
          setCloseBetData(paginatedData1);
          setCloseBetTotal(closeTable.length);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      betDataSeperated();
    }
  }, [getUserGamePrediction]);
  //** render calculation *//

  //===============For Click function=================//
  const handleHalfButtonClick = async () => {

    let decrementedValue = wager / 2; // Wager value divided by 2
    if (decrementedValue >= 1) {
      let roundedValue = decrementedValue.toFixed(2);
      setWager(roundedValue); //
      setUserManualInput(roundedValue);
      setErrorMessage("");
      let payoutMultiplier = await payoutMultiplierData();

      await bustPriceCalc();
      //======Fee Calculcation========
      let findNewFeeCal = decrementedValue * payoutMultiplier;
      let flatFeeAmt = ((findNewFeeCal * optionFlatFee) / 100).toFixed(2);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (
        Number(decrementedValue) + Number(flatFeeAmt)
      ).toFixed(1);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    }
  };

  const handleDoubleButtonClick = async () => {
    let doubledValue = wager * 2;
    setWager(doubledValue);
    setUserManualInput(doubledValue);
    setErrorMessage("");

    await bustPriceCalc();
    let payoutMultiplier = await payoutMultiplierData();

    //=======Fee calculation==============

    let findNewFeeCal = doubledValue * payoutMultiplier;
    let flatFeeAmt = ((findNewFeeCal * optionFlatFee) / 100).toFixed(2);
    setExactFeeAmt(flatFeeAmt);
    let finalFlatFee = (Number(doubledValue) + Number(flatFeeAmt)).toFixed(2);
    setFlatFeeCalculation(toFixedValue(finalFlatFee));
  };

  const handleWagerChange = async (e) => {
    let newValue = e.target.value; // target value of wager

    if (newValue.includes(".")) {
      const parts = newValue.split(".");
      if (parts.length > 2) {
        return; // Do not set the value if it contains multiple dots
      }
    }

    if (newValue == 0) {
      setUserManualInput(0);
    } else {
      setUserManualInput(newValue);
      // loadSocket()
    }

    setWager(newValue);
    await bustPriceCalc(newValue, currentStepIndex);
    var payoutMultiplier = await payoutMultiplierData();
    let newBustPrice = newValue * payoutMultiplier;
    //=======Flat Fee Calculation===========//
    let flatFeeAmt = ((newBustPrice * optionFlatFee) / 100).toFixed(2);
    setExactFeeAmt(flatFeeAmt);
    let finalFlatFee = (Number(newValue) + Number(flatFeeAmt)).toFixed(2);
    setFlatFeeCalculation(toFixedValue(finalFlatFee));
  };


  const payoutMultiplierData = async (index) => {
    if (index) {
      return values[index];
    } else return values[currentStepIndex];
  };


  //change range value
  const handleRangeChange = async (e) => {
    let index = parseInt(e.target.value);
    if (index) setCurrentStepIndex(index);
    else setCurrentStepIndex(0);
    await bustPriceCalc(wager, index);
    let payoutMultiplier = await payoutMultiplierData(index);
    // let payoutMultiplier = values[index];
    let newBustPrice = wager * payoutMultiplier;
    let flatFeeAmt = ((newBustPrice * optionFlatFee) / 100).toFixed(2);
    // console.log("flatFeeAmt==========", flatFeeAmt);
    setExactFeeAmt(flatFeeAmt);
    let finalFlatFee = (Number(wager) + Number(flatFeeAmt)).toFixed(2);
    setFlatFeeCalculation(toFixedValue(finalFlatFee));
  };


  const handleWagerPaste = (e) => {
    e.preventDefault();
  };

  // finally Placebet function
  async function handlePlaceBet(pay) {
    if (isButtonDisabled) {
      return; // Do nothing if the button is already disabled
    }
    try {
      setDirection(pay)
      const calculatedBustPrice = await calculateBustPrice(apiMarketPrice, userManualInput, currentStepIndex, pay);

      const payload = {
        wager: wager,
        actualWager: actualWager,
        direction: pay,
        userLoginId: userId,
        currencyId: currencySymbol,
        multiplier: values[currentStepIndex],
        bustprice: calculatedBustPrice,
        marketPrice: apiMarketPrice,
        betFeeAmt: exactFeeAmt,
        pair: selectedPair
      };

      const params = {
        url: `${Config.OPTIONS_BEAR_AND_BULL_V1_API_URL}options_game/optionsGame`,
        method: "POST",
        body: payload,
      };
      setButtonDisabled(true);
      const { status, data, error, message } = await makeRequest(params);
      if (status == true) {
        toast({ status: true, message: message });
        const payloadData = {
          userLoginId: userId,
          currencyId: currencySymbol,
        };
        socketConnection.emit("getActiveBets", payloadData);
        // profitCalcultion();
        // userGameWalletBalance(currencySymbol);
        setButtonDisabled(false);

        const activeBetsTab = new Tab(document.querySelector('#pills-all-bets-tab'));
        activeBetsTab.show();


        // Reload the current page
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } else {
        toast({ status: false, message: message });
        const payloadData = {
          userLoginId: userId,
          currencyId: currencySymbol,
        };
        socketConnection.emit("getActiveBets", payloadData);
        setButtonDisabled(false);
      }
    } catch (err) {
      console.log("error", err);
      setButtonDisabled(false);
    }
  }

  /// Prediction History //
  const profitCalcultion = async (data) => {
    try {

      const response = data;
      // console.log("data", data)
      if (response) {
        setActiveBets(response);
      }
      else {
        setActiveBets([]);
      }
    } catch (error) {
      console.log("error", error);
    }

  };

  const userGameWalletBalance = async (data) => {

    try {
      const response = data;

      if (response.status == true) {
        let balanceAmt = response.data ? response.data.optionsGameAmount : 0;
        let formattedBalance = Math.floor(balanceAmt * 100) / 100; // Truncate to two decimal places without rounding
        if (userId) {
          setUserGameWalletBal(formattedBalance.toFixed(2));
        } else {
          setUserGameWalletBal(0);
        }

      }
    } catch (error) {
      console.log("error--", error);
    }
  };


  const getCurrency = async () => {
    try {
      const params = {
        url: `${Config.OPTIONS_BEAR_AND_BULL_V1_API_URL}options_game/getCurrencySymbol`,
        method: "GET",
      };

      const response = await makeRequest(params);
      // let currencySymbol = response && response.data && response.data._id ? response.data._id : "";
      if (response && response.status == true) {
        const responseData = response.data;
        setCurrencySymbol(responseData._id);
      } else {
        setCurrencySymbol(null);
      }
    } catch (error) {
      console.error("Error fetching currency:", error);
    }

  }


  const getAllPairData = async (Symbol) => {
    try {
      const payload = {
        currencyId: Symbol,
      };

      const params = {
        url: `${Config.OPTIONS_BEAR_AND_BULL_V1_API_URL}options_game/getAllPairs`,
        method: "POST",
        body: payload
      };
      const response = await makeRequest(params);
      if (response && response.status == true) {
        setPairData(response.data);
        const images = response.AggregatePairData.map((item) => item.result.image);
        setPairImages(images);

        if (response.data.length > 0) {
          setSelectedPair(response.data[0]);
          setSelectedImage(images[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching currency:", error);
    }
  }


  // useEffect(() => {
  //   if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
  //     // profitCalcultion();
  //     // userGameWalletBalance(currencySymbol);
  //     findFlatFee();
  //   }
  // }, [myProfile, apiMarketPrice]);

  //=================Socket Connection================

  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      let socket = socketIOClient(Config.OPTIONS_BEAR_AND_BULL_SOCKET_URL, {
        transports: ["websocket"],
      });

      let socketUnsubscribe;
      if (socket) {
        socket.on("connect", function () {
          console.log("socket connected");
          setSocketConnection(socket);
          socketUnsubscribe = socket;

          if (currentStepIndex == 0) {
            setMaintainMultiplier(0);
          } else if (currentStepIndex == 1) {
            setMaintainMultiplier(1);
          } else if (currentStepIndex == 2) {
            setMaintainMultiplier(2);
          }
        });
        socket.on("connect_error", (err) => {
          console.log("socket connect_error", err);
        });
        socket.on("disconnect", function () {
          console.log("socket disconnected");
        });
      }
      return () => {
        if (socketUnsubscribe) {
          socketUnsubscribe.disconnect();
        }
      };
    }
  }, []);


  useEffect(() => {
    let interval;
    const payload = {
      userLoginId: userId,
      currencyId: currencySymbol,
    };
    if (socketConnection != null) {
      socketConnection.on("betClosed", (data) => {
        if (data.userId == userId) {
          toast({ message: data.message });
          // profitCalcultion();
          // userGameWalletBalance(currencySymbol);
          socketConnection.emit("getActiveBets", payload);
          betDataSeperated();
          getPagination();
        }
      });



      interval = setInterval(() => {
        socketConnection.emit("getTotalPairs", "");
        socketConnection.emit("getSelectedPair", selectedPair);
        socketConnection.emit("getUserBalance", payload);
      }, 3000);

      socketConnection.on("getTotalPairsResp", (data) => {
        // console.log("getTotalPairsResp--",data);
        fetchTotalPairs(data);
      })

      socketConnection.on("getSelectedPairResp", (data) => {
        // console.log("getSelectedPairResp--",data);
        fetchSelectedPairData(data);
      })

      socketConnection.on("getUserBalanceResp", (data) => {
        // console.log("getUserBalanceResp--",data);
        let Userdata = data;
        if (Userdata && Userdata.data.userId == userId) {
          userGameWalletBalance(data);
        }
      })
      socketConnection.emit("getActiveBets", payload);
      socketConnection.on("getActiveBetsResp", (data) => {
        // console.log("getActiveBetsResp--",data.data);
        let filterData = data.data.filter(element => userId == element.userId);
        profitCalcultion(filterData);
      })


      return () => {
        if (interval) clearInterval(interval);
        if (socketConnection) {
          socketConnection.off("getTotalPairsResp");
          socketConnection.off("getSelectedPairResp");
          socketConnection.off("getUserBalanceResp");
          socketConnection.off("getActiveBetsResp");
        }
      };
    }
  }, [socketConnection, selectedPair, apiMarketPrice])


  const fetchSelectedPairData = async (data) => {
    try {
      const response = data;
      // console.log("response--", response);

      if (response && response.status == true) {
        const data = response.data[0];
        if (data) {
          // setMarketPrice(Number(parseFloat(data.marketPrice).toFixed(data.priceDecimal)));
          setMarketPrice(parseFloat(data.marketPrice).toFixed(data.priceDecimal));
          setTurnover24h(parseFloat(data.volume_fromCur).toFixed(data.priceDecimal));
          setHighPrice24h(parseFloat(data.high).toFixed(data.priceDecimal));
          setLowPrice24h(parseFloat(data.low).toFixed(data.priceDecimal));
          setPriceDecimal(data.priceDecimal);
          if (currentStepIndex) multiplayerRangeSet(data);
          let newBustPrice = 0;
          if (maintainMultiplier === 0 && userManualInput !== 0) {
            newBustPrice = userManualInput * 500;
          } else if (maintainMultiplier === 1 && userManualInput !== 0) {
            newBustPrice = userManualInput * 750;
          } else if (maintainMultiplier === 2 && userManualInput !== 0) {
            newBustPrice = userManualInput * 1000;
          }
          let recentBust = 0;
          if (userManualInput == 0) {
            recentBust = 0;
          } else {
            recentBust = userManualInput / newBustPrice;
          }
          if(data && data.pair){
            document.title = data.marketPrice + " | " + data.pair + " | " + Config.SITENAME;
          }else{
            document.title = "BitNevex Exchange"
          }
          
        }
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  const fetchTotalPairs = async (data) => {
    try {
      const response = data;
      // console.log("fetchTotalPairs-rsp--",response);

      if (response?.status && response?.activePairList?.msg) {
        const prices = response.activePairList.msg.map(pair => ({
          pair: pair.pair,
          marketPrice: pair.marketPrice.toFixed(priceDecimal),
        }));
        setMarketPrices(prices); // Set market prices in state
      }

    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  }

  const getTradingData = async () => {
    try {
      const params = {
        url: `${Config.OPTIONS_BEAR_AND_BULL_V1_API_URL}options_game/getTradeingData`,
        method: "GET",
      };

      const response = await makeRequest(params);
      if (response && response.status == true) {
        const responseData = response.data[0];
        setoptionFlatFee(responseData.optionsFlatFee);
        setCloseTime(responseData.optionsTiming)
      } else {
        setoptionFlatFee(0);
        setCloseTime(0)

      }
    } catch (error) {
      console.error("Error fetching currency:", error);
    }

  }


  const setListData = async () => {
    try {
      if (activeBets && activeBets.length > 0) {
        var addData = [];
        var loss = [];
        for (let resData of activeBets) {
          if (resData.predictionStatus === 0) {
            const marketPriceData = marketPrices.find((pair) => pair.pair === resData.pairName);
            if (marketPriceData) {
              const actualMarketPrice = marketPriceData.marketPrice;
              let newBustPrice = resData.actualpredictionAmt * resData.multiplier;
              let profitAmt = newBustPrice / resData.marketprice;
              let calProfit;
              let calProfitPrc = null;
              if (resData.direction == "Calls" && resData.predictionStatus == 0) {
                calProfit = profitAmt * (actualMarketPrice - resData.marketprice);
                calProfitPrc = Number(calProfit.toFixed(2));

                if (Number(calProfitPrc) < 0 && Math.abs(calProfitPrc) > Number(resData.actualpredictionAmt)) {
                  // Close the bet if P&L is greater than the bet price
                  await handleClick(resData._id, calProfitPrc, "autoClosed");
                  continue;
                }
              } else if (resData.direction == "Puts" && resData.predictionStatus == 0) {
                calProfit = profitAmt * (resData.marketprice - actualMarketPrice);
                calProfitPrc = Number(calProfit.toFixed(2));
                if (Number(calProfitPrc) < 0 && Math.abs(calProfitPrc) > Number(resData.actualpredictionAmt)) {
                  // Close the bet if P&L is greater than the bet price
                  await handleClick(resData._id, calProfitPrc, "autoClosed");
                  continue;
                }
              }

              resData.profitAndLoss = calProfitPrc.toFixed(2);
              if (calProfitPrc !== null) {
                resData.profitAndLoss = calProfitPrc.toFixed(2);
              }
            }
          }
          // Object.assign(resData);
          addData.push(resData);
        }

        if (addData && addData?.length > 0) {
          setGetUserGamePrediction(addData);
        }
      }
    } catch (error) {
      console.log("error", error);
    }


  };

  const multiplayerRangeSet = async (data) => {
    let payoutMultiplier = await payoutMultiplierData();
    let newBustPrice = wager * payoutMultiplier;
    let recentBust = wager / newBustPrice;
    let findBitAmt = Number(data.marketPrice) * recentBust;
    let total = Number(data.marketPrice) - findBitAmt;
  };


  async function calculateBustPrice(marketPrice, userInput, multiplierSta, dir) {

    let marketPriceData = parseFloat(marketPrice);
    let newBustPrice = 0;
    let userEnterVal = Number(userInput)
    let multiplier = multiplierSta


    if (multiplier === 0 && userEnterVal !== 0) {
      newBustPrice = userEnterVal * 500;
      let flatFeeAmt = ((newBustPrice * optionFlatFee) / 100).toFixed(2);
      let actualWagerAmt = userEnterVal - flatFeeAmt;
      setActualWager(actualWagerAmt);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (Number(userEnterVal) + Number(flatFeeAmt)).toFixed(2);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    } else if (multiplier === 1 && userEnterVal !== 0) {
      newBustPrice = userEnterVal * 750;
      let flatFeeAmt = ((newBustPrice * optionFlatFee) / 100).toFixed(2);
      let actualWagerAmt = userEnterVal - flatFeeAmt;
      setActualWager(actualWagerAmt);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (Number(userEnterVal) + Number(flatFeeAmt)).toFixed(2);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    } else if (multiplier === 2 && userEnterVal !== 0) {
      newBustPrice = userEnterVal * 1000;
      let flatFeeAmt = ((newBustPrice * optionFlatFee) / 100).toFixed(2);
      let actualWagerAmt = userEnterVal - flatFeeAmt;
      setActualWager(actualWagerAmt);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (Number(userEnterVal) + Number(flatFeeAmt)).toFixed(2);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    }

    let recentBust = 0;
    if (userEnterVal == 0) {
      recentBust = 0
    } else {
      recentBust = userEnterVal / newBustPrice;
    }

    let findBitAmt = marketPriceData * recentBust;
    let total = dir === 'Calls' ? marketPriceData - findBitAmt : marketPriceData + findBitAmt;

    const bustPrice = userEnterVal === 0 ? 0 : parseFloat(total.toFixed(priceDecimal));
    setBustPrice(bustPrice);
    return bustPrice;
  }

  const findFlatFee = async () => {
    let payoutMultiplier = await payoutMultiplierData();
    let wagerAndMultiplier = wager * payoutMultiplier;
    let flatFeeCalc = (wagerAndMultiplier * optionFlatFee) / 100;
    let finalFlatFeeAmt = wager + flatFeeCalc;
    setFlatFeeCalculation(toFixedValue(finalFlatFeeAmt));
  };

  const handlePairChange = (e) => {
    const selectedPair = e.target.value;
    setSelectedPair(selectedPair);
    const index = pairData.indexOf(selectedPair);
    if (index !== -1) {
      setSelectedImage(pairImages[index]);
    }
  };

  const formatPair = (pair) => {
    return pair.replace("_", "/");
  };

  const handleClick = async (id, value, positionStr) => {
    if (!isCloseButtonDisabled) {
      try {
        const payload = {
          predictionId: `${id}`,
          profitAndLoss: `${value}`,
          exitPrice: `${apiMarketPrice}`,
          userLoginId: `${userId}`,
          currencyId: `${currencySymbol}`,
        };

        const params = {
          url: `${Config.OPTIONS_BEAR_AND_BULL_V1_API_URL}options_game/userClosedBet`,
          method: "POST",
          body: payload,
        };

        const { status, data, error, message } = await makeRequest(params);
        setIsCloseButtonDisabled(true);
        setDisabledButtonId(id);
        if (positionStr == "autoClosed" && status == true) {
          setIsCloseButtonDisabled(false);
          toast({ status: true, message: message });
          const payloadData = {
            userLoginId: userId,
            currencyId: currencySymbol,
          };
          socketConnection.emit("getActiveBets", payloadData);
          // profitCalcultion();
          // userGameWalletBalance(currencySymbol);
          betDataSeperated();
          getPagination();
        } else if (positionStr == "handleClose" && status == true) {
          setIsCloseButtonDisabled(false);
          toast({ status: true, message: message });
          const payloadData = {
            userLoginId: userId,
            currencyId: currencySymbol,
          };
          socketConnection.emit("getActiveBets", payloadData);
          // profitCalcultion();
          // userGameWalletBalance(currencySymbol);
          betDataSeperated();
          getPagination();
        }
      } catch (err) {
        setIsCloseButtonDisabled(false);
        toast({ status: false, message: err });
      }
    }
  };

  useEffect(() => {
    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      $(".status_change .dropdown-item").click(function () {
        var getStatusText = $(this).text();
        $(this)
          .closest(".status_dropdown")
          .find(".status__btn")
          .text(getStatusText);
        var generateStatusClass = `${$(this).attr("data-className")}-status`;
        $(this)
          .closest(".status_dropdown")
          .attr("data-color", `${generateStatusClass}`);
      });
    }
  }, []);
  useEffect(() => {
    $(document).ready(function () {
      $("#flexRadioDefault4").click(function () {
        window.location.reload(false);
      });
      $("#flexRadioDefault2").click(function () {
        window.location.reload(false);
      });
    });

    if (Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable") {
      $(document).ready(function () {
        $(".prediction-trade-settings-section .dropdown-menu").click(function (
          e
        ) {
          e.stopPropagation();
        });
        $("#features-dropdown-open").click(function () {
          $(".features-dropdown-hidden").hide();
          $("#features-dropdown-open").hide();
          $("#features-dropdown-close").show();
        });
        $("#features-dropdown-close").click(function () {
          $(".features-dropdown-hidden").show();
          $("#features-dropdown-open").show();
          $("#features-dropdown-close").hide();
        });
        $("#features-chats-section-close").click(function () {
          $(".prediction-page-right-section").css("width", "0%");
          $(".prediction-page-middle-section").css("width", "87%");
        });
      });
    }
  });
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="prediction-trade-top-banner trade-active-nav-class">
        <div className="container-fluid p-0">
          <div className="d-flex flex-lg-row flex-column gap-4">
            <div className="container-fluid prediction-trade-right-section p-0">
              <div className=" d-flex flex-lg-row flex-column gap-3">
                <div className="prediction-trade-right-1 pt-4">
                  <div className="d-flex flex-lg-row flex-column mb-3 align-items-lg-center gap-3">
                    <div>
                      {selectedImage && (
                        <div>
                          <img className="prediction-trade-image-1" src={selectedImage} alt={selectedPair} />
                        </div>
                      )}
                    </div>

                    <div>
                      <div className="dropdown post-d-card-1-1">
                        <select className="form-select w-auto" value={selectedPair} onChange={handlePairChange}>
                          {pairData.map((pair, index) => (
                            <option key={index} value={pair}>
                              {formatPair(pair)}
                            </option>
                          ))}
                        </select>
                      </div>


                    </div>
                    <div>
                      {/* <span>{currencydataSymbol}/USDT </span> */}
                      <span className="ms-lg-2" style={{ color }}>{apiMarketPrice}</span>
                    </div>
                    <div className="d-flex gap-2">
                      <span className="prediction-trade-text-2 mb-0">
                        <span className="prediction-trade-text-3">
                          24h Volume:
                        </span>{" "}
                        {turnover24h}{" "}
                      </span>
                      <span className="prediction-trade-text-2 mb-0">
                        <span className="prediction-trade-text-3">H:</span>{" "}
                        <span style={{ color: "lightgreen" }}>
                          {highPrice24h}
                        </span>{" "}
                      </span>
                      <span className="prediction-trade-text-2 mb-0">
                        <span className="prediction-trade-text-3">L:</span>{" "}
                        <span style={{ color: "red" }}>{lowPrice24h}</span>{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    id="tradingviewwidget-linechart"
                    className="position-relative"
                  >
                    <TradingView theme={props.theme} symbol={selectedPair.split('_').join('/')} />
                  </div>
                </div>
                <div className="prediction-trade-right-2">
                  {/* <h4 className="benefits-text-1">MANUAL</h4> */}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-manual-tabs"
                      role="tabpanel"
                      aria-labelledby="pills-manual-tabs-tab"
                      tabindex="0"
                    >
                      {/* <span className="prediction-trade-text-4">
                        WILL THE PRICE GO UP OR DOWN?
                      </span> */}
                      {/* <div className="prediction-manual-updown-tabs">
                        <ul
                          className="nav nav-pills mb-3 nav-fill mt-2"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => handleButtonClick("Calls")}
                          >
                            <button
                              className="nav-link manual-active-up active"
                              id="pills-manual-up-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-manual-up"
                              type="button"
                              role="tab"
                              aria-controls="pills-manual-up"
                              aria-selected="true"
                            >
                              <MdKeyboardDoubleArrowUp className="prediction-trade-text-5" />
                              Calls
                            </button>
                          </li>
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => handleButtonClick("Puts")}
                          >
                            <button
                              className="nav-link manual-active-down"
                              id="pills-manual-down-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-manual-down"
                              type="button"
                              role="tab"
                              aria-controls="pills-manual-down"
                              aria-selected="false"
                            >
                              <MdKeyboardDoubleArrowDown className="prediction-trade-text-5" />
                              Puts
                            </button>
                          </li>
                        </ul>
                      </div> */}

                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-manual-up"
                          role="tabpanel"
                          aria-labelledby="pills-manual-up-tab"
                          tabindex="0"
                        >
                          <span className="prediction-trade-text-4">
                            INVESTMENT AMOUNT
                          </span>
                          <div className="input-group my-2 prediction-trade-updown-input prediction-overrall-border">
                            {/* <span className="input-group-text">
                              <img
                                className="prediction-manual-coin"
                                src={Coin}
                                alt="coin"
                              />
                            </span> */}
                            <input
                              type="text"
                              className="form-control"
                              value={wager}
                              aria-label="Example text with button addon"
                              aria-describedby="button-addon1"
                              onChange={handleWagerChange}
                              onKeyPress={(event) => {
                                const keyCode = event.which || event.keyCode;
                                const keyValue = String.fromCharCode(keyCode);

                                // Allow only numeric values (0-9) and the backspace key (8)
                                if (!/^[0-9\b.]+$/.test(keyValue)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handleWagerPaste}
                            />
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleHalfButtonClick}
                            >
                              1/2
                            </button>
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleDoubleButtonClick}
                            >
                              x2
                            </button>
                          </div>
                          <span className="prediction-trade-text-4">
                            MULTIPLIER
                          </span>
                          <div className="row align-items-center mt-2 g-2">
                            <div className="col-lg-12">
                              <div className="input-group prediction-trade-updown-input py-1 prediction-overrall-border">
                                {/* <span className="input-group-text">
                                  <img
                                    className="prediction-manual-coin"
                                    src={Coin}
                                    alt="coin"
                                  />
                                </span> */}
                                <input
                                  type="text"
                                  className="form-control"
                                  value={values[currentStepIndex]}
                                  aria-label="Example text with button addon"
                                  aria-describedby="button-addon1"
                                  disabled
                                />
                              </div>
                            </div>

                          </div>
                          <div className="d-flex justify-content-end mt-2">
                            <div className="col-lg-6">
                              <p className="mb-0 prediction-trade-text-6">
                                Liquidation Price:
                              </p>
                              <p className="mb-0 prediction-trade-text-6">
                                {bustPrice === 0 || isNaN(bustPrice) ? (
                                  <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                ) : (
                                  bustPrice
                                )}
                              </p>
                            </div>
                            <div className="col-lg-6 text-end">
                              <button
                                className="prediction-updown-button-1"
                                type="button"
                                onClick={() => handleButtonClick("Calls")}
                                style={{ opacity: direction === "Calls" ? 1 : 0.6 }}
                              >
                                Calls
                              </button>
                              <button
                                className="prediction-updown-button-2"
                                type="button"
                                onClick={() => handleButtonClick("Puts")}
                                style={{ opacity: direction === "Puts" ? 1 : 0.6 }}
                              >
                                Puts
                              </button>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="">
                              <input
                                className="form-range-prediction"
                                onInput={handleRangeChange}
                                type="range"
                                min="0"
                                value={currentStepIndex}
                                max="2"
                                step="1"
                                list="tick-list"
                              />
                            </div>
                            <div className="d-flex flex-row-reverse ">
                              <div>
                                {values[currentStepIndex] == 500 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#0c491d" }}>
                                      Safe
                                    </span>
                                    : x500
                                  </span>
                                )}
                                {values[currentStepIndex] == 750 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f2a819" }}>
                                      Moderate
                                    </span>
                                    : x750
                                  </span>
                                )}
                                {values[currentStepIndex] == 1000 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f23838" }}>
                                      Risk
                                    </span>
                                    : x1000
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <button
                            type="button"
                            className="prediction-bet-button-up mt-3"
                            disabled={isButtonDisabled || wager < 1}
                            onClick={() => {

                              if (userId) {
                                handlePlaceBet("Calls");
                              } else {
                                navigate("/login")
                              }

                            }}
                          >
                            <MdKeyboardDoubleArrowUp className="prediction-trade-text-5" />
                            CALLS
                          </button>

                          <button
                            type="button"
                            className="prediction-bet-button-down mt-3"
                            disabled={isButtonDisabled || wager < 1}
                            onClick={() => {

                              if (userId) {
                                handlePlaceBet("Puts");
                              } else {
                                navigate("/login")
                              }

                            }}
                          >
                            <MdKeyboardDoubleArrowDown className="prediction-trade-text-5" />
                            PUTS
                          </button>
                          {wager < 1 && (
                            <p className="error-message">
                              * Wager must be at least $1
                            </p>
                          )}
                          <div className="mt-3">
                            <span>Flat Fee : {optionFlatFee}%</span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              We need to deduct {exactFeeAmt || 0} USDT from your
                              placing
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              Position will close in {closeTime} minute.
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 mb-0">
                              <span className="prediction-trade-text-3">
                                Option Wallet Balance:
                              </span>{" "}
                              {userGameWalletBal ? userGameWalletBal : 0} USDT{" "}
                            </span>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-manual-down"
                          role="tabpanel"
                          aria-labelledby="pills-manual-down-tab"
                          tabindex="0"
                        >
                          <span className="prediction-trade-text-4">
                            INVESTMENT AMOUNT
                          </span>
                          <div className="input-group my-2 prediction-trade-updown-input prediction-overrall-border">
                            {/* <span className="input-group-text">
                              <img
                                className="prediction-manual-coin"
                                src={Coin}
                                alt="coin"
                              />
                            </span> */}
                            <input
                              type="text"
                              className="form-control"
                              value={wager}
                              aria-label="Example text with button addon"
                              aria-describedby="button-addon1"
                              onChange={handleWagerChange}
                              onKeyPress={(event) => {
                                const keyCode = event.which || event.keyCode;
                                const keyValue = String.fromCharCode(keyCode);

                                // Allow only numeric values (0-9) and the backspace key (8)
                                if (!/^[0-9\b.]+$/.test(keyValue)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handleWagerPaste}
                            />
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleHalfButtonClick}
                            >
                              1/2
                            </button>
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleDoubleButtonClick}
                            >
                              x2
                            </button>
                          </div>
                          <span className="prediction-trade-text-4">
                            MULTIPLIER
                          </span>
                          <div className="row align-items-center mt-2 g-2">
                            <div className="col-lg-6">
                              <div className="input-group prediction-trade-updown-input py-1 prediction-overrall-border">
                                {/* <span className="input-group-text">
                                  <img
                                    className="prediction-manual-coin"
                                    src={Coin}
                                    alt="coin"
                                  />
                                </span> */}
                                <input
                                  type="text"
                                  className="form-control"
                                  value={values[currentStepIndex]}
                                  aria-label="Example text with button addon"
                                  aria-describedby="button-addon1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <p className="mb-0 prediction-trade-text-6">
                                Liquidation Price:
                              </p>
                              <p className="mb-0 prediction-trade-text-6">
                                {bustPrice === 0 || isNaN(bustPrice) ? (
                                  <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                ) : (
                                  bustPrice
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="">
                              <input
                                className="form-range-prediction"
                                onInput={handleRangeChange}
                                type="range"
                                min="0"
                                value={currentStepIndex}
                                max="2"
                                step="1"
                                list="tick-list"
                              />
                            </div>

                            <div className="d-flex flex-row-reverse ">
                              <div>
                                {values[currentStepIndex] == 500 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#0c491d" }}>
                                      Safe
                                    </span>
                                    : x500
                                  </span>
                                )}
                                {values[currentStepIndex] == 750 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f2a819" }}>
                                      Moderate
                                    </span>
                                    : x750
                                  </span>
                                )}
                                {values[currentStepIndex] == 1000 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f23838" }}>
                                      Risk
                                    </span>
                                    : x1000
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          {wager < 1 && (
                            <p className="error-message">
                              * Wager must be at least $1
                            </p>
                          )}
                          <div className="mt-3">
                            <span>Flat Fee : {optionFlatFee}%</span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              We need to deduct {exactFeeAmt || 0} USDT from your
                              placing
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              Position will close in {closeTime} minute.
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 mb-0">
                              <span className="prediction-trade-text-3">
                                Option Wallet Balance:
                              </span>{" "}
                              {userGameWalletBal ? userGameWalletBal : 0} USDT{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prediction-trade-right-section-table-section">
                {userId ? (
                  <div className="row justify-content-center mt-4">
                    <div className="col">
                      <div className="d-flex flex-lg-row flex-column gap-2">
                        <div>
                          <div className="prediction-bets-tabs-section">
                            <ul
                              className="nav nav-pills"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link fs-14 active"
                                  id="pills-all-bets-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-all-bets"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-all-bets"
                                  aria-selected="true"
                                >
                                  Active Position
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link fs-14"
                                  id="pills-lucky-bets-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-lucky-bets"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-lucky-bets"
                                  aria-selected="false"
                                >
                                  Closed Position
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 tab-content" id="nav-tabContent">
                        <div
                          className="prediction-bets-table-section tab-pane fade show active"
                          id="pills-all-bets"
                          role="tabpanel"
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="bets-table-trade-1">
                                  Date
                                </th>
                                <th scope="col" className="bets-table-trade-2">
                                  Pair
                                </th>
                                <th scope="col" className="bets-table-trade-3">
                                  Calls / Puts
                                </th>
                                {/* <th scope="col" className="bets-table-trade-4">
                                FEE
                              </th> */}
                                <th scope="col" className="bets-table-trade-5">
                                  Entry Price
                                </th>
                                <th scope="col" className="bets-table-trade-6">
                                  multiplier
                                </th>
                                <th scope="col" className="bets-table-trade-7">
                                  Investment Amount
                                </th>
                                <th scope="col" className="bets-table-trade-8">
                                  Liquidation Price
                                </th>
                                <th scope="col" className="bets-table-trade-9">
                                  P & L
                                </th>
                                <th scope="col" className="bets-table-trade-10">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeBetData && activeBetData.length > 0 ? (
                                activeBetData.map((active) => {
                                  const isButtonDisabled =
                                    active._id === disabledButtonId &&
                                    isCloseButtonDisabled;
                                  return (
                                    <tr>
                                      <td className="bets-trade-tablebd-1">
                                        {moment(active.dateTime).utc().format("DD-MM-YYYY hh:mm:ss A")}
                                      </td>
                                      <td className="bets-trade-tablebd-2">
                                        {active.pairName.split('_').join('/')}
                                      </td>
                                      <td className="bets-trade-tablebd-3">
                                        {active.direction}
                                      </td>
                                      {/* <td className="bets-trade-tablebd-4">
                                      {active.feeAmount}
                                    </td> */}
                                      <td className="bets-trade-tablebd-5">
                                        {active.marketprice}
                                      </td>
                                      <td className="bets-trade-tablebd-6">
                                        {active.multiplier}
                                      </td>
                                      <td className="bets-trade-tablebd-7">
                                        {active.predictionAmt}
                                      </td>
                                      <td className="bets-trade-tablebd-8">
                                        {active.bustprice}
                                      </td>
                                      <td className={`bets-trade-tablebd-9 ${active.profitAndLoss < 0 ? "negativeVal" : "positiveVal"}`}>
                                      {active.profitAndLoss > 0 ? `+${active.profitAndLoss}` : active.profitAndLoss}
                                        
                                      </td>
                                      <td className="bets-trade-tablebd-10">
                                        {" "}
                                        <button
                                          className={`prediction-updown-button m-0 fs-10 ${isButtonDisabled ? "disabled" : ""
                                            }`}
                                          type="button"
                                          id="button-addon1"
                                          onClick={() =>
                                            handleClick(
                                              active._id,
                                              active.profitAndLoss,
                                              "handleClose"
                                            )
                                          }
                                          disabled={isButtonDisabled}
                                        >
                                          CLOSE
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle" }}>No Active Bet</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {
                            (activeBetTotal > recordsPerPage) && (
                              <div className="row">
                                <div className="col-lg-12 d-flex justify-content-end">
                                  <CustomPagination
                                    className="p2p-trade-pagination"
                                    total={Math.ceil(
                                      activeBetTotal / recordsPerPage
                                    )}
                                    current={activePage}
                                    onPageChange={(page) => clickPageNo(page, "activeBets")}
                                  />
                                </div>
                              </div>
                            )
                          }
                        </div>
                        <div
                          className="prediction-bets-table-section tab-pane fade"
                          id="pills-lucky-bets"
                          role="tabpanel"
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="bets-table-trade-1">
                                  Date
                                </th>
                                <th scope="col" className="bets-table-trade-2">
                                  Pair
                                </th>
                                <th scope="col" className="bets-table-trade-3">
                                  Entry Price
                                </th>
                                <th scope="col" className="bets-table-trade-4">
                                  Calls / Puts
                                </th>
                                <th scope="col" className="bets-table-trade-5">
                                  FEE
                                </th>
                                <th scope="col" className="bets-table-trade-6">
                                  multiplier
                                </th>
                                <th scope="col" className="bets-table-trade-7">
                                  Investment Amount
                                </th>
                                <th scope="col" className="bets-table-trade-8">
                                  Liquidation Price
                                </th>
                                <th scope="col" className="bets-table-trade-9">
                                  Exit Price
                                </th>
                                <th scope="col" className="bets-table-trade-10">
                                  P & L
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {closeBetData && closeBetData.length > 0 ? (
                                closeBetData.map((active) => {
                                  return (
                                    <tr key={active.id}>
                                      <td className="bets-trade-tablebd-1">
                                        {moment(active.dateTime).utc().format("DD-MM-YYYY hh:mm:ss A")}
                                      </td>
                                      <td className="bets-trade-tablebd-2">
                                        {active.pairName.split('_').join('/')}
                                      </td>
                                      <td className="bets-trade-tablebd-3">
                                        {active.marketprice}
                                      </td>
                                      <td className="bets-trade-tablebd-4">
                                        {active.direction}
                                      </td>
                                      <td className="bets-trade-tablebd-5">
                                        {active.feeAmount}
                                      </td>
                                      <td className="bets-trade-tablebd-6">
                                        {active.multiplier}
                                      </td>
                                      <td className="bets-trade-tablebd-7">
                                        {active.predictionAmt}
                                      </td>
                                      <td className="bets-trade-tablebd-8">
                                        {active.bustprice}
                                      </td>
                                      <td className="bets-trade-tablebd-9">
                                        {active.exitMarketPrice}
                                      </td>
                                      <td className="bets-trade-tablebd-10">
                                        {active.winAmt == 0 ? (
                                          <span className="negativeVal">
                                            {active.lossAmt?.toFixed(2)}
                                          </span>
                                        ) : (
                                          <span className="positiveVal">
                                            +{active.winAmt?.toFixed(2)}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="10" style={{ textAlign: "center", verticalAlign: "middle" }}>No Closed Bet</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {
                            (closeBetTotal > recordsPerPage) && (
                              <div className="row">
                                <div className="col-lg-12 d-flex justify-content-end">
                                  <CustomPagination className="p2p-trade-pagination"
                                    total={Math.ceil(closeBetTotal / recordsPerPage)}
                                    current={closeBetPage}
                                    onPageChange={(page) => clickPageNo(page, "closeBets")}
                                  />
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='trading-page-order-tabs-section pt-5'>
                    <span className='trade-bottom-login-text-1'>
                      <span className='fc-b cursor-poniter' onClick={() => navigate('/login')}>Login </span>(or)
                      <span className='fc-b cursor-poniter' onClick={() => navigate('/register')}> Register Now</span> to view your bets.
                    </span>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
