import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom'
import io from "socket.io-client"
import NavbarOne from "../siteTheme/NavbarOne";
import Accordion from 'react-bootstrap/Accordion';
import { makeRequest } from "../../core/services/v1/request";
import { useContextData } from "../../core/context/index";
import Footer from "../siteTheme/Footer";
import Config from "../../core/config/";
import "../../assets/style.css";
import SupportChatBox from "./supportChatbox";
import socketIOClient from "socket.io-client";
import { toast } from "../../core/lib/toastAlert";
import { BsDot } from "react-icons/bs";
import { GoChevronLeft } from "react-icons/go";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import { useFileUpload } from "use-file-upload";
import { FaUserAlt } from 'react-icons/fa';
import { GrAttachment } from "react-icons/gr";
import { dateFormat } from "../../core/helper/date-format";

const fileTypes = ["JPEG", "PNG", "JPG"];

export default function Support(props) {
    const navigate = useNavigate()
    const [socketConnection, setSocketConnection] = useState(null);
    const { myProfile, setUserProfile } = useContextData();
    const [issue, setIssue] = useState('');
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState('');
    const [filesOffline, setfilesOffline] = useState({});
    const [viewTicket, setViewTicket] = useState([]);
    const [ticketId, setTicketId] = useState("");
    const [chattingHistory, setchattingHistory] = useState([]);
    const [ticketChat, setTicketChat] = useState("");
    const [chatisLoading, setchatisLoading] = useState(false);
    const [chatattachment, setchatattachment] = useState("");
    const [changefile, setchangefile] = useState("");
    const [issueList, setIssueList] = useState([]);
    const [files, selectFiles] = useFileUpload();
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [openedIndex, setOpenedIndex] = useState(null);
    const [uploaderLabel, setUploaderLabel] = useState("Upload or drop a file right here");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        issueListdisplay();
        viewTickets();
        let socket = socketIOClient(Config.SOCKET_URL, { transports: ['websocket'] });
        let socketUnsubscribe;
        if (socket) {
            socket.on('connect', function () {
                setSocketConnection(socket);
                socketUnsubscribe = socket;
            });
            socket.on('connect_error', (err) => {
            })
            socket.on('disconnect', function () {
            });
        };
        return () => {
            if (socketUnsubscribe) {
                socketUnsubscribe.disconnect();
            };
        };

    }, [myProfile]);

    useEffect(() => {
        if (socketConnection != null) {
            socketConnection.on('chattingTicketResponse', (data) => {
                if (data && data.userHistory) {
                    const chatHis = data.userHistory;
                    if (chatHis && chatHis.length > 0) {
                        const resulData = chatHis.find((hisData) => hisData.ticketId == ticketId);
                        if (resulData && resulData.chatHistory) {
                            setchattingHistory(resulData.chatHistory);
                        } else {
                            if (ticketId == data.ticketId) {
                                chattingHistory.push(data.chatHistory)
                            };
                        };
                    };
                };
            });
        };
    }, [socketConnection, ticketId]);
    async function issueListdisplay() {
        if (myProfile && myProfile._id) {
            const params = {
                url: `${Config.V1_API_URL}supportticket/issuelist`,
                method: "GET",
            }
            const response = await makeRequest(params);
            const resData = response.data
            if (resData && resData.length > 0) {
                const filterData = resData.filter((data) => data.issueTitle !== "p2p issue");
                setIssueList(filterData);
            };
            let type = 'error';
            if (response.status) {
                type = 'success';
            };
        };
    };

    async function viewTickets() {
        if (myProfile && myProfile._id) {
            const params = {
                url: `${Config.V1_API_URL}supportticket/viewticket/${myProfile._id}`,
                method: "GET",
            };
            const response = await makeRequest(params);
            if (response.data) setViewTicket(response.data);
            let type = 'error';
            if (response.status) {
                type = 'success';
            };
        };
    };

    async function backButton() {
        try {
            const length = window.history.length;
            if (length > 1) {
                window.history.back();
            } else {
                navigate("/");
            }
        } catch (err) { }
    }
    function handleChangesOffline(event, name = "") {
        if (event) {

            const file = event[0];
            if (file) {
                const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];
                if (file.size > 1024 * 1024) {
                    toast({ type: 'error', message: 'File size must be less than 1 MB' });
                    return;
                }
                if (!validFormats.includes(file.type)) {
                    toast({ type: 'error', message: 'Invalid file format. Only PNG, JPEG, and JPG are allowed.' });
                    return;
                }
                setUploaderLabel("Uploaded Successfully! Upload another?")
                let files1_copy = Object.assign({}, filesOffline);
                files1_copy[name] = file;
                setfilesOffline(files1_copy)

                var reader = new FileReader();
                reader.onload = function (e) {
                    if (name == "attachment") {
                        console.log(e);
                        setAttachment(e.target.result);
                    }
                }
                reader.readAsDataURL(file);
                setErrors((prevErrors) => ({ ...prevErrors, attachment: '' }));
            }
        }
    }

    const validate = () => {
        let errors = {};
        if (!issue) {
            errors.issue = '*Issue is required';
        }
        if (!message) {
            errors.message = '*Message is required';
        }
        if (Object.keys(filesOffline).length === 0) {
            errors.attachment = '*Attachment is required';
        }
        return errors;
    };

    async function createTicket(e) {
        try {

            e.preventDefault()
            const validationErrors = validate();
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                return;
            };
            setIsLoading(true);
            let formdata = new FormData();
            let sizeFile = 1
            for (var key in filesOffline) {
                let fileToUpload = filesOffline[key];
                let fileName = key;
                let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
                formdata.append('images[]', fileToUpload, fileName + '.' + fileExtension);
            };
            const fileParam = {
                url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
                method: 'POST',
                body: formdata,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            const fileRes = (await makeRequest(fileParam));
            const imageUrl = fileRes.message[0].location;
            if (imageUrl && imageUrl != "") {
                const data = {
                    userId: myProfile._id,
                    subject: issue,
                    description: message,
                    chattingImage: imageUrl
                }
                const params = {
                    url: `${Config.V1_API_URL}supportticket/createticket`,
                    method: "POST",
                    body: data,
                }
                const response = await makeRequest(params);
                let type = 'error';
                if (response.status) {
                    type = 'success';
                    setUploaderLabel("Upload or drop a file right here");
                    setUserProfile();
                    setIssue("");
                    setMessage("");
                    setAttachment("");
                    setfilesOffline({});
                    setIsLoading(false);
                }
                toast({ type, message: response.message });
            } else {
                toast({ type: "error", message: "Something Wrong!" });
            }

        } catch (error) {
            console.log("error--", error);
        }

    }

    const handleChattingChange = (e) => {
        setTicketChat(e.target.value);
        if (e.target.value.trim() === '') {
            setErrorMessage("Message cannot be empty");
        } else {
            setErrorMessage("");
        }
    }
    async function handleSubmit(id) {
        if (ticketChat.trim() === '' && chatattachment === '') {
            setErrorMessage("Message or attachment is required");
            return;
        }
        try {
            let uploadFile = chatattachment;
            setchatisLoading(true);
            if (ticketChat == "" && uploadFile == "") {
                return false;
            }
            if (uploadFile == "") {
                const data = {
                    ticketId: id,
                    userId: myProfile._id,
                    ticketChat: ticketChat,
                    attachment: "",
                }
                const params = {
                    url: `${Config.V1_API_URL}supportticket/chat-ticket`,
                    method: 'POST',
                    body: data
                }
                const result = (await makeRequest(params));
                const chatHis = result.history;
                if (Array.isArray(chatHis)) {
                    const resulData = chatHis.find((hisData) => hisData.ticketId == ticketId)
                    setchattingHistory(resulData.chatHistory)
                };
                setchatisLoading(false);
                setchatattachment("");
                setTicketChat("");
                viewTickets();
                setchangefile('');
                setErrorMessage('');
            } else {
                console.log("file", uploadFile)
                const formData = new FormData();
                let sizeFile = 1;
                let fileToUpload = uploadFile;
                let fileName = 'attachment';
                let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
                formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
                const params = {
                    url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                //Start Load
                setchatisLoading(true);
                const response = (await makeRequest(params));
                // Stop Loader 
                setchatisLoading(false);
                if (response.status) {
                    const data = {
                        ticketId: id,
                        userId: myProfile._id,
                        ticketChat: ticketChat,
                        attachment: response.message[0].location
                    }
                    const reqData = {
                        url: `${Config.V1_API_URL}supportticket/chat-ticket`,
                        method: 'POST',
                        body: data
                    }
                    const result = (await makeRequest(reqData));
                    const chatHis = result.history;
                    if (Array.isArray(chatHis)) {
                        const resulData = chatHis.find((hisData) => hisData.ticketId == ticketId)
                        setchattingHistory(resulData.chatHistory)
                    };
                    setchatattachment("");
                    setTicketChat("");
                    viewTickets();
                    setchangefile('');
                    setErrorMessage('');
                }
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const handleIssueChange = (e) => {
        setIssue(e.target.value);
        if (e.target.value) {
            setErrors((prevErrors) => ({ ...prevErrors, issue: '' }));
        }
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        if (e.target.value) {
            setErrors((prevErrors) => ({ ...prevErrors, message: '' }));
        }
    };
    return (
        <div>
            <NavbarOne setTheme={props.setTheme} theme={props.theme} />
            <div className="deposit-page-top-banner">
                <div className="deposit-hero-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <GoChevronLeft className="deposit-back-button-icon" onClick={() => backButton()} />
                                <span className="deposit-text-1">Create Ticket Support</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-5 post-d-card-1 border">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label>Ticket For</label>
                                        <select class="form-select cursor-poniter" aria-label="Default select example" onChange={handleIssueChange} value={issue}>
                                            <option value="" disabled>Select issue</option>
                                            {
                                                issueList.map((data) => {
                                                    return (
                                                        <>
                                                            <option value={data._id} class="cursor-poniter">{data.issueTitle}</option>
                                                        </>
                                                    )
                                                })
                                            }
                                        </select>
                                        {errors.issue && <div className="invalid-amount error">{errors.issue}</div>}
                                    </div>
                                    <div className="mb-3 post-d-card-1-1">
                                        <label>Message to support team</label>
                                        <textarea className="form-control" placeholder="Enter Message.." rows="8" onChange={handleMessageChange} value={message}></textarea>
                                        {errors.message && <div className="invalid-amount error">{errors.message}</div>}
                                    </div>
                                    <label for="inputGroupFile02">Attachment</label>
                                    <div className="input-group mb-3">
                                        <FileUploader
                                            classes="custom-file-uploader cursor-poniter"
                                            className="ui-fs"
                                            multiple={true}
                                            handleChange={(e) => handleChangesOffline(e, "attachment")}
                                            name={"attachment"}
                                            types={fileTypes}
                                            maxSize={1}
                                            onSizeError={(err) => {
                                                setfilesOffline({});
                                                setUploaderLabel(err);
                                            }}
                                        >
                                            <div style={{ border: '2px dashed #0087F7', padding: '5px', textAlign: 'start' }}>
                                                {uploaderLabel}
                                            </div>
                                        </FileUploader>

                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        {attachment ? <>
                                            <img src={attachment} alt="kycImg" className="imagee cusImagee" />
                                        </> :
                                            ''
                                        }
                                        {errors.attachment && <div className="invalid-amount error">{errors.attachment}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <small>Note: Accepted types(jpg, jpeg, png). Max image size 1MB</small>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" onClick={createTicket} disabled={isLoading} class="btn alt-submit-txt-btn">Create Ticket</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="post-d-card-1 border p-3">
                            <h4 className="card-title mt-3">More About Support</h4>
                            <hr></hr>
                            <ol>
                                <li>General</li>
                                <li>Login Problem</li>
                                <li>Transaction Issue</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mb-5 faq-second-section nesw-char-tic-page">
                {viewTicket && viewTicket.length > 0 && viewTicket.map((data, index) => (
                    <Accordion key={index} className="m-2" onClick={() => {
                        setTicketId(data.ticketId);
                        setchattingHistory(data.chatHistory);
                    }} activeKey={openedIndex === index ? '0' : ''}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={() => setOpenedIndex(openedIndex === index ? null : index)}>
                                <span>Ticket Id: #{data.ticketId}</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex justify-content-between">
                                    <span>Subject: {data.subject?.issueTitle}</span>
                                    <span>Created At: {dateFormat(data.date)}</span>
                                    <span>Status: {data.status === 1 ? <span className="text-success">Open</span> : <span className="text-danger">Resolved</span>}</span>
                                </div>
                                <hr />
                                <SupportChatBox
                                    myProfile={myProfile}
                                    description={data.description}
                                    ticketChat={ticketChat}
                                    chatHistory={chattingHistory}
                                    handleChattingChange={handleChattingChange}
                                    submit={() => handleSubmit(data._id)}
                                    isChat={data.status}
                                    setchatisLoading={chatisLoading}
                                    chatattachment={chatattachment}
                                    setchatattachment={setchatattachment}
                                    changefile={changefile}
                                    setchangefile={setchangefile}
                                    selectFiles={selectFiles}
                                    files={files}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))}
            </div>
            <Footer />
        </div>
    )
}