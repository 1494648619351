import React, { useEffect, useState } from "react";

import { BsLink45Deg } from "react-icons/bs";
import notFound from "../../assets/images/file.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat, shortenString } from '../../core/helper/date-format';
import { toast } from "../../core/lib/toastAlert";
import Pagination from 'react-responsive-pagination';
import '../../pagination.css';
import DataTable from 'react-data-table-component';
import $ from "jquery";
import { RiFileCopyFill } from "react-icons/ri";

export default function TransactionhistoryFiat(props) {

  const [transactionList, settransactionList] = useState([]);
  const [filter, setFilter] = useState({
    tab: 'Fiat',
    type: 'Deposit',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalorders, settotalorders] = useState(0);


  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      const generateStatusClass = `${$(this).attr("data-class")}-status`;
      $(this)
        .closest(".status_dropdown")
        .attr("data-color", `${generateStatusClass}`);
    });
  }, []);

  useEffect(() => {
    getHistory();
  }, [filter, currentPage, rowsPerPage]);

  async function filterChange(data) {
    const { target = "", value = "" } = data;
    let filterCopy = { ...filter };
    if (target && value) {
      if (target === 'type') {
        filterCopy.type = value;
        setFilter(filterCopy);
        setCurrentPage(1); // Reset to first page when filter changes
      }
    }
  }

  async function getHistory() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistoryWithFilter`,
        method: 'POST',
        data: {
          filter,
          limit: rowsPerPage,
          offset: rowsPerPage * (currentPage - 1)
        }
      }
      const response = (await makeRequest(params));
      // console.log("response---",response);
      if (response.status) {
        settransactionList(response.data);
        settotalorders(response.total);
      }
    } catch (err) {
      console.log("Something Wrong!");
    }
  }
  const noOfPages = Math.ceil(totalorders / rowsPerPage);

  const clickPageNo = (pgNumber) => {
    pageChange(pgNumber);
  }
  const pageChange = (newCurrentPage) => {
    if (newCurrentPage >= 1 && newCurrentPage <= noOfPages) {
      setCurrentPage(newCurrentPage);
      getPagination(newCurrentPage)
    }
  }
  async function getPagination(page) {
    try {
      const data = {
        filter,
        limit: rowsPerPage,
        offset: rowsPerPage * (page - 1)
      };
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistoryWithFilter`,
        method: "POST",
        body: data,
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        settransactionList(response.data);
        settotalorders(response.total);
      }
    } catch (err) { }
  }
  function copyText(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    let message = "";
    if (data.type == "TxnId") {
      message = "Txn id copied successfully!";
    }
    toast({ type: 'success', message });
  }

  function bonusAmount(trxn) {
    try {
      if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
        return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
      } else {
        if (trxn.depositType == 'Pre Booking') {
          return '-';
        } else {
          return 'NA';
        }
      }
    } catch (err) { }
  }
  const columns = [
    {
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      name: 'Date',
      selector: row => dateFormat(row.createdDate),
      sortable: true,
      width: "200px",
    },
    {
      name: 'Type',
      selector: row => row.type,
      sortable: true,
    },
    {
      name: 'Asset',
      selector: row => row.currencyDet?.currencySymbol,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
    },
    {
      name: 'Bonus',
      selector: row => bonusAmount(row),
      sortable: true,
    },
    {
      name: 'Txn ID',
      cell: row => (
        row.txnId !== "" ? (
          <p className="mb-0 cursor-poniter" onClick={() => copyText({ type: "TxnId", text: row.txnId })}>
            <label className="dest-addres-ellipsi cursor-poniter">{shortenString(row.txnId, 8)}</label>
            <RiFileCopyFill className="dest-addres-link cursor-poniter" />
          </p>
        ) : "-"
      ),
      width: "200px",
    },
    {
      name: 'Status',
      selector: row => (
        row.status === 0 ? 'Pending' :
          row.status === 1 ? 'Approved' :
            row.status === 2 ? 'Rejected' :
              'Processing'
      ),
      sortable: true,
    },
  ];
  return (<>
    <div className="transaction-history-second-section py-3 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="d-flex flex-row mt-3 transaction-history-select-section flex-wrap gap-3">
              <div className="me-3">
                <span className="transaction-text-1">Type</span>
                <div className="custom-dropdown-trans-section">
                  <div
                    className="dropdown custom-dropdown status_dropdown"
                    data-color="created-status"
                  >
                    <button
                      className="select-dropdown-btn dropdown-toggle w-100 d-flex align-items-center justify-content-between status__btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-offset="0,12"
                    >
                      {filter.type}
                    </button>
                    <ul
                      className="dropdown-menu status_change"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          data-class="stoplimit"
                          href="#"
                          onClick={() => filterChange({
                            target: 'type',
                            value: "Deposit"
                          })}
                        >
                          Deposit
                        </a>
                      </li>
                      <li >
                        <a
                          className="dropdown-item"
                          data-class="trailingstop"
                          href="#"
                          onClick={() => filterChange({
                            target: 'type',
                            value: "Withdraw"
                          })}
                        >
                          Withdraw
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container deposit-fourth-section">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <DataTable
            columns={columns}
            data={transactionList}
            persistTableHead
            pagination
            paginationServer
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationTotalRows={totalorders}
            onChangeRowsPerPage={newRowsPerPage => {
              setRowsPerPage(newRowsPerPage);
              setCurrentPage(1); // Reset to first page when rows per page changes
            }}
            onChangePage={page => setCurrentPage(page)}
            noDataComponent={
              <div className="text-center py-2">
                <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                <br />
                <span className="text-center">No Records Found</span>
              </div>
            }
          />
        </div>
      </div>

    </div>
  </>
  );
}