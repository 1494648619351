import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { GoChevronLeft } from 'react-icons/go';
import { HiArrowNarrowRight } from 'react-icons/hi';
// import { RiArrowDownSFill, RiFileCopyFill } from 'react-icons/ri';
// import { BsLink45Deg, BsFillArrowRightCircleFill, BsInfoCircleFill } from 'react-icons/bs';
import {
  Grid,
  Card,
  CardContent,
} from '@mui/material';
// import { IoClose } from "react-icons/io5";
import $ from "jquery";

import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";

import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";

import { toast } from "../core/lib/toastAlert";
import axios from "axios";
// import Depositimage from "../assets/images/deposit/Group.png";
import noreplay from "../assets/images/deposit/no-re.png";

// import ReactTooltip from "react-tooltip";
import { dateFormat, shortAdrress } from '../core/helper/date-format';
import { useContextData } from '../core/context/index'
import { TbUpload } from 'react-icons/tb';
import { useFileUpload } from "use-file-upload";
import * as yup from 'yup';
import { useFormik } from 'formik';
// import Upload from "../assets/images/cloud+upload+file.png";
import DefaultUpload from "../assets/images/cloud+upload+file.png";
import { pageAllowCheck, clickNavigate } from "../core/helper/common";
import { RiArrowDownSFill } from "react-icons/ri"
import { Form, FormGroup, Label, Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import "../assets/style.css";
import Pagination from 'react-responsive-pagination';
import '../pagination.css';
import Accorplus from '../assets/images/accor-plus.png';
import Accormin from '../assets/images/accor-min.png';
import Collapse from 'react-bootstrap/Collapse';
import notFound from "../assets/images/file.png"
import { MdOutlineCommentBank } from "react-icons/md";


const validationSchema = yup.object({
  amount: yup.number().typeError('Please enter the valid amount').required('Amount is required'),
  transactionNumber: yup.string().typeError('Please enter the valid Tnx Hash number').required('Tnx Hash is required'),
  myfile1: yup.mixed().required('Attachment is required')
});

export default function DepositFiat(props) {
  const [openbank, setOpenbank] = useState(true);
  const defaultSrc = DefaultUpload;
  const navigate = useNavigate();
  const { myProfile, setUserProfile, ipAddress } = useContextData();
  const [particularCurrencyList, setparticularCurrencyList] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [depositfiatlist, setdepositfiatList] = useState([]);
  const [adminBankDetails, setadminBankDetails] = useState([]);
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState({});
  const [fillteredData, setFillteredData] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [searchval, setSearchval] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [filterText, setFilterText] = useState('');
  const [depositText, setDepositText] = useState("CLAIM DEPOSIT");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [adminBankDetailsStatus, setadminBankDetailsStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalorders, settotalorders] = useState(0);
  const fileRef = useRef();
  const [imageUrl, setImageUrl] = useState(null);
  const [isAttached, setIsAttached] = useState(false)

  useEffect(() => {
    $(".crypto-workflow-section-close-button").click(function () {
      $(".deposit-second-section").hide();
    });
  }, []);

  useEffect(() => {
    getparticularCurrency_func();
    getBankPaymentsDet();
  }, []);

  useEffect(() => {
    getHistory();
    getAdminBankDetails()
  }, [selectedCurrency, currentPage, rowsPerPage]);

  useEffect(() => {
    if (myProfile) {
      const pageAllowCheckResp = pageAllowCheck(myProfile, "final");
      if (pageAllowCheckResp.type == "error") {
        toast({ type: pageAllowCheckResp.type, message: pageAllowCheckResp.message });
        navigate(pageAllowCheckResp.navigate);
      } else {
        const data = { type: "deposit", row: { currencySymbol: selectedCurrency } };
        clickNavigate(data, myProfile);
      }
    }
  }, [myProfile]);


  let columns = [];
  let filteredItems = [];

  filteredItems = depositfiatlist.filter(
    item => item.txnId && item.txnId.toLowerCase().includes(filterText.toLowerCase())
  );

  columns = [
    {
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      name: 'Time (UTC)',
      width: '190px',
      selector: row => dateFormat(row?.createdDate)
    },
    {
      name: 'Type',
      width: '190px',
      selector: row => row?.type
    },
    {
      name: 'Assets',
      selector: row => (<>{row.currencyId.image ? <img src={row?.currencyId.image} alt={Config.SITENAME} width="20px" height="20px" className="img-fluid img-res" style={{ height: '20%', width: '20%' }} /> : ""} {""} {row?.currencyId.currencySymbol}</>),
      sortable: false
    },
    {
      name: 'Amount',
      selector: row => row?.amount,
      sortable: false
    },
    {
      name: 'UTR Number',
      width: '290px',
      selector: row => (
        <>
          {shortAdrress(row?.txnId !== "" ? row?.txnId : "-")}
        </>
      )
    },
    {
      name: 'Status',
      selector: row => row.status == 0 ? 'Pending' : row.status == 1 ? "Approved" : row.status == 2 ? 'Rejected' : 'Processing',
      sortable: false
    },

  ]

  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      if (typeof selector(a) == "string") {
        const aField = selector(a).toLowerCase();
        const bField = selector(b).toLowerCase();

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      } else {
        const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
        const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      }
    });
  };


  const missingDeposit = async () => {
    setDepositText('Loading...')
    try {
      const value = { type: "Deposit", currencyType: "Fiat", currencySymbol: selectedCurrency }
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistory`,
        method: 'POST',
        body: value
      }
      const response = (await makeRequest(params));
      if (response.status == true) {
        toast({ message: "Recent deposit updated sucessfully" });
        setDepositText('CLAIM DEPOSIT');
      } else {
        setDepositText('CLAIM DEPOSIT')
      }

      getparticularHistory();
    } catch (err) {
      console.log("something wrong!");
    }
  }

  async function getparticularCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: { currencySymbol: "BNB" }
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setparticularCurrencyList(response.data);
      }
    } catch (err) {
      console.log("error")
    }
  }

  async function getFiatCurrency() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: { currencySymbol: selectedCurrency }
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setSelectedFiatCurrency(response.data);
      }
    } catch (err) {
      console.log("error");
    }
  }

  const formik = useFormik({
    initialValues: {
      amount: '',
      transactionNumber: '',
      myfile1: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!selectedFiatCurrency || !selectedFiatCurrency.currencySymbol) {
        toast({ type: "error", message: "Please select a coin" });
        return;
      }
      if (!isAttached) {
        toast({ type: 'error', message: "Your File Size Is Too Large!" });
        return
      }
      const data = {
        currencySymbol: selectedCurrency,
        amount: values.amount,
        transactionNumber: values.transactionNumber,
        attachment: imageUrl
      }
      data.ipDetails = ipAddress
      const params = {
        url: `${Config.V1_API_URL}wallet/depositFiat`,
        method: 'POST',
        body: data
      }
      const result = (await makeRequest(params));
      let type = 'error';
      if (result.status) {
        setImageUrl(defaultSrc);
        setisLoading(false);
        type = 'success';
        toast({ type, message: result.message });
        getHistory();
        getAdminBankDetails();
        getBankPaymentsDet();
        setUserProfile();
        formik.resetForm();
      } else {
        setisLoading(false)
        type = 'error';
        const errmsg = result.message.split('');
        if (errmsg[0] == 'KYC') {
          navigate('/my/identification')
        } else if (errmsg[0] == 'Bank') {
          navigate('/my/payment')
        }
        toast({ type, message: result.message });
      }
      setisLoading(false)
    },
  });


  async function getAdminBankDetails() {
    try {
      const params = {
        url: `${Config.V1_API_URL}admin/getBankDetails`,
        method: 'POST',
        data: {
          currencySymbol: selectedCurrency
        }
      }
      const response = await makeRequest(params);
      if (response.status) {
        if (response.message.status) {
          setadminBankDetails(response.message?.bankDetails);
          setadminBankDetailsStatus(response.message?.status);
        } else {
          setadminBankDetails([]);
          setadminBankDetailsStatus(false);
        }

      }
    } catch (err) {
      console.log("something wrong!");
    }
  }


  async function getBankPaymentsDet() {
    try {
      const params = {
        url: `${Config.V2_API_URL}customer/getBankPayments`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response) {
        const accData = response.data;
        if (accData.length > 0) {
          const isActiveAcc = accData.some(bnkAcc => bnkAcc.status == 1);
          const isBankDisable = accData.some(bnkAcc => bnkAcc.status == 5);
          const isBankPending = accData.some(bnkAcc => bnkAcc.status == 0);
          if (!isActiveAcc) {
            if (isBankDisable) {
              toast({ message: 'Your Bank Account Currently Disabled. Please Enable Your Bank Account' });
              setTimeout(() => navigate("/my/payment"), 2000);
              return
            };
            if (isBankPending) {
              toast({ message: 'Your Documents are Waiting for Approval...!' });
              setTimeout(() => navigate("/my/payment"), 2000);
              return
            };
          };
        } else {
          let type = 'error';
          toast({ type, message: 'Have to Add the Bank details first' });
          setTimeout(() => navigate("/my/payment"), 2000);
        };
      };
    } catch (err) {
      console.error("Error fetching bank payments:", err);
    };
  };

  async function getHistory() {
    try {
      const value = {
        type: "Deposit",
        currencyType: "Fiat",
        currencySymbol: selectedCurrency,
        limit: rowsPerPage,
        offset: rowsPerPage * (currentPage - 1)
      }
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistory`,
        method: 'POST',
        body: value
      }
      const response = (await makeRequest(params));
      if (response.status == true) {
        setdepositfiatList(response.data)
        settotalorders(response.total);
      } else {
        setdepositfiatList(0)
        settotalorders(0);
      }
    } catch (err) {
      console.log("something wrong!");
    }
  }


  async function getparticularHistory() {
    setLoader(true);
    try {
      const value = { type: "Deposit", currencyType: "Fiat", currencySymbol: selectedCurrency }
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistory`,
        method: 'POST',
        body: value
      }
      const response = (await makeRequest(params));
      if (response.status == true) {
        setdepositfiatList(response.data)
        setLoader(false);
      } else {
        setdepositfiatList(0)
      }
    } catch (err) {
      console.log("something wrong!");
    }
  }

  async function handleCurrencyChange(currencySymbol) {
    window.history.pushState({}, '', window.origin + "/deposit/fiat/" + currencySymbol);
    setSelectedCurrency(currencySymbol);
  }
  async function getWalletCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        const data = response.data;
        let currencyArray = [];
        if (Array.isArray(data)) {
          const orgData = data.filter((datas, i) => datas.curnType === "Fiat");
          data.map((datas, i) => {
            currencyArray.push({
              value: datas._id,
              currencySymbol: datas.currencySymbol,
              image: datas.image,
              currencyName: datas.currencyName,
              currencyType: datas.curnType
            });
          });
          setCurrencyList(currencyArray);
        }
      }
    } catch (error) {
      console.log("something wrong!");
    }
  }
  useEffect(() => {
    const filterCurrencyList = async () => {
      const searchString = searchval ? searchval.toString().toLowerCase() : "";
      if (!searchString) {
        return currencyList.filter(currency => currency.currencyType !== "Crypto" && currency.currencyType !== "Pi-Network");
      } else {
        return currencyList.filter(currency =>
          currency.currencySymbol.toLowerCase().includes(searchString) && currency.currencyType !== "Crypto" && currency.currencyType !== "Pi-Network"
        );
      }
    };

    if (currencyList.length > 0) {
      filterCurrencyList().then(filteredData => {
        const filteredINRData = filteredData.filter(currency => currency.currencySymbol);
        setFillteredData(filteredINRData);
      });
    } else {
      getWalletCurrency_func();
    }
  }, [searchval, currencyList]);

  useEffect(() => {
    getFiatCurrency();
    getWalletCurrency_func()
  }, [selectedCurrency]);

  const handleClose = () => {
    setSearchval("")
  }

  const imageUploader = async (value) => {
    try {
      const formData = new FormData();
      let sizeFile = 1;
      let fileToUpload = value;
      let fileName = 'attachment';
      let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
      formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
      if (value && value.size > 1000000) {
        setImageUrl(defaultSrc);
        formik.setFieldValue('myfile1', "");
        toast({ type: "error", message: "You'r File Is Too Large!" });
        return
      };
      setIsAttached(true)
      if (fileExtension !== "png" && fileExtension !== "jpg" && fileExtension !== "jpeg") {
        setImageUrl(defaultSrc);
        toast({ type: "error", message: "You'r File Is Unsupported Format!" });
        return
      };
      formik.setFieldValue('myfile1', value);
      setisLoading(true);
      const params = {
        url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setImageUrl(response.message[0].location);
        setisLoading(false)
      }
    } catch (error) {
      console.log("image error");
    };
  };

  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6" onClick={() => navigate("/my/dashboard")}>
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Deposit Fiat</span>
              </div>
              <div className="col-6 text-end" onClick={() => navigate("/deposit/crypto/" + particularCurrencyList?.currencySymbol)}>
                <button type="button" className="deposit-fiat-button">
                  Deposit Crypto <HiArrowNarrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>



        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-5">
              <form onSubmit={formik.handleSubmit}>
                <div className="col-lg-12">
                  <div className="d-flex flex-lg-row flex-column">
                    <div className="deposit-third-right-section">
                      <span className="deposit-text-33 password-text-44  color-blue ">Coin</span>
                      <div
                        className="d-flex flex-row align-items-center deposit-select-coin-section"
                        data-bs-toggle="modal"
                        data-bs-target="#selectcoinModal"
                      >
                        <div className="">
                          {selectedFiatCurrency && selectedFiatCurrency.image ?
                            <img
                              src={selectedFiatCurrency && selectedFiatCurrency.image}
                              className="deposit-logo-image"
                              alt="logo"
                            /> : "Select Coin"}
                          <span key={'i'} className="deposit-text-4"> {selectedFiatCurrency && selectedFiatCurrency.currencySymbol} </span>
                        </div>
                        <div className="ms-auto">
                          <RiArrowDownSFill />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-4">

                    <div className="">
                      <div className="withdraw-nav-tabs">
                        <div className="mb-4">
                          <span className="deposit-text-33 password-text-44  color-blue">Amount</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount"
                            id="amount"
                            name='amount'
                            autoComplete='off'
                            value={formik.values.amount}
                            onChange={(e) => {
                              const regex = /^[0-9.]*$/;
                              const amount = e.target.value;
                              const isTrue = regex.test(amount);
                              const hasMultipleDots = (amount.match(/\./g) || []).length > 1;
                              if (isTrue && !hasMultipleDots) {
                                formik.handleChange(e)
                              }
                            }}
                            onBlur={formik.handleBlur}
                            onKeyPress={(event) => {
                              const keyCode = event.which || event.keyCode;
                              const keyValue = String.fromCharCode(keyCode);
                              const inputValue = event.target.value;
                              if (!/^[0-9\b.]$/.test(keyValue) || (keyValue === '.' && inputValue.includes('.'))) {
                                event.preventDefault();
                              }
                            }}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                          />
                          {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                        </div>
                        <div className="mb-4">
                          <span className="deposit-text-33 password-text-44  color-blue">UTR Number</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="UTR Number"
                            id="transactionNumber"
                            name='transactionNumber'
                            autoComplete='off'
                            value={formik.values.transactionNumber}
                            onChange={(e) => {
                              const allowedRegex = /^[a-zA-Z0-9]*$/;
                              if (allowedRegex.test(e.target.value)) {
                                formik.handleChange(e)
                              }
                            }}
                            onBlur={formik.handleBlur}
                            onKeyPress={(event) => {
                              const keyCode = event.which || event.keyCode;
                              const keyValue = String.fromCharCode(keyCode);

                              if (!/^[a-zA-Z0-9]$/.test(keyValue)) {
                                event.preventDefault();
                              }
                            }}
                            error={formik.touched.transactionNumber && Boolean(formik.errors.transactionNumber)}
                            helperText={formik.touched.transactionNumber && formik.errors.transactionNumber}
                          />
                          {formik.errors.transactionNumber ? <small className="invalid-transactionNumber error">{formik.errors.transactionNumber}</small> : null}
                        </div>
                        <div>
                          <input type="file" hidden ref={fileRef} onChange={(e) => {
                            formik.setFieldValue('myfile1', "");
                            imageUploader(e.target.files[0])
                          }} />
                          <img className='payment-qrcode-optional-image' src={imageUrl != null ? imageUrl : defaultSrc} alt="preview" /><br />
                          <button type="button" className='payment-qrcode-optional-button mt-3 alter-paymeny-btn-01' name="myfile1"
                            onClick={() => {
                              if (fileRef.current) {
                                fileRef.current.click();
                              }
                            }}
                          >
                            <TbUpload />Upload
                          </button>

                          <p className="me-2 mt-2 password-text-33 mb-0"><span className="password-text-44  color-blue">Note:</span> File Size Should Be Upload Below 1MB(.jpeg,jpg,png)</p>
                          <span className="password-text-33"><b>Minimum Deposit Amount: </b>{selectedFiatCurrency?.minCurDeposit ? selectedFiatCurrency?.minCurDeposit : "--"} {selectedFiatCurrency?.currencySymbol}</span>
                        </div>
                        {formik.errors.myfile1 != "" ? <small className="invalid-myfile1 error">{formik.errors.myfile1}</small> : null}
                        <button type="submit" disabled={isLoading || adminBankDetailsStatus == false} className="btn btn-col w-100 mt-4 alt-submit-txt-btn">
                          Submit Deposit Request
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-7 mt-4">
              <Grid item xs={12} sm={6} className="text-left-move">
                <div>
                  <p className='fc-b password-text-44'>Send Money to this account
                  </p>
                  <p className='password-text-33'>
                    Fiat Withdraw can only be done to the default Account.
                    Whereas deposits can be done from any of the linked bank
                    accounts
                  </p>
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  <p className="mb-0">Bank Details</p>
                  <div className="ms-auto">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenbank(!openbank)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openbank}
                    >
                      {openbank ? (
                        <img src={Accormin} alt="Accormin" style={{ width: "20px" }} />
                      ) : (
                        <img src={Accorplus} alt="Accorplus" style={{ width: "20px" }} />
                      )}
                    </a>
                  </div>
                </div>
                <Collapse in={openbank}>
                  <div id="example-collapse-text">
                    {adminBankDetails.length > 0 ? (
                      <div className='ui-card m-top-21 mb-4'>
                        <Card className='bg-white-card '>
                          <CardContent className='color-black'>
                            {/* <p className="password-text-33"><b>Minimum Deposit Amount: </b>{selectedFiatCurrency?.minCurDeposit} {selectedFiatCurrency?.currencySymbol}</p> */}
                            <div className="alter-text-area-01" dangerouslySetInnerHTML={{ __html: adminBankDetails }} readOnly >
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    ) : (
                      <div className="justy-content-center align-items-center text-center">
                        <MdOutlineCommentBank style={{ width: 70, height: 70, color: "var(--btnClr)" }} />
                        <p className="mt-2">No Active Admin Bank Account Found.</p>
                      </div>
                    )}

                  </div>
                </Collapse>
                <div>
                  <div className='mt-2'>
                    <ul className='mt-12'>
                      <li className='password-text-33'>UPI bank transfers, IMPS, NEFT & RTGS are allowed for this
                        account</li>
                      <li className='password-text-33'> UPI Apps like gPay, PhonePe, Paytm are  not available.</li>
                      <li className='password-text-33'> {selectedCurrency == "INR" ? "INR" : "EUR"} Deposit and Withdrawal take upto 24 hours to Process</li>
                      {selectedFiatCurrency.depositNotes && selectedFiatCurrency.depositNotes !== "NA" && (
                        <li className='password-text-33'>{selectedFiatCurrency.depositNotes}</li>
                      )}
                    </ul>
                    <div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>

        <div className="container deposit-fourth-section my-4">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <h1 className="deposit-text-11 color-blue">Recent Deposits</h1>
              <p className='mt-12'>
                If your previous deposit is not reflecting, you can claim your deposit below &nbsp;
              </p>
              <button className='fc-b f-15 m-top-20 cursor color-hover-res hover-hand-csss new-calim-csxas' onClick={() => missingDeposit()}>
                {depositText} {" "}
                {
                  loader ?
                    <div class="spinner-border text-primary spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    :
                    ''
                }
              </button>
              <div className="col-lg-5 mt-3">
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">Search</Label>
                    <Input
                      id="search"
                      type="text"
                      placeholder="Filter By Transaction id"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </FormGroup>
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                persistTableHead
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalorders}
                paginationRowsPerPageOptions={[5, 10, 15]}
                onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                onChangePage={(page) => setCurrentPage(page)}
                noDataComponent={
                  <div className="text-center">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 70, height: 70 }} />
                    <br />
                    <span className="text-center">No Records Found</span>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>


      <div
        className="modal fade deposit-crypto-modal"
        id="selectcoinModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header ">
              <h4 className="modal-title" id="exampleModalLabel">
                Select coin to deposit
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body modal-2-overflow-scroll">
              <input
                id="myInput2"
                type="text"
                placeholder="Search here..."
                autoComplete="off"
                name="search"
                value={searchval}
                onChange={(e) => setSearchval(e.target.value)}
              />
              <ul id="myUL2" >

                {fillteredData.length > 0 ? (<>
                  {fillteredData.map((currency, i) => {
                    if (currency.curnType != 'Crypto') {
                      return (
                        <li onClick={() => handleCurrencyChange(currency.currencySymbol)} key={i + 1}>
                          <a
                            data-bs-dismiss="modal"
                            className="deposit-modal-list-sizing"
                          >
                            <div className="row deposit-modal-row-sizing">
                              <div className="col-1">
                                <img
                                  src={currency.image}
                                  alt="logo"
                                  className="me-1 deposit-modal-coin-images"
                                />
                              </div>
                              <div className="col">
                                <div className="row ps-2">
                                  <span className="deposit-modal-coin-heading">
                                    {currency.currencySymbol}
                                  </span>
                                  <span className="deposit-modal-coin-muted-text ">
                                    {currency.currencyName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      )
                    }
                  })}
                </>
                ) : (
                  <div className="text-center mt-5">
                    <img className="h-auto" src={notFound} alt="notFound" style={{ width: "40px" }} />
                    <p className=" mb-0 mt-1" style={{ fontSize: "12px", letterSpacing: "1px" }}>No Coin Found</p>
                  </div>
                )
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
