// import package
import React, { useEffect, useState } from 'react';

// import lib
import { positionMrgn, unrealizedPnLVal } from '../../../core/helper/bybit/usdtPerpetual';
import { averagePrice, positionMargin } from '../../../core/helper/bybit/usdtPerpetual'

const UnrealizedPnlValCross = (props) => {
    const { positionDoc, entryPrice, pairDetails, pair, userTradeDetails, pairDetailsArr } = props;
    console.log({ pairDetailsArr })
    const {
        type = "",
        totalAmount = 0,
        leverage = 1,
        method,
        filled,
        _id
    } = positionDoc;

    // state
    const [pnl, setPnl] = useState(0)
    const [marginVal, setMarginVal] = useState(0)
    const [pairData, setPairData] = useState([]);
    useEffect(() => {
        let findDoc = null;
        findDoc = (pairDetailsArr) && (pairDetailsArr?.length >= 0) && pairDetailsArr.find((pair) => pair.pair == positionDoc.pairName);
        if (findDoc) setPairData(findDoc)
    }, [pairDetailsArr]);

    useEffect(() => {
        let price = averagePrice(filled);
        setMarginVal(positionMargin(price, totalAmount, leverage, pairDetails.takerFee, type, positionDoc.filled));
        setPnl(
            unrealizedPnLVal(price,
                positionDoc.totalAmount,
                pairData.marketPrice,
                positionDoc.leverage,
                pairData.takerFee,
                positionDoc.type,
                positionDoc.filled)
        );
    }, [positionDoc, entryPrice, pairData]);


    return (
        <small className={(pnl >= 0) ? "positiveVal" : "negativeVal"}>
            {props.decimalValue(
                marginVal * (props.decimalValue(pnl, pair.amountDecimal) / 100),
                pair.amountDecimal
            )}
            {/* {props.decimalValue(pnl, pair.amountDecimal)} */}
        </small>
    )
}

export default UnrealizedPnlValCross;