import React from 'react';

const CustomPagination = ({ total, current, onPageChange }) => {
  const pageNumbers = [];
  const maxPagesToShow = 5; // Number of visible page numbers
  const totalPages = total; // Total pages based on your logic

  // Helper function to create a range of page numbers
  const createPageNumbers = () => {
    if (totalPages <= maxPagesToShow) {
      // If total pages are less than the maximum to show, display all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Display first few pages, ellipsis, and the last page
      const startPages = [1, 2, 3];
      const endPage = totalPages;

      if (current <= 3) {
        // If current page is within the first few pages, show the first 3
        pageNumbers.push(...startPages);
        pageNumbers.push('...');
        pageNumbers.push(endPage);
      } else if (current > 3 && current < totalPages - 2) {
        // Middle range: display current page, ellipsis, and the last page
        pageNumbers.push(1, '...');
        pageNumbers.push(current - 1, current, current + 1);
        pageNumbers.push('...');
        pageNumbers.push(endPage);
      } else {
        // Near the last pages, show the last few pages
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      }
    }
  };

  createPageNumbers();

  return (
    <div className="custom-pagination">
      <ul className="pagination">
        {/* Prev Button */}
        <li
          className={`page-item ${current === 1 ? 'disabled' : ''}`}
          onClick={() => current > 1 && onPageChange(current - 1)}
          style={{ cursor: current > 1 ? 'pointer' : 'default' }}
        >
          <span className="page-link">Prev</span>
        </li>

        {/* Page Numbers */}
        {pageNumbers.map((page, index) => (
          <li
            key={index}
            className={`page-item ${page === current ? 'active' : ''}`}
            onClick={() => page !== '...' && onPageChange(page)}
            style={{ cursor: page !== '...' ? 'pointer' : 'default' }}
          >
            <span className="page-link">{page}</span>
          </li>
        ))}

        {/* Next Button */}
        <li
          className={`page-item ${current === totalPages ? 'disabled' : ''}`}
          onClick={() => current < totalPages && onPageChange(current + 1)}
          style={{ cursor: current < totalPages ? 'pointer' : 'default' }}
        >
          <span className="page-link">Next</span>
        </li>
      </ul>
    </div>
  );
};

export default CustomPagination;
