import React, { useEffect, useState } from "react";
import $ from "jquery";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdListAlt, MdOutlineAlignVerticalBottom, MdOutlineRocketLaunch, MdRocketLaunch, MdShield } from "react-icons/md";
import { CgMicrosoft } from "react-icons/cg";
import { BrowserRouter as Router, Link, useNavigate, useLocation } from "react-router-dom";
import { GiHeraldicSun } from "react-icons/gi";
import { FaCloudMoon, FaCrown } from "react-icons/fa";
import DarkModeToggle from "react-dark-mode-toggle";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { BiLogoGraphql, BiLogOutCircle, BiWallet } from "react-icons/bi";

import {
  BsBank2,
  BsArrowRight,
  BsShield,
  BsCurrencyExchange,
  BsCoin,
  BsCurrencyDollar,
  BsCodeSquare,
  BsRobot,
} from "react-icons/bs";
import { AiFillWallet, AiFillApi, AiFillStop } from "react-icons/ai";
import { ImArrowRight2, ImMakeGroup } from "react-icons/im";
import {
  BsFillEnvelopeCheckFill,
  BsFillEnvelopeExclamationFill,
} from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { RiCompass2Line, RiCopperCoinFill, RiSlowDownFill } from "react-icons/ri";
import { SiFuturelearn, SiGamedeveloper } from "react-icons/si";
import { useContextData } from "../../core/context/index";
import { getCookie, deleteCookie } from "../../core/helper/cookie";
import { FaUserAlt, FaDollyFlatbed } from "react-icons/fa";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { BsBellFill } from "react-icons/bs";

import jwt_decode from "jwt-decode";
import lightnew from "../../assets/images/mode-images/light-new.svg";
import Darknew from "../../assets/images/mode-images/Dark-new.svg";

import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";
import socketIOClient from "socket.io-client";
import { Badge } from "react-bootstrap";
import moment from "moment/moment";
import BitLogotext from "../../assets/BitLogotext.png";
import Dummy from "../../assets/new-getx-images/dummy.gif";
import { RiArrowRightSLine } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";

import Spotgif from "../../assets/new-getx-images/gif-nav/trade/spot.gif";
import USDMFUTURESgif from "../../assets/new-getx-images/gif-nav/trade/USD-M-FUTURES.gif";
import P2pSgif from "../../assets/new-getx-images/gif-nav/trade/p2p.gif";
import Optionsgif from "../../assets/new-getx-images/gif-nav/trade/OPTION.gif";

import Copytradegif from "../../assets/new-getx-images/gif-nav/copy-trade/copy-trade.gif";
import Createleadergif from "../../assets/new-getx-images/gif-nav/copy-trade/create-leader.gif";

import Copytradetourngif from "../../assets/new-getx-images/gif-nav/tounament/copy-trade.gif";
import Tradingcompetitioncompletegif from "../../assets/new-getx-images/gif-nav/tounament/trading-competition-complete.gif";
import Tradingcompetitiongif from "../../assets/new-getx-images/gif-nav/tounament/trading-competition.gif";

import Cryptoloangif from "../../assets/new-getx-images/gif-nav/finance/crypto-loan.gif";
import Simpleearngif from "../../assets/new-getx-images/gif-nav/finance/simple-earn.gif";
import Loanhistorygif from "../../assets/new-getx-images/gif-nav/finance/loan-history.gif";

import LaunchPadHomeGif from '../../assets/new-getx-images/gif-nav/launchpad/home.gif'
import LaunchPadFormGif from '../../assets/new-getx-images/gif-nav/launchpad/form.gif'
import LaunchPadProjectGif from '../../assets/new-getx-images/gif-nav/launchpad/project.gif'
import LaunchPadLaunchGif from '../../assets/new-getx-images/gif-nav/launchpad/launch.gif'
import { VscHistory } from "react-icons/vsc";
import { userSessionLogout } from "../../core/helper/common";
import { TbComponents } from "react-icons/tb";
import { IoHome } from "react-icons/io5";
import { LiaThListSolid } from "react-icons/lia";
import { GoProjectSymlink } from "react-icons/go";

export default function NavbarOne(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { siteSettings, myProfile, setUserProfile } = useContextData();
  const [socketConnection, setSocketConnection] = useState(null);
  const [currNotification, setCurrNotification] = useState([]);
  const [userLogin, setUserLogin] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [unread, setUnread] = useState(0);
  const [unreadData, setUnreadData] = useState([]);
  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(props.theme == "dark-new" ? true : false);
  const { isRead } = props;
  useEffect(() => {
    if (location.pathname === '/') {
      document.body.classList.add('home-dfd-navbar-body');
    } else {
      document.body.classList.remove('home-dfd-navbar-body');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (myProfile && myProfile._id) {
      setUserId(myProfile._id);
      if (Config.NOTIFICATION_STATUS == "Enable") {
        fetchNotifications();
      } else {
        loginChk();
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (socketConnection != null) {
      if (typeof userId == "string" && userId) {
        loadSocket();
      }
    }
  }, [socketConnection]);
  const handleToggle = () => { };
  const loadSocket = async () => {
    var storeIds = [];
    socketConnection.on("notificationSent", function (data) {
      const filterUnread = data.msg.filter(
        (noti) =>
          (noti.status == 0 && noti.userId == userId) || noti.status == 0
      );
      setUnread(filterUnread.length);
      setUnreadData(filterUnread);
      filterUnread.map((send) => {
        if (!send.notificationType) {
          storeIds.push(send._id);
        }
      });
      setIds(storeIds);
    });
  };

  const loginChk = async (event) => {
    const token = getCookie("userToken");
    const decodedToken = token ? jwt_decode(token, { header: true }) : {};
    if (decodedToken.typ === "JWT") {
      setUserLogin(true);
    }
  };
  const fetchNotifications = async () => {
    try {
      var storeIds = [];
      const params = {
        url: `${Config.V1_API_URL}notification/getAllNotification`,
        method: "POST",
        body: { userId: userId },
      };
      const { status, data, error } = await makeRequest(params);
      if (status == true) {
        const example = data;
        setCurrNotification(example);
        let filterUnread = 0;
        if (data.length > 0) {
          filterUnread = data.filter(
            (noti) =>
              (noti.status == 0 && noti.userId == userId) || noti.status == 0
          );
          if (filterUnread?.length > 0) {
            setUnread(filterUnread.length);
            setUnreadData(filterUnread);
          };
          filterUnread.map((send) => {
            if (send.notificationType) {
              storeIds.push(send._id);
            }
          });
          setIds(storeIds);
        } else {
          filterUnread = 0;
          setUnread(filterUnread);
        }
      } else {
        console.log("Some error occur");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    loginChk();
    window.scrollTo(0, 0);
    if (Config.NOTIFICATION_STATUS == "Enable") {
      let socket = socketIOClient(Config.SOCKET_URL, {
        transports: ["websocket"],
      });
      let socketUnsubscribe;

      if (socket) {
        socket.on("notification", (data) => {
          fetchNotifications();
        });

        socket.on("connect_error", (err) => {
          console.log("socket connect_error", err);
        });
        socket.on("disconnect", function () {
          console.log("socket disconnected");
        });
      }
      return () => {
        if (socketUnsubscribe) {
          socketUnsubscribe.disconnect();
        }
      };
    }
  }, [myProfile]);

  const markAsRead = async (content) => {
    if (content == "same") {
      let notifyId = [...new Set(ids)];
      let payLoad = {
        notifyId,
        userId,
      };

      let params = {
        url: `${Config.V1_API_URL}notification/readNotification`,
        method: "POST",
        data: payLoad,
      };
      setisLoading(true);
      const response = await makeRequest(params);
      if (response.status) {
        fetchNotifications();
        setUnreadData([]);
      }
      setisLoading(false);
    } else if (content == "next") {
      window.location.href = "/notification";
    } else {
    }
  };
  useEffect(() => {

    if (Config.COMPETITION_STATUS == "Enable") {
      $(document).ready(function () {
        $("#trading-competition-hover-button").mouseenter(function () {
          $("#trading-competition-width").css("width", "50%");
          $("#classy-navbar-mobile .tournament-dropdown-menu .dropdown-menu").css("width", "44rem");
          $("#trading-competition-hover-show-hide").show();
        });
        $("#trading-competition-hover-button").mousemove(function () {
          $("#trading-competition-width").css("width", "50%");
          $("#classy-navbar-mobile .tournament-dropdown-menu .dropdown-menu").css("width", "44rem");
          $("#trading-competition-hover-show-hide").show();
        });
        $("#prediction-game-hover-button").mouseenter(function () {
          $("#trading-competition-width").css("width", "100%");
          $("#classy-navbar-mobile .tournament-dropdown-menu .dropdown-menu").css("width", "22rem");
          $("#trading-competition-hover-show-hide").hide();
        });
        $(".dropdown-menu").mouseleave(function () {
          $("#trading-competition-width").css("width", "100%");
          $("#classy-navbar-mobile .tournament-dropdown-menu .dropdown-menu").css("width", "22rem");
          $("#trading-competition-hover-show-hide").hide();
        });
      });
    }
  }, [myProfile]);
  const modesetter = async (event) => {
    const mode = event.target.value;
    props.setTheme(mode);
    localStorage.setItem("mode", mode);
  };
  const handleThemeChange = (mode) => {
    props.setTheme(mode);
    localStorage.setItem("mode", mode);
  };

  useEffect(() => {
    if (isRead) {
      setUnreadData([]);
      if (unread > 0) {
        setUnread(unread - 1)
      };
    };
  }, [isRead]);
  return (
    <Navbar
      id="classy-navbar-mobile"
      className="fixed-top top-navbar-banner-section home-dfd-navbar-sec"
      key="lg"
      expand="lg"
    >
      <Container fluid className="col-lg-12 px-xl-4">
        <Link className="navbar-brand m-0" to="/">
          {siteSettings && siteSettings.siteLogo ? (
            <img src={siteSettings.siteLogo} alt="logo" id="navbar-img" className="navbar-img-with-baaaazx" />
          ) : (
            <img
              src={BitLogotext}
              alt="logo"
              id="navbar-img"
              className="navbar-img-with-baaaazx"
            />
          )}
        </Link>
        <li className="nav-item me-2 d-lg-none d-block ms-auto nav-item-dark-light">
          <button className="nav-link nav-nx-mode-1 d-none-dark" onClick={() => handleThemeChange('dark-new')}><IoMdMoon /></button>
          <button className="nav-link nav-nx-mode-1 d-none-light" onClick={() => handleThemeChange('light-new')}><IoMdSunny /></button>
        </li>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              <Link className="navbar-brand" to="/">
                {siteSettings && siteSettings.siteLogo ? (
                  <img src={siteSettings.siteLogo} alt="logo" id="navbar-img" className="navbar-img-with-baaaazx" />
                ) : (
                  <img
                    src={BitLogotext}
                    alt="logo"
                    id="navbar-img"
                    className="navbar-img-with-baaaazx"
                  />
                )}
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="navbar-nav me-auto align-items-lg-center text-lg-center gap-lg-0 gap-2">
              <li className="nav-item ps-xl-3 ps-2" style={{ display: "none" }}>
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/deposit")}
                  rel="noopener noreferrer"
                  alt="support"
                  className="nav-link"
                >
                  Deposit
                </a>
              </li>
              <li className="nav-item ps-xl-3 ps-lg-2">
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/markets")}
                  rel="noopener noreferrer"
                  alt="support"
                  className="nav-link market-active-nav-active-link"
                >
                  Markets
                </a>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link nav-link-border-1 trade-active-nav-active-link" data-bs-toggle="dropdown"><div className='nav-link-border-2'>Trade <TiArrowSortedDown className="d-lg-none d-block" /></div></a>
                <div class="dropdown-menu" role="menu">
                  <div>
                    <a
                      className="new-nx-drop-i-1"
                      href="javascript:void(0);"
                      onClick={() => navigate("/spot/BTC_USDT")}>
                      <li>
                        <div className='d-flex flex-row align-items-center'>
                          <span className="nav-gif-banner-1"><BiLogoGraphql /></span>
                          <div>
                            <h5 className='mb-0 nav-text-banner-1'>Spot</h5>
                            <span className='nav-text-banner-2'>Spot exchange</span>
                          </div>
                        </div>
                      </li>
                    </a>
                    <a
                      className="new-nx-drop-i-1"
                      href="javascript:void(0);"
                      onClick={() => navigate("/p2p-home")}
                    >
                      <li>
                        <div className='d-flex flex-row align-items-center'>
                          <span className="nav-gif-banner-1"><ImMakeGroup /></span>
                          <div>
                            <h5 className='mb-0 nav-text-banner-1'>P2P</h5>
                            <span className='nav-text-banner-2'>Peer-To-Peer Exchange</span>
                            {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming soon)</span> */}
                          </div>
                        </div>
                      </li>
                    </a>
                    {Config.DERIVATIVES_STATUS == "Enable" ? (
                      <a
                        className="new-nx-drop-i-1"
                        href="javascript:void(0);"
                        onClick={() => navigate("/futures/BTC_USDT")}
                      >
                        <li>
                          <div className='d-flex flex-row align-items-center'>
                            <span className="nav-gif-banner-1"><BsRobot /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>USDT-M Futures</h5>
                              <span className='nav-text-banner-2'>Perpetual Contracts settled in USDT</span>
                              {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming soon)</span> */}
                            </div>
                          </div>
                        </li>
                      </a>
                    ) : (
                      ""
                    )}
                    {Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable" && (
                      <a
                        href="javascript:void(0);"
                        className="new-nx-drop-i-1"
                        onClick={() => navigate("/options")}
                      >
                        <li>
                          <div className='d-flex flex-row gap-1 align-items-center'>
                            <span className="nav-gif-banner-1"><RiSlowDownFill /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>Options Trading</h5>
                              <span className='nav-text-banner-2'>Options Contracts settled in USDT</span>
                              {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming Soon)</span> */}
                            </div>
                          </div>
                        </li>
                      </a>
                    )}

                    {Config.OPTIONAL_TRADING_STATUS == "Enable" ? (
                      <a
                        className="new-nx-drop-i-1"
                        href="javascript:void(0);"
                        onClick={() => navigate("/eoption")}
                      >
                        <li>
                          <div className='d-flex flex-row align-items-center'>
                            <span className="nav-gif-banner-1"><BiLogoGraphql /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>Options</h5>
                              <span className='nav-text-banner-2'>Buy and Sell European-style Options.</span>
                              {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming soon)</span> */}
                            </div>
                          </div>
                        </li>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </li>

              {/* {Config.COPY_TRADING_STATUS == "Enable" && (
                <li class="nav-item dropdown">
                  <a class="nav-link nav-link-border-1 copy-active-nav-active-link" data-bs-toggle="dropdown"><div className='nav-link-border-2'>Copy Trade <TiArrowSortedDown className="d-lg-none d-block" /></div></a>
                  <div class="dropdown-menu" role="menu">
                    <div>
                      <a
                        className="new-nx-drop-i-1"
                        href="javascript:void(0);"
                        onClick={() => navigate("/create-leader")}
                      >
                        <li>
                          <div className='d-flex flex-row gap-1 align-items-center'>
                            <span className="nav-gif-banner-1"><FaCrown /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>Create Leader</h5>
                              
                            </div>
                          </div>
                        </li>
                      </a>
                      <a
                        className="new-nx-drop-i-1"
                        href="javascript:void(0);"
                        onClick={() => navigate("/copy-trade")}
                      >
                        <li>
                          <div className='d-flex flex-row gap-1 align-items-center'>
                            <span className="nav-gif-banner-1"><MdOutlineAlignVerticalBottom /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>Copy Trade</h5>
                             
                            </div>
                          </div>
                        </li>
                      </a>
                    </div>
                  </div>
                </li>
              )} */}


              {Config.NFT_STATUS == "Enable" ? (
                <li className="nav-item ps-lg-3">
                  <a
                    href={Config.nftLink}
                    target={"_blank"}
                    rel="noopener noreferrer"
                    alt="nft"
                    className="nav-link"
                  >
                    NFT
                  </a>
                </li>
              ) : (
                ""
              )}
              {/* {Config.STAKING_STATUS == "Enable" ? (
                <li className="nav-item ps-lg-3">
                  <a
                    href={Config.stakingLink}
                    target={"_blank"}
                    rel="noopener noreferrer"
                    alt="staking"
                    className="nav-link"
                  >
                    Staking
                  </a>
                </li>
              ) : (
                ""
              )} */}
              {Config.COMPETITION_STATUS == "Enable" ||
                Config.BEAR_AND_BULL_STATUS == "Enable" ? (
                <li class="nav-item dropdown">
                  <a class="nav-link nav-link-border-1 tournament-active-nav-active-link" data-bs-toggle="dropdown"><div className='nav-link-border-2'>Tournament <TiArrowSortedDown className="d-lg-none d-block" /></div></a>
                  <div class="dropdown-menu" role="menu">
                    <div>
                      {Config.COMPETITION_STATUS == "Enable" && (
                        <a
                          href="javascript:void(0);"
                          className="new-nx-drop-i-1"
                          onClick={() => navigate("/trading-competition")}
                        >
                          <li>
                            <div className='d-flex flex-row gap-1 align-items-center'>
                              <span className="nav-gif-banner-1"><TbComponents /></span>
                              <div>
                                <h5 className='mb-0 nav-text-banner-1'>Trading Competition - LIVE</h5>


                                {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming Soon)</span> */}
                              </div>
                            </div>
                          </li>
                        </a>
                      )}
                      {Config.COMPETITION_STATUS == "Enable" && (
                        <a
                          href="javascript:void(0);"
                          className="new-nx-drop-i-1"
                          onClick={() => navigate("/trading-competition-completion")}
                        >
                          <li>
                            <div className='d-flex flex-row gap-1 align-items-center'>
                              <span className="nav-gif-banner-1"><TbComponents /></span>
                              <div>
                                <h5 className='mb-0 nav-text-banner-1'>Trading Competition - Completed</h5>
                                <span className='nav-text-banner-2'></span>
                                {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming Soon)</span> */}
                              </div>
                            </div>
                          </li>
                        </a>
                      )}
                      {Config.BEAR_AND_BULL_STATUS == "Enable" && (
                        <a
                          href="javascript:void(0);"
                          className="new-nx-drop-i-1"
                          onClick={() => navigate("/prediction-trade")}
                        >
                          <li>
                            <div className='d-flex flex-row gap-1 align-items-center'>
                              <span className="nav-gif-banner-1"><RiSlowDownFill /></span>
                              <div>
                                <h5 className='mb-0 nav-text-banner-1'>Prediction Gaming</h5>
                                {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming Soon)</span> */}
                              </div>
                            </div>
                          </li>
                        </a>
                      )}


                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}

              {(Config.CRYPTO_LOAN_STATUS == "Enable" || Config.SIMPLEEARN_STATUS == "Enable") && (
                <li class="nav-item dropdown">
                  <a class="nav-link nav-link-border-1 finance-active-nav-active-link" data-bs-toggle="dropdown"><div className='nav-link-border-2'>Finance <TiArrowSortedDown className="d-lg-none d-block" /></div></a>
                  <div class="dropdown-menu" role="menu">
                    <div>
                      {(Config.CRYPTO_LOAN_STATUS == "Enable") && (
                        <>
                          <a
                            href="javascript:void(0);"
                            className="new-nx-drop-i-1"
                            onClick={() => navigate("/loan")}
                          >
                            <li>
                              <div className='d-flex flex-row gap-1 align-items-center'>
                                <span className="nav-gif-banner-1"><RiCopperCoinFill /></span>
                                <div>
                                  <h5 className='mb-0 nav-text-banner-1'>Crypto Loans</h5>
                                  <span className='nav-text-banner-2'>Get an instant loan secured by crypto assets</span>
                                  {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming soon)</span> */}
                                </div>
                              </div>
                            </li>
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="new-nx-drop-i-1"
                            onClick={() => navigate("/loan/history")}
                          >
                            <li>
                              <div className='d-flex flex-row gap-1 align-items-center'>
                                <span className="nav-gif-banner-1"><MdListAlt /></span>
                                <div>
                                  <h5 className='mb-0 nav-text-banner-1'>Loan History</h5>
                                  <span className='nav-text-banner-2'>Get an instant loan secured by crypto assets</span>
                                  {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming soon)</span> */}
                                </div>
                              </div>
                            </li>
                          </a>
                        </>
                      )}
                      {(Config.SIMPLEEARN_STATUS == "Enable") && (
                        <a
                          href="javascript:void(0);"
                          className="new-nx-drop-i-1"
                          onClick={() => navigate("/simple-earn")}
                        >
                          <li>
                            <div className='d-flex flex-row gap-1 align-items-center'>
                              <span className="nav-gif-banner-1"><SiFuturelearn /></span>
                              <div>
                                <h5 className='mb-0 nav-text-banner-1'>Simple Earn</h5>
                                <span className='nav-text-banner-2'>Simple earn staking</span>
                                {/* <span className='nav-text-banner-2' style={{ color: "#F7B500" }}>(coming soon)</span> */}
                              </div>
                            </div>
                          </li>
                        </a>
                      )}
                    </div>
                  </div>
                </li>
              )}

              {Config.LAUNCHPAD_STATUS == "Enable" ? (
                <li class="nav-item dropdown">
                  <a class="nav-link nav-link-border-1 launchpad-active-nav-active-link" data-bs-toggle="dropdown"><div className='nav-link-border-2'>Launchpad <TiArrowSortedDown className="d-lg-none d-block" /></div></a>
                  <div class="dropdown-menu" role="menu">
                    <div>
                      <a
                        className="new-nx-drop-i-1"
                        href="javascript:void(0);"
                        onClick={() => navigate("/launch-pad")} >
                        <li>
                          <div className='d-flex flex-row gap-1 align-items-center'>
                            <span className="nav-gif-banner-1"><IoHome /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>Home</h5>
                              <span className='nav-text-banner-2'>Overview of our launchpad</span>
                            </div>
                          </div>
                        </li>
                      </a>
                      <a
                        className="new-nx-drop-i-1"
                        href="javascript:void(0);"
                        onClick={() => navigate("/ido-form")} >
                        <li>
                          <div className='d-flex flex-row gap-1 align-items-center'>
                            <span className="nav-gif-banner-1"><LiaThListSolid /></span>
                            <div>
                              <h5 className='mb-0 nav-text-banner-1'>Form</h5>
                              <span className='nav-text-banner-2'>Fill the form and run your projects</span>
                            </div>
                          </div>
                        </li>
                      </a>

                      {
                        userLogin === true ?
                          (
                            <>
                              <a
                                className="new-nx-drop-i-1"
                                href="javascript:void(0);"
                                onClick={() => navigate("/ido-project")} >
                                <li>
                                  <div className='d-flex flex-row gap-1 align-items-center'>
                                    <span className="nav-gif-banner-1"><GoProjectSymlink /></span>
                                    <div>
                                      <h5 className='mb-0 nav-text-banner-1'>Project</h5>
                                      <span className='nav-text-banner-2'>Your project info</span>
                                    </div>
                                  </div>
                                </li>
                              </a>
                              <a
                                className="new-nx-drop-i-1"
                                href="javascript:void(0);"
                                onClick={() => navigate("/ido-launch")} >
                                <li>
                                  <div className='d-flex flex-row gap-1 align-items-center'>
                                    <span className="nav-gif-banner-1"><MdOutlineRocketLaunch /></span>
                                    <div>
                                      <h5 className='mb-0 nav-text-banner-1'>Launch</h5>
                                      <span className='nav-text-banner-2'>Buy Tokens</span>
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </>
                          )
                          :
                          ''
                      }
                    </div>
                  </div>
                </li>
                // <li className="nav-item ps-lg-3">
                //   <a
                //     href="javascript:void(0);"
                //     onClick={() => {
                //       navigate("/launch-pad");
                //     }}
                //     rel="noopener noreferrer"
                //     alt="nft"
                //     className="nav-link"
                //   >
                //     Launchpad
                //   </a>
                // </li>
              ) : (
                ""
              )}

              <li className="nav-item">
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    navigate("/faq");
                  }}
                  rel="noopener noreferrer"
                  alt="nft"
                  className="nav-link faq-active-nav-active-link"
                >
                  FAQs
                </a>
              </li>


              {/* <li class="nav-item dropdown has-megamenu nav-item-dark-light">
                <a class="nav-link nav-link-border-1" data-bs-toggle="dropdown"><div className='nav-link-border-2'>Theme <TiArrowSortedDown className="d-lg-none d-block" /></div></a>
                <div class="dropdown-menu megamenu" role="menu">
                  <div className='row justify-content-center g-lg-0 g-4'>
                    <div className='col-lg-6'>
                      <div className='row row-cols-1 row-cols-lg-2 g-4 ms-lg-0 ms-2' onChange={modesetter}>
                        <div className='col'>
                          <li>
                            <div className='d-flex flex-row gap-2 align-items-center'>
                              <span><img className='nav-gif-banner-1' src={lightnew} alt='lightnew' /></span>
                              <div>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="colortheme"
                                  id="flexRadioDefault2"
                                  value="light-new"
                                  checked={props.theme == "light-new" ? "checked" : ""}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  for="flexRadioDefault2"
                                >
                                  Light
                                </label>
                              </div>
                            </div>
                          </li>
                        </div>
                        <div className='col'>
                          <li>
                            <div className='d-flex flex-row gap-2 align-items-center'>
                              <span><img className='nav-gif-banner-1' src={Darknew} alt='Darknew' /></span>
                              <div>
                                <input
                                  className="form-check-input "
                                  type="radio"
                                  name="colortheme"
                                  id="flexRadioDefault4"
                                  value="dark-new"
                                  checked={props.theme == "dark-new" ? "checked" : ""}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  for="flexRadioDefault2"
                                >
                                  Dark
                                </label>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}



            </ul>

            <ul className="navbar-nav ms-auto align-items-lg-center text-lg-center gap-lg-0 gap-1 mt-lg-0 mt-3">
              {userLogin === false ? (
                <>
                  <li className="nav-item ps-xl-3">
                    <a
                      onClick={() => navigate("/login")}
                      rel="noopener noreferrer"
                      alt="support"
                      className="login-active-nav-active-link nav-link"
                    >
                      Log In
                    </a>
                  </li>
                  <li className="nav-item ps-xl-3 text-center">
                    <a
                      onClick={() => navigate("/register")}
                      rel="noopener noreferrer"
                      alt="support"
                      className=" nav-button-banner-1 reg-active-nav-active-link"
                    >
                      Sign Up
                    </a>
                  </li>
                </>
              ) : (
                <>
                  {Config.NOTIFICATION_STATUS == "Enable" ? (
                    <>
                      <li className="nav-item ps-xl-3 bell-icon-dropdown-css">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn  dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-display="static"
                            aria-expanded="false"
                          >
                            <BsBellFill className="fc-g f-25" />{" "}
                            {unread ? <Badge className="notti-ctn-1">{unread}</Badge> : ""}
                          </button>
                          <ul class="dropdown-menu ">
                            <li>
                              <button class="dropdown-item" type="button">
                                <div className="d-flex flex-row justify-content-between bell-icon-notification-bg p-3">
                                  <div className="">
                                    <p className="mb-0 text-dark">
                                      {unread > 0 ? unread : 0}{" "}
                                      <span className="text-black-grey f-13">
                                        Pending Notifications
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <p
                                      onClick={() => markAsRead("same")}
                                      className="mb-0 fc-g"
                                    >
                                      Clear All
                                    </p>
                                  </div>
                                  <div>
                                    <p
                                      onClick={() => markAsRead("next")}
                                      className="mb-0 text-dark"
                                    >
                                      View All{" "}
                                      <span>
                                        <BsArrowRightShort />
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </button>
                            </li>
                            {unreadData.map((data, n) => {
                              const truncate = data.message?.substring(0, 40);
                              return (
                                <li onClick={() => navigate("/notification")}>
                                  <button
                                    class="dropdown-item  mb-2"
                                    type="button"
                                  >
                                    <div className="d-flex flex-row ">
                                      <div>
                                        <BsFillEnvelopeCheckFill className="fc-g  f-20 ms-2 me-2" />
                                      </div>
                                      <div>
                                        <p className="mb-0">{data.title}</p>
                                        <p className="mb-0 text-grey f-15">
                                          {truncate}....{" "}
                                          {moment(
                                            new Date(data.createdDate),
                                            "YYYYMMDD"
                                          ).fromNow()}
                                        </p>
                                      </div>
                                    </div>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li className="nav-item ps-xl-3 d-lg-none d-blocktop-banner-third-section ">
                    <a
                      href=""
                      onClick={() => navigate("/my/wallet")}
                      rel="noopener noreferrer"
                      alt="support"
                      className="nav-link"
                    >
                      Wallet
                    </a>
                  </li>
                  <li className="nav-item ps-xl-3">
                    <a href="javascript:void(0);" className="nav-link remove nav-link-new-drop-getx">
                      <div className="dropdown home-drop">
                        <button
                          className="dropdown-toggle btn-next-1"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {myProfile && (
                            <>
                              {myProfile.email
                                ? myProfile.email
                                : myProfile.phoneno}{" "}
                            </>
                          )}
                          <FaUserAlt />
                        </button>
                        <ul
                          className="dropdown-menu drop"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/dashboard")}
                            >
                              <CgMicrosoft /> Dashboard
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/profile")}
                            >
                              <MdShield /> Security
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/wallet")}
                            >
                              <AiFillWallet /> Wallet
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/identification")}
                            >
                              <BsShield /> Identification
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/payment")}
                            >
                              <BiWallet /> Payment
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/referral")}
                            >
                              <MdOutlineConnectWithoutContact /> Referral
                              Program
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/wallet/history")}
                            >
                              <HiClipboardDocumentList /> Transactions
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate("/my/SpotTradeHistory")}
                            >
                              <VscHistory /> Trade History
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li onClick={async () => {
                            await userSessionLogout();
                            deleteCookie("userToken");
                            setUserProfile();
                          }}>
                            <a
                              className="dropdown-item logout"
                              href="/login"
                            >
                              <BiLogOutCircle /> Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </a>
                  </li>

                </>
              )}
              <li className="nav-item ms-lg-2 d-lg-block d-none nav-item-dark-light">
                <button className="nav-link nav-nx-mode-1 d-none-dark" onClick={() => handleThemeChange('dark-new')}><IoMdMoon /></button>
                <button className="nav-link nav-nx-mode-1 d-none-light" onClick={() => handleThemeChange('light-new')}><IoMdSunny /></button>
              </li>
            </ul>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
