import React,{useState} from 'react';
import TVChartContainer from './TVChartContainer';
import Chart from "./Chart"

// const TVChartContainer = React.lazy(() => import("./TVChartContainer"));

const TradingView = (props) => {
  const pair = "BTCUSDT";
  //console.log("-interval-----",props.interval);
  return (
    // <TVChartContainer {...props} />
    <Chart pair={pair} theme={props.theme} />
  );
}
export default TradingView;