import "../assets/siteloader.css"
function SiteLoader() {
    return (
        <div class="coin">
            <div class="side heads">
                <svg width="90" height="90" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_21_10)">
                        <path d="M68 136C105.555 136 136 105.555 136 68C136 30.4446 105.555 0 68 0C30.4446 0 0 30.4446 0 68C0 105.555 30.4446 136 68 136Z" fill="#5928E9" />
                        <path d="M77.4368 125.16C80.0233 125.16 82.1201 123.063 82.1201 120.477C82.1201 117.89 80.0233 115.793 77.4368 115.793C74.8502 115.793 72.7534 117.89 72.7534 120.477C72.7534 123.063 74.8502 125.16 77.4368 125.16Z" fill="white" />
                        <path d="M103.267 111.92C105.853 111.92 107.95 109.823 107.95 107.237C107.95 104.65 105.853 102.553 103.267 102.553C100.68 102.553 98.5835 104.65 98.5835 107.237C98.5835 109.823 100.68 111.92 103.267 111.92Z" fill="white" />
                        <path d="M116.793 91.3133C119.38 91.3133 121.477 89.2165 121.477 86.63C121.477 84.0435 119.38 81.9467 116.793 81.9467C114.207 81.9467 112.11 84.0435 112.11 86.63C112.11 89.2165 114.207 91.3133 116.793 91.3133Z" fill="white" />
                        <path d="M118.667 66.42C121.253 66.42 123.35 64.3232 123.35 61.7367C123.35 59.1501 121.253 57.0533 118.667 57.0533C116.08 57.0533 113.983 59.1501 113.983 61.7367C113.983 64.3232 116.08 66.42 118.667 66.42Z" fill="white" />
                        <path d="M108.22 42.4C110.806 42.4 112.903 40.3032 112.903 37.7167C112.903 35.1301 110.806 33.0333 108.22 33.0333C105.633 33.0333 103.537 35.1301 103.537 37.7167C103.537 40.3032 105.633 42.4 108.22 42.4Z" fill="white" />
                        <path d="M95.7099 28.7467C97.2618 28.7467 98.5199 27.4886 98.5199 25.9367C98.5199 24.3848 97.2618 23.1267 95.7099 23.1267C94.158 23.1267 92.8999 24.3848 92.8999 25.9367C92.8999 27.4886 94.158 28.7467 95.7099 28.7467Z" fill="white" />
                        <path d="M103.203 30.62C104.238 30.62 105.077 29.7813 105.077 28.7467C105.077 27.712 104.238 26.8733 103.203 26.8733C102.169 26.8733 101.33 27.712 101.33 28.7467C101.33 29.7813 102.169 30.62 103.203 30.62Z" fill="white" />
                        <path d="M114.857 49.75C116.409 49.75 117.667 48.4919 117.667 46.94C117.667 45.3881 116.409 44.13 114.857 44.13C113.305 44.13 112.047 45.3881 112.047 46.94C112.047 48.4919 113.305 49.75 114.857 49.75Z" fill="white" />
                        <path d="M119.54 54.4333C120.574 54.4333 121.413 53.5946 121.413 52.56C121.413 51.5254 120.574 50.6866 119.54 50.6866C118.505 50.6866 117.667 51.5254 117.667 52.56C117.667 53.5946 118.505 54.4333 119.54 54.4333Z" fill="white" />
                        <path d="M119.62 73.9767C121.172 73.9767 122.43 72.7186 122.43 71.1667C122.43 69.6148 121.172 68.3567 119.62 68.3567C118.068 68.3567 116.81 69.6148 116.81 71.1667C116.81 72.7186 118.068 73.9767 119.62 73.9767Z" fill="white" />
                        <path d="M120.286 80.47C121.321 80.47 122.16 79.6313 122.16 78.5967C122.16 77.562 121.321 76.7233 120.286 76.7233C119.252 76.7233 118.413 77.562 118.413 78.5967C118.413 79.6313 119.252 80.47 120.286 80.47Z" fill="white" />
                        <path d="M112.523 98.14C114.075 98.14 115.333 96.8819 115.333 95.33C115.333 93.7781 114.075 92.52 112.523 92.52C110.971 92.52 109.713 93.7781 109.713 95.33C109.713 96.8819 110.971 98.14 112.523 98.14Z" fill="white" />
                        <path d="M95.7099 115.81C97.2618 115.81 98.5199 114.552 98.5199 113C98.5199 111.448 97.2618 110.19 95.7099 110.19C94.158 110.19 92.8999 111.448 92.8999 113C92.8999 114.552 94.158 115.81 95.7099 115.81Z" fill="white" />
                        <path d="M66.8002 123.97C68.3522 123.97 69.6102 122.712 69.6102 121.16C69.6102 119.608 68.3522 118.35 66.8002 118.35C65.2483 118.35 63.9902 119.608 63.9902 121.16C63.9902 122.712 65.2483 123.97 66.8002 123.97Z" fill="white" />
                        <path d="M39.56 115.413C41.1119 115.413 42.37 114.155 42.37 112.603C42.37 111.051 41.1119 109.793 39.56 109.793C38.0081 109.793 36.75 111.051 36.75 112.603C36.75 114.155 38.0081 115.413 39.56 115.413Z" fill="white" />
                        <path d="M22.1435 96.14C23.6954 96.14 24.9535 94.8819 24.9535 93.33C24.9535 91.7781 23.6954 90.52 22.1435 90.52C20.5916 90.52 19.3335 91.7781 19.3335 93.33C19.3335 94.8819 20.5916 96.14 22.1435 96.14Z" fill="white" />
                        <path d="M110.11 104.093C111.145 104.093 111.983 103.255 111.983 102.22C111.983 101.185 111.145 100.347 110.11 100.347C109.076 100.347 108.237 101.185 108.237 102.22C108.237 103.255 109.076 104.093 110.11 104.093Z" fill="white" />
                        <path d="M89.5501 120.223C90.5847 120.223 91.4234 119.385 91.4234 118.35C91.4234 117.315 90.5847 116.477 89.5501 116.477C88.5155 116.477 87.6768 117.315 87.6768 118.35C87.6768 119.385 88.5155 120.223 89.5501 120.223Z" fill="white" />
                        <path d="M58.3733 124.033C59.4079 124.033 60.2467 123.195 60.2467 122.16C60.2467 121.125 59.4079 120.287 58.3733 120.287C57.3387 120.287 56.5 121.125 56.5 122.16C56.5 123.195 57.3387 124.033 58.3733 124.033Z" fill="white" />
                        <path d="M31.7332 110.73C32.7678 110.73 33.6065 109.891 33.6065 108.857C33.6065 107.822 32.7678 106.983 31.7332 106.983C30.6986 106.983 29.8599 107.822 29.8599 108.857C29.8599 109.891 30.6986 110.73 31.7332 110.73Z" fill="white" />
                        <path d="M17.3968 89.25C18.4314 89.25 19.2701 88.4113 19.2701 87.3767C19.2701 86.3421 18.4314 85.5034 17.3968 85.5034C16.3622 85.5034 15.5234 86.3421 15.5234 87.3767C15.5234 88.4113 16.3622 89.25 17.3968 89.25Z" fill="white" />
                        <path d="M16.6498 72.6433C18.2018 72.6433 19.4598 71.3852 19.4598 69.8333C19.4598 68.2814 18.2018 67.0233 16.6498 67.0233C15.0979 67.0233 13.8398 68.2814 13.8398 69.8333C13.8398 71.3852 15.0979 72.6433 16.6498 72.6433Z" fill="white" />
                        <path d="M15.0467 65.5467C16.0813 65.5467 16.92 64.7079 16.92 63.6733C16.92 62.6387 16.0813 61.8 15.0467 61.8C14.0121 61.8 13.1733 62.6387 13.1733 63.6733C13.1733 64.7079 14.0121 65.5467 15.0467 65.5467Z" fill="white" />
                        <path d="M20.4765 50.4167C22.0284 50.4167 23.2865 49.1586 23.2865 47.6067C23.2865 46.0548 22.0284 44.7967 20.4765 44.7967C18.9246 44.7967 17.6665 46.0548 17.6665 47.6067C17.6665 49.1586 18.9246 50.4167 20.4765 50.4167Z" fill="white" />
                        <path d="M38.56 29.4133C40.1119 29.4133 41.37 28.1553 41.37 26.6033C41.37 25.0514 40.1119 23.7933 38.56 23.7933C37.0081 23.7933 35.75 25.0514 35.75 26.6033C35.75 28.1553 37.0081 29.4133 38.56 29.4133Z" fill="white" />
                        <path d="M67.8002 20.92C69.3522 20.92 70.6102 19.6619 70.6102 18.11C70.6102 16.5581 69.3522 15.3 67.8002 15.3C66.2483 15.3 64.9902 16.5581 64.9902 18.11C64.9902 19.6619 66.2483 20.92 67.8002 20.92Z" fill="white" />
                        <path d="M23.4935 42.59C24.5281 42.59 25.3668 41.7513 25.3668 40.7167C25.3668 39.682 24.5281 38.8433 23.4935 38.8433C22.4588 38.8433 21.6201 39.682 21.6201 40.7167C21.6201 41.7513 22.4588 42.59 23.4935 42.59Z" fill="white" />
                        <path d="M44.7835 23.73C45.8181 23.73 46.6568 22.8913 46.6568 21.8567C46.6568 20.8221 45.8181 19.9833 44.7835 19.9833C43.7489 19.9833 42.9102 20.8221 42.9102 21.8567C42.9102 22.8913 43.7489 23.73 44.7835 23.73Z" fill="white" />
                        <path d="M76.2298 19.0467C77.2644 19.0467 78.1031 18.2079 78.1031 17.1733C78.1031 16.1387 77.2644 15.3 76.2298 15.3C75.1952 15.3 74.3564 16.1387 74.3564 17.1733C74.3564 18.2079 75.1952 19.0467 76.2298 19.0467Z" fill="white" />
                        <path d="M85.8664 26.08C88.453 26.08 90.5498 23.9832 90.5498 21.3967C90.5498 18.8101 88.453 16.7133 85.8664 16.7133C83.2799 16.7133 81.1831 18.8101 81.1831 21.3967C81.1831 23.9832 83.2799 26.08 85.8664 26.08Z" fill="white" />
                        <path d="M56.5002 24.2067C59.0868 24.2067 61.1836 22.1099 61.1836 19.5233C61.1836 16.9368 59.0868 14.84 56.5002 14.84C53.9137 14.84 51.8169 16.9368 51.8169 19.5233C51.8169 22.1099 53.9137 24.2067 56.5002 24.2067Z" fill="white" />
                        <path d="M30.7331 39.1133C33.3197 39.1133 35.4165 37.0165 35.4165 34.43C35.4165 31.8434 33.3197 29.7466 30.7331 29.7466C28.1466 29.7466 26.0498 31.8434 26.0498 34.43C26.0498 37.0165 28.1466 39.1133 30.7331 39.1133Z" fill="white" />
                        <path d="M18.2068 61.5934C20.7933 61.5934 22.8901 59.4966 22.8901 56.91C22.8901 54.3235 20.7933 52.2267 18.2068 52.2267C15.6202 52.2267 13.5234 54.3235 13.5234 56.91C13.5234 59.4966 15.6202 61.5934 18.2068 61.5934Z" fill="white" />
                        <path d="M17.3332 83.2166C19.9198 83.2166 22.0166 81.1198 22.0166 78.5333C22.0166 75.9468 19.9198 73.85 17.3332 73.85C14.7467 73.85 12.6499 75.9468 12.6499 78.5333C12.6499 81.1198 14.7467 83.2166 17.3332 83.2166Z" fill="white" />
                        <path d="M26.78 105.3C29.3665 105.3 31.4633 103.203 31.4633 100.617C31.4633 98.0302 29.3665 95.9333 26.78 95.9333C24.1935 95.9333 22.0967 98.0302 22.0967 100.617C22.0967 103.203 24.1935 105.3 26.78 105.3Z" fill="white" />
                        <path d="M47.0701 121.287C49.6566 121.287 51.7534 119.19 51.7534 116.603C51.7534 114.017 49.6566 111.92 47.0701 111.92C44.4835 111.92 42.3867 114.017 42.3867 116.603C42.3867 119.19 44.4835 121.287 47.0701 121.287Z" fill="white" />
                        <path d="M52.9067 78.4633L67.4367 57.0967" stroke="white" stroke-miterlimit="10" />
                        <path d="M71.71 57.0967L85.3833 77.61" stroke="white" stroke-miterlimit="10" />
                        <path d="M54.6167 80.1733L83.6734 64.79" stroke="white" stroke-miterlimit="10" />
                        <path d="M83.6735 79.3166L55.4702 64.79" stroke="white" stroke-miterlimit="10" />
                        <path d="M51.1968 78.4633V66.24" stroke="white" stroke-miterlimit="10" />
                        <path d="M54.6167 61.37L66.58 55.3867" stroke="white" stroke-miterlimit="10" />
                        <path d="M72.5635 55.3867L84.5301 61.37" stroke="white" stroke-miterlimit="10" />
                        <path d="M87.6533 66.24V77.61" stroke="white" stroke-miterlimit="10" />
                        <path d="M86.6668 66.4967C88.3181 66.4967 89.6568 65.158 89.6568 63.5067C89.6568 61.8553 88.3181 60.5167 86.6668 60.5167C85.0154 60.5167 83.6768 61.8553 83.6768 63.5067C83.6768 65.158 85.0154 66.4967 86.6668 66.4967Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M52.4802 66.4967C54.1316 66.4967 55.4702 65.158 55.4702 63.5067C55.4702 61.8553 54.1316 60.5167 52.4802 60.5167C50.8289 60.5167 49.4902 61.8553 49.4902 63.5067C49.4902 65.158 50.8289 66.4967 52.4802 66.4967Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M69.5735 57.95C71.2248 57.95 72.5635 56.6113 72.5635 54.96C72.5635 53.3086 71.2248 51.97 69.5735 51.97C67.9222 51.97 66.5835 53.3086 66.5835 54.96C66.5835 56.6113 67.9222 57.95 69.5735 57.95Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M51.6233 84.4433C53.2746 84.4433 54.6133 83.1047 54.6133 81.4533C54.6133 79.802 53.2746 78.4633 51.6233 78.4633C49.972 78.4633 48.6333 79.802 48.6333 81.4533C48.6333 83.1047 49.972 84.4433 51.6233 84.4433Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M86.6668 83.59C88.3181 83.59 89.6568 82.2513 89.6568 80.6C89.6568 78.9487 88.3181 77.61 86.6668 77.61C85.0154 77.61 83.6768 78.9487 83.6768 80.6C83.6768 82.2513 85.0154 83.59 86.6668 83.59Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M63.6469 84.6333C64.201 84.6333 64.6502 84.1841 64.6502 83.63C64.6502 83.0759 64.201 82.6266 63.6469 82.6266C63.0928 82.6266 62.6436 83.0759 62.6436 83.63C62.6436 84.1841 63.0928 84.6333 63.6469 84.6333Z" fill="white" />
                        <path d="M69.8102 79.4733C70.4435 79.4733 70.9569 78.9599 70.9569 78.3267C70.9569 77.6934 70.4435 77.18 69.8102 77.18C69.177 77.18 68.6636 77.6934 68.6636 78.3267C68.6636 78.9599 69.177 79.4733 69.8102 79.4733Z" fill="white" />
                        <path d="M77.9401 82.8108L76.4033 81.274L74.8665 82.8108L76.4033 84.3476L77.9401 82.8108Z" fill="white" />
                        <path d="M32.4034 70.1234C35.0673 70.1234 37.2267 67.9639 37.2267 65.3C37.2267 62.6362 35.0673 60.4767 32.4034 60.4767C29.7396 60.4767 27.5801 62.6362 27.5801 65.3C27.5801 67.9639 29.7396 70.1234 32.4034 70.1234Z" fill="white" />
                        <path d="M37.9366 54.68C40.6005 54.68 42.7599 52.5205 42.7599 49.8567C42.7599 47.1928 40.6005 45.0333 37.9366 45.0333C35.2728 45.0333 33.1133 47.1928 33.1133 49.8567C33.1133 52.5205 35.2728 54.68 37.9366 54.68Z" fill="white" />
                        <path d="M52.4 42.7033C55.0638 42.7033 57.2233 40.5438 57.2233 37.88C57.2233 35.2161 55.0638 33.0566 52.4 33.0566C49.7361 33.0566 47.5767 35.2161 47.5767 37.88C47.5767 40.5438 49.7361 42.7033 52.4 42.7033Z" fill="white" />
                        <path d="M72.6231 39.28C75.287 39.28 77.4465 37.1205 77.4465 34.4567C77.4465 31.7928 75.287 29.6334 72.6231 29.6334C69.9593 29.6334 67.7998 31.7928 67.7998 34.4567C67.7998 37.1205 69.9593 39.28 72.6231 39.28Z" fill="white" />
                        <path d="M91.2999 47.3134C93.9637 47.3134 96.1232 45.1539 96.1232 42.49C96.1232 39.8262 93.9637 37.6667 91.2999 37.6667C88.636 37.6667 86.4766 39.8262 86.4766 42.49C86.4766 45.1539 88.636 47.3134 91.2999 47.3134Z" fill="white" />
                        <path d="M101.32 60.7133C103.984 60.7133 106.143 58.5538 106.143 55.89C106.143 53.2261 103.984 51.0667 101.32 51.0667C98.6561 51.0667 96.4966 53.2261 96.4966 55.89C96.4966 58.5538 98.6561 60.7133 101.32 60.7133Z" fill="white" />
                        <path d="M104.22 77.01C106.884 77.01 109.043 74.8505 109.043 72.1867C109.043 69.5228 106.884 67.3633 104.22 67.3633C101.556 67.3633 99.3965 69.5228 99.3965 72.1867C99.3965 74.8505 101.556 77.01 104.22 77.01Z" fill="white" />
                        <path d="M98.5201 94.6433C101.184 94.6433 103.343 92.4838 103.343 89.82C103.343 87.1561 101.184 84.9966 98.5201 84.9966C95.8563 84.9966 93.6968 87.1561 93.6968 89.82C93.6968 92.4838 95.8563 94.6433 98.5201 94.6433Z" fill="white" />
                        <path d="M81.5768 108.787C84.2406 108.787 86.4001 106.627 86.4001 103.963C86.4001 101.299 84.2406 99.14 81.5768 99.14C78.9129 99.14 76.7534 101.299 76.7534 103.963C76.7534 106.627 78.9129 108.787 81.5768 108.787Z" fill="white" />
                        <path d="M60.0001 110.52C62.6639 110.52 64.8234 108.361 64.8234 105.697C64.8234 103.033 62.6639 100.873 60.0001 100.873C57.3362 100.873 55.1768 103.033 55.1768 105.697C55.1768 108.361 57.3362 110.52 60.0001 110.52Z" fill="white" />
                        <path d="M43.6568 101.843C46.3207 101.843 48.4802 99.6838 48.4802 97.02C48.4802 94.3561 46.3207 92.1967 43.6568 92.1967C40.993 92.1967 38.8335 94.3561 38.8335 97.02C38.8335 99.6838 40.993 101.843 43.6568 101.843Z" fill="white" />
                        <path d="M34.1368 87.9667C36.8007 87.9667 38.9601 85.8072 38.9601 83.1433C38.9601 80.4795 36.8007 78.32 34.1368 78.32C31.473 78.32 29.3135 80.4795 29.3135 83.1433C29.3135 85.8072 31.473 87.9667 34.1368 87.9667Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_21_10">
                            <rect width="136" height="136" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="side tails">
                <svg width="90" height="90" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_21_10)">
                        <path d="M68 136C105.555 136 136 105.555 136 68C136 30.4446 105.555 0 68 0C30.4446 0 0 30.4446 0 68C0 105.555 30.4446 136 68 136Z" fill="#5928E9" />
                        <path d="M77.4368 125.16C80.0233 125.16 82.1201 123.063 82.1201 120.477C82.1201 117.89 80.0233 115.793 77.4368 115.793C74.8502 115.793 72.7534 117.89 72.7534 120.477C72.7534 123.063 74.8502 125.16 77.4368 125.16Z" fill="white" />
                        <path d="M103.267 111.92C105.853 111.92 107.95 109.823 107.95 107.237C107.95 104.65 105.853 102.553 103.267 102.553C100.68 102.553 98.5835 104.65 98.5835 107.237C98.5835 109.823 100.68 111.92 103.267 111.92Z" fill="white" />
                        <path d="M116.793 91.3133C119.38 91.3133 121.477 89.2165 121.477 86.63C121.477 84.0435 119.38 81.9467 116.793 81.9467C114.207 81.9467 112.11 84.0435 112.11 86.63C112.11 89.2165 114.207 91.3133 116.793 91.3133Z" fill="white" />
                        <path d="M118.667 66.42C121.253 66.42 123.35 64.3232 123.35 61.7367C123.35 59.1501 121.253 57.0533 118.667 57.0533C116.08 57.0533 113.983 59.1501 113.983 61.7367C113.983 64.3232 116.08 66.42 118.667 66.42Z" fill="white" />
                        <path d="M108.22 42.4C110.806 42.4 112.903 40.3032 112.903 37.7167C112.903 35.1301 110.806 33.0333 108.22 33.0333C105.633 33.0333 103.537 35.1301 103.537 37.7167C103.537 40.3032 105.633 42.4 108.22 42.4Z" fill="white" />
                        <path d="M95.7099 28.7467C97.2618 28.7467 98.5199 27.4886 98.5199 25.9367C98.5199 24.3848 97.2618 23.1267 95.7099 23.1267C94.158 23.1267 92.8999 24.3848 92.8999 25.9367C92.8999 27.4886 94.158 28.7467 95.7099 28.7467Z" fill="white" />
                        <path d="M103.203 30.62C104.238 30.62 105.077 29.7813 105.077 28.7467C105.077 27.712 104.238 26.8733 103.203 26.8733C102.169 26.8733 101.33 27.712 101.33 28.7467C101.33 29.7813 102.169 30.62 103.203 30.62Z" fill="white" />
                        <path d="M114.857 49.75C116.409 49.75 117.667 48.4919 117.667 46.94C117.667 45.3881 116.409 44.13 114.857 44.13C113.305 44.13 112.047 45.3881 112.047 46.94C112.047 48.4919 113.305 49.75 114.857 49.75Z" fill="white" />
                        <path d="M119.54 54.4333C120.574 54.4333 121.413 53.5946 121.413 52.56C121.413 51.5254 120.574 50.6866 119.54 50.6866C118.505 50.6866 117.667 51.5254 117.667 52.56C117.667 53.5946 118.505 54.4333 119.54 54.4333Z" fill="white" />
                        <path d="M119.62 73.9767C121.172 73.9767 122.43 72.7186 122.43 71.1667C122.43 69.6148 121.172 68.3567 119.62 68.3567C118.068 68.3567 116.81 69.6148 116.81 71.1667C116.81 72.7186 118.068 73.9767 119.62 73.9767Z" fill="white" />
                        <path d="M120.286 80.47C121.321 80.47 122.16 79.6313 122.16 78.5967C122.16 77.562 121.321 76.7233 120.286 76.7233C119.252 76.7233 118.413 77.562 118.413 78.5967C118.413 79.6313 119.252 80.47 120.286 80.47Z" fill="white" />
                        <path d="M112.523 98.14C114.075 98.14 115.333 96.8819 115.333 95.33C115.333 93.7781 114.075 92.52 112.523 92.52C110.971 92.52 109.713 93.7781 109.713 95.33C109.713 96.8819 110.971 98.14 112.523 98.14Z" fill="white" />
                        <path d="M95.7099 115.81C97.2618 115.81 98.5199 114.552 98.5199 113C98.5199 111.448 97.2618 110.19 95.7099 110.19C94.158 110.19 92.8999 111.448 92.8999 113C92.8999 114.552 94.158 115.81 95.7099 115.81Z" fill="white" />
                        <path d="M66.8002 123.97C68.3522 123.97 69.6102 122.712 69.6102 121.16C69.6102 119.608 68.3522 118.35 66.8002 118.35C65.2483 118.35 63.9902 119.608 63.9902 121.16C63.9902 122.712 65.2483 123.97 66.8002 123.97Z" fill="white" />
                        <path d="M39.56 115.413C41.1119 115.413 42.37 114.155 42.37 112.603C42.37 111.051 41.1119 109.793 39.56 109.793C38.0081 109.793 36.75 111.051 36.75 112.603C36.75 114.155 38.0081 115.413 39.56 115.413Z" fill="white" />
                        <path d="M22.1435 96.14C23.6954 96.14 24.9535 94.8819 24.9535 93.33C24.9535 91.7781 23.6954 90.52 22.1435 90.52C20.5916 90.52 19.3335 91.7781 19.3335 93.33C19.3335 94.8819 20.5916 96.14 22.1435 96.14Z" fill="white" />
                        <path d="M110.11 104.093C111.145 104.093 111.983 103.255 111.983 102.22C111.983 101.185 111.145 100.347 110.11 100.347C109.076 100.347 108.237 101.185 108.237 102.22C108.237 103.255 109.076 104.093 110.11 104.093Z" fill="white" />
                        <path d="M89.5501 120.223C90.5847 120.223 91.4234 119.385 91.4234 118.35C91.4234 117.315 90.5847 116.477 89.5501 116.477C88.5155 116.477 87.6768 117.315 87.6768 118.35C87.6768 119.385 88.5155 120.223 89.5501 120.223Z" fill="white" />
                        <path d="M58.3733 124.033C59.4079 124.033 60.2467 123.195 60.2467 122.16C60.2467 121.125 59.4079 120.287 58.3733 120.287C57.3387 120.287 56.5 121.125 56.5 122.16C56.5 123.195 57.3387 124.033 58.3733 124.033Z" fill="white" />
                        <path d="M31.7332 110.73C32.7678 110.73 33.6065 109.891 33.6065 108.857C33.6065 107.822 32.7678 106.983 31.7332 106.983C30.6986 106.983 29.8599 107.822 29.8599 108.857C29.8599 109.891 30.6986 110.73 31.7332 110.73Z" fill="white" />
                        <path d="M17.3968 89.25C18.4314 89.25 19.2701 88.4113 19.2701 87.3767C19.2701 86.3421 18.4314 85.5034 17.3968 85.5034C16.3622 85.5034 15.5234 86.3421 15.5234 87.3767C15.5234 88.4113 16.3622 89.25 17.3968 89.25Z" fill="white" />
                        <path d="M16.6498 72.6433C18.2018 72.6433 19.4598 71.3852 19.4598 69.8333C19.4598 68.2814 18.2018 67.0233 16.6498 67.0233C15.0979 67.0233 13.8398 68.2814 13.8398 69.8333C13.8398 71.3852 15.0979 72.6433 16.6498 72.6433Z" fill="white" />
                        <path d="M15.0467 65.5467C16.0813 65.5467 16.92 64.7079 16.92 63.6733C16.92 62.6387 16.0813 61.8 15.0467 61.8C14.0121 61.8 13.1733 62.6387 13.1733 63.6733C13.1733 64.7079 14.0121 65.5467 15.0467 65.5467Z" fill="white" />
                        <path d="M20.4765 50.4167C22.0284 50.4167 23.2865 49.1586 23.2865 47.6067C23.2865 46.0548 22.0284 44.7967 20.4765 44.7967C18.9246 44.7967 17.6665 46.0548 17.6665 47.6067C17.6665 49.1586 18.9246 50.4167 20.4765 50.4167Z" fill="white" />
                        <path d="M38.56 29.4133C40.1119 29.4133 41.37 28.1553 41.37 26.6033C41.37 25.0514 40.1119 23.7933 38.56 23.7933C37.0081 23.7933 35.75 25.0514 35.75 26.6033C35.75 28.1553 37.0081 29.4133 38.56 29.4133Z" fill="white" />
                        <path d="M67.8002 20.92C69.3522 20.92 70.6102 19.6619 70.6102 18.11C70.6102 16.5581 69.3522 15.3 67.8002 15.3C66.2483 15.3 64.9902 16.5581 64.9902 18.11C64.9902 19.6619 66.2483 20.92 67.8002 20.92Z" fill="white" />
                        <path d="M23.4935 42.59C24.5281 42.59 25.3668 41.7513 25.3668 40.7167C25.3668 39.682 24.5281 38.8433 23.4935 38.8433C22.4588 38.8433 21.6201 39.682 21.6201 40.7167C21.6201 41.7513 22.4588 42.59 23.4935 42.59Z" fill="white" />
                        <path d="M44.7835 23.73C45.8181 23.73 46.6568 22.8913 46.6568 21.8567C46.6568 20.8221 45.8181 19.9833 44.7835 19.9833C43.7489 19.9833 42.9102 20.8221 42.9102 21.8567C42.9102 22.8913 43.7489 23.73 44.7835 23.73Z" fill="white" />
                        <path d="M76.2298 19.0467C77.2644 19.0467 78.1031 18.2079 78.1031 17.1733C78.1031 16.1387 77.2644 15.3 76.2298 15.3C75.1952 15.3 74.3564 16.1387 74.3564 17.1733C74.3564 18.2079 75.1952 19.0467 76.2298 19.0467Z" fill="white" />
                        <path d="M85.8664 26.08C88.453 26.08 90.5498 23.9832 90.5498 21.3967C90.5498 18.8101 88.453 16.7133 85.8664 16.7133C83.2799 16.7133 81.1831 18.8101 81.1831 21.3967C81.1831 23.9832 83.2799 26.08 85.8664 26.08Z" fill="white" />
                        <path d="M56.5002 24.2067C59.0868 24.2067 61.1836 22.1099 61.1836 19.5233C61.1836 16.9368 59.0868 14.84 56.5002 14.84C53.9137 14.84 51.8169 16.9368 51.8169 19.5233C51.8169 22.1099 53.9137 24.2067 56.5002 24.2067Z" fill="white" />
                        <path d="M30.7331 39.1133C33.3197 39.1133 35.4165 37.0165 35.4165 34.43C35.4165 31.8434 33.3197 29.7466 30.7331 29.7466C28.1466 29.7466 26.0498 31.8434 26.0498 34.43C26.0498 37.0165 28.1466 39.1133 30.7331 39.1133Z" fill="white" />
                        <path d="M18.2068 61.5934C20.7933 61.5934 22.8901 59.4966 22.8901 56.91C22.8901 54.3235 20.7933 52.2267 18.2068 52.2267C15.6202 52.2267 13.5234 54.3235 13.5234 56.91C13.5234 59.4966 15.6202 61.5934 18.2068 61.5934Z" fill="white" />
                        <path d="M17.3332 83.2166C19.9198 83.2166 22.0166 81.1198 22.0166 78.5333C22.0166 75.9468 19.9198 73.85 17.3332 73.85C14.7467 73.85 12.6499 75.9468 12.6499 78.5333C12.6499 81.1198 14.7467 83.2166 17.3332 83.2166Z" fill="white" />
                        <path d="M26.78 105.3C29.3665 105.3 31.4633 103.203 31.4633 100.617C31.4633 98.0302 29.3665 95.9333 26.78 95.9333C24.1935 95.9333 22.0967 98.0302 22.0967 100.617C22.0967 103.203 24.1935 105.3 26.78 105.3Z" fill="white" />
                        <path d="M47.0701 121.287C49.6566 121.287 51.7534 119.19 51.7534 116.603C51.7534 114.017 49.6566 111.92 47.0701 111.92C44.4835 111.92 42.3867 114.017 42.3867 116.603C42.3867 119.19 44.4835 121.287 47.0701 121.287Z" fill="white" />
                        <path d="M52.9067 78.4633L67.4367 57.0967" stroke="white" stroke-miterlimit="10" />
                        <path d="M71.71 57.0967L85.3833 77.61" stroke="white" stroke-miterlimit="10" />
                        <path d="M54.6167 80.1733L83.6734 64.79" stroke="white" stroke-miterlimit="10" />
                        <path d="M83.6735 79.3166L55.4702 64.79" stroke="white" stroke-miterlimit="10" />
                        <path d="M51.1968 78.4633V66.24" stroke="white" stroke-miterlimit="10" />
                        <path d="M54.6167 61.37L66.58 55.3867" stroke="white" stroke-miterlimit="10" />
                        <path d="M72.5635 55.3867L84.5301 61.37" stroke="white" stroke-miterlimit="10" />
                        <path d="M87.6533 66.24V77.61" stroke="white" stroke-miterlimit="10" />
                        <path d="M86.6668 66.4967C88.3181 66.4967 89.6568 65.158 89.6568 63.5067C89.6568 61.8553 88.3181 60.5167 86.6668 60.5167C85.0154 60.5167 83.6768 61.8553 83.6768 63.5067C83.6768 65.158 85.0154 66.4967 86.6668 66.4967Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M52.4802 66.4967C54.1316 66.4967 55.4702 65.158 55.4702 63.5067C55.4702 61.8553 54.1316 60.5167 52.4802 60.5167C50.8289 60.5167 49.4902 61.8553 49.4902 63.5067C49.4902 65.158 50.8289 66.4967 52.4802 66.4967Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M69.5735 57.95C71.2248 57.95 72.5635 56.6113 72.5635 54.96C72.5635 53.3086 71.2248 51.97 69.5735 51.97C67.9222 51.97 66.5835 53.3086 66.5835 54.96C66.5835 56.6113 67.9222 57.95 69.5735 57.95Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M51.6233 84.4433C53.2746 84.4433 54.6133 83.1047 54.6133 81.4533C54.6133 79.802 53.2746 78.4633 51.6233 78.4633C49.972 78.4633 48.6333 79.802 48.6333 81.4533C48.6333 83.1047 49.972 84.4433 51.6233 84.4433Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M86.6668 83.59C88.3181 83.59 89.6568 82.2513 89.6568 80.6C89.6568 78.9487 88.3181 77.61 86.6668 77.61C85.0154 77.61 83.6768 78.9487 83.6768 80.6C83.6768 82.2513 85.0154 83.59 86.6668 83.59Z" stroke="white" stroke-miterlimit="10" />
                        <path d="M63.6469 84.6333C64.201 84.6333 64.6502 84.1841 64.6502 83.63C64.6502 83.0759 64.201 82.6266 63.6469 82.6266C63.0928 82.6266 62.6436 83.0759 62.6436 83.63C62.6436 84.1841 63.0928 84.6333 63.6469 84.6333Z" fill="white" />
                        <path d="M69.8102 79.4733C70.4435 79.4733 70.9569 78.9599 70.9569 78.3267C70.9569 77.6934 70.4435 77.18 69.8102 77.18C69.177 77.18 68.6636 77.6934 68.6636 78.3267C68.6636 78.9599 69.177 79.4733 69.8102 79.4733Z" fill="white" />
                        <path d="M77.9401 82.8108L76.4033 81.274L74.8665 82.8108L76.4033 84.3476L77.9401 82.8108Z" fill="white" />
                        <path d="M32.4034 70.1234C35.0673 70.1234 37.2267 67.9639 37.2267 65.3C37.2267 62.6362 35.0673 60.4767 32.4034 60.4767C29.7396 60.4767 27.5801 62.6362 27.5801 65.3C27.5801 67.9639 29.7396 70.1234 32.4034 70.1234Z" fill="white" />
                        <path d="M37.9366 54.68C40.6005 54.68 42.7599 52.5205 42.7599 49.8567C42.7599 47.1928 40.6005 45.0333 37.9366 45.0333C35.2728 45.0333 33.1133 47.1928 33.1133 49.8567C33.1133 52.5205 35.2728 54.68 37.9366 54.68Z" fill="white" />
                        <path d="M52.4 42.7033C55.0638 42.7033 57.2233 40.5438 57.2233 37.88C57.2233 35.2161 55.0638 33.0566 52.4 33.0566C49.7361 33.0566 47.5767 35.2161 47.5767 37.88C47.5767 40.5438 49.7361 42.7033 52.4 42.7033Z" fill="white" />
                        <path d="M72.6231 39.28C75.287 39.28 77.4465 37.1205 77.4465 34.4567C77.4465 31.7928 75.287 29.6334 72.6231 29.6334C69.9593 29.6334 67.7998 31.7928 67.7998 34.4567C67.7998 37.1205 69.9593 39.28 72.6231 39.28Z" fill="white" />
                        <path d="M91.2999 47.3134C93.9637 47.3134 96.1232 45.1539 96.1232 42.49C96.1232 39.8262 93.9637 37.6667 91.2999 37.6667C88.636 37.6667 86.4766 39.8262 86.4766 42.49C86.4766 45.1539 88.636 47.3134 91.2999 47.3134Z" fill="white" />
                        <path d="M101.32 60.7133C103.984 60.7133 106.143 58.5538 106.143 55.89C106.143 53.2261 103.984 51.0667 101.32 51.0667C98.6561 51.0667 96.4966 53.2261 96.4966 55.89C96.4966 58.5538 98.6561 60.7133 101.32 60.7133Z" fill="white" />
                        <path d="M104.22 77.01C106.884 77.01 109.043 74.8505 109.043 72.1867C109.043 69.5228 106.884 67.3633 104.22 67.3633C101.556 67.3633 99.3965 69.5228 99.3965 72.1867C99.3965 74.8505 101.556 77.01 104.22 77.01Z" fill="white" />
                        <path d="M98.5201 94.6433C101.184 94.6433 103.343 92.4838 103.343 89.82C103.343 87.1561 101.184 84.9966 98.5201 84.9966C95.8563 84.9966 93.6968 87.1561 93.6968 89.82C93.6968 92.4838 95.8563 94.6433 98.5201 94.6433Z" fill="white" />
                        <path d="M81.5768 108.787C84.2406 108.787 86.4001 106.627 86.4001 103.963C86.4001 101.299 84.2406 99.14 81.5768 99.14C78.9129 99.14 76.7534 101.299 76.7534 103.963C76.7534 106.627 78.9129 108.787 81.5768 108.787Z" fill="white" />
                        <path d="M60.0001 110.52C62.6639 110.52 64.8234 108.361 64.8234 105.697C64.8234 103.033 62.6639 100.873 60.0001 100.873C57.3362 100.873 55.1768 103.033 55.1768 105.697C55.1768 108.361 57.3362 110.52 60.0001 110.52Z" fill="white" />
                        <path d="M43.6568 101.843C46.3207 101.843 48.4802 99.6838 48.4802 97.02C48.4802 94.3561 46.3207 92.1967 43.6568 92.1967C40.993 92.1967 38.8335 94.3561 38.8335 97.02C38.8335 99.6838 40.993 101.843 43.6568 101.843Z" fill="white" />
                        <path d="M34.1368 87.9667C36.8007 87.9667 38.9601 85.8072 38.9601 83.1433C38.9601 80.4795 36.8007 78.32 34.1368 78.32C31.473 78.32 29.3135 80.4795 29.3135 83.1433C29.3135 85.8072 31.473 87.9667 34.1368 87.9667Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_21_10">
                            <rect width="90" height="90" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    )
};
export default SiteLoader;