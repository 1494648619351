import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Range, getTrackBackground } from "react-range";
import { Checkbox } from "@mui/material";
import $ from "jquery";
// import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import { useContextData } from '../../../core/context/index';
import { tradeFanTknFeesAuth_API } from "../../../core/services/all.api";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config/";
import CrossMarginBalance from "./cross-margin";
// import CrossMarginList from "./Cross-margin-list";
import { BsDot } from 'react-icons/bs';
import { AiOutlineMinus, AiOutlinePlus, AiFillInfoCircle } from 'react-icons/ai';
import { Modal } from "react-bootstrap";

const STEP = 1;

export default function FutureTrade(props) {
  let {
    range = [0]
  } = props;

  const navigate = useNavigate();
  const leverageFutures = localStorage.getItem('leverageFutures') ? localStorage.getItem('leverageFutures') : 25;
  const [isloadingData, setIsloadingData] = useState({});
  const [tradeFanTknFees_info, setTradeFanTknFees_info] = useState("");
  const [orderPlacementPrefer, setOrderPlacementPrefer] = useState("Value");
  const [MIN, setMIN] = useState(1);
  const [MAX, setMAX] = useState(0);
  const isolatedButtonRef = useRef(null);
  const crossButtonRef = useRef(null);
  const [crossToggle, setCrossToggle] = useState(false);
  const [selectMethod, setSelectMethod] = useState("");
  const [leverageShow, setLeverageShow] = useState(false);
  const [leverageVal, setLeverageVal] = useState("");
  const [leverageArr, setLeverageArr] = useState({
    range: [0]
  })
  const [btnHide, setBtnHide] = useState(true);
  useEffect(() => {
    if ((props && props.pairDetails && props.pairDetails.maxLeverage) && MAX == 0) {
      let localLeve = localStorage.getItem('leverageFutures') ? parseInt(localStorage.getItem('leverageFutures')) : 2;

      if (localLeve > props.pairDetails.maxLeverage) {
        localLeve = props.pairDetails.maxLeverage;
      } else setBtnHide(false);

      if (localLeve < props.pairDetails.minLeverage) {
        localLeve = props.pairDetails.minLeverage;
      } else setBtnHide(false);
      setMIN(parseInt(props.pairDetails.minLeverage));
      setMAX(parseInt(props.pairDetails.maxLeverage));
      props.setRange({ leverage: [localLeve] });
      setLeverageVal(localLeve);
      setLeverageArr({ range: [localLeve] })
    }
  }, [props]);

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      var getStatusText = $(this).text();
      $(this).closest(".status_dropdown").find(".status__btn").text(getStatusText);
      var generateStatusClass = `${$(this).attr('data-className')}-status`
      $(this).closest(".status_dropdown").attr("data-color", `${generateStatusClass}`);
    })
  }, []);

  const { siteSettings, myProfile, setUserProfile } = useContextData();

  useEffect(() => {
    if (props && props.pairDetails && props.makerFee && siteSettings && siteSettings.tradeFeeDiscount && siteSettings.tradeFeeDiscountCurrency) {
      const mFee = (myProfile && myProfile.kycstatus === 1) ? props.pairDetails.makerFeeWithKYC : props.pairDetails.makerFee;
      const discount = props.decimalValue((mFee * (siteSettings.tradeFeeDiscount / 100)), 2);
      setTradeFanTknFees_info(discount + "% or 1$ worth of " + siteSettings.tradeFeeDiscountCurrency.currencySymbol + " in fees");
    }
  }, [siteSettings, props, myProfile]);


  // const levValChangePlusMin = (val) => {
  //   let curLevVal = 0;
  //   if (props.range && props.range.leverage && props.range.leverage[0]) {
  //     curLevVal = props.range.leverage[0];
  //   }
  //   if (val == "min") {
  //     curLevVal = curLevVal - 1;
  //   }

  //   if (val == "plus") {
  //     curLevVal = curLevVal + 1;
  //   }

  //   if (curLevVal >= MIN && curLevVal <= MAX) {
  //     levValChange([curLevVal]);
  //   }
  // }
  const levValChangePlusMin = (val) => {
    var curLevVal = 0;
    if (leverageArr && leverageArr.range && leverageArr.range[0]) {
      curLevVal = Number(leverageArr.range[0]);
    }
    if (val == "min") {
      curLevVal = curLevVal - 1;
    }

    if (val == "plus") {
      curLevVal = curLevVal + 1;
    }

    if (curLevVal < MIN || curLevVal > MAX) setBtnHide(true);
    if (curLevVal >= MIN && curLevVal <= MAX) {
      levValChange([curLevVal]);
    }
  }
  // const levValChange = (val) => {
  //   props.setRange({ leverage: val });
  //   props.calculateValues(val, "leverage");
  //   if (val && val[0]) {
  //     localStorage.setItem('leverageFutures', val[0]);
  //   }
  // }
  const levValChange = (val) => {
    var maxLeverage = 0, minLeverage = 0;
    if (props && props.pairDetails) {
      if (props.pairDetails.maxLeverage) {
        maxLeverage = props.pairDetails.maxLeverage;
      }
      if (props.pairDetails.minLeverage) {
        minLeverage = props.pairDetails.minLeverage;
      }
    }
    if (val[0] > maxLeverage) setBtnHide(true)
    if (val[0] < minLeverage) setBtnHide(true)
    setBtnHide(false);
    setLeverageArr({ range: val });
    props.calculateValues(val, "leverage");
    if (val && val[0]) {
      localStorage.setItem('leverageFutures', val[0]);
    }
  }
  //** leverage input field functionalities */
  // const handleChange = async (event) => {
  //   if (event.target.name == "leverage") {
  //     var maxLeverage = 0, minLeverage = 0, str_value = "", val = event.target.value;
  //     if (props && props.pairDetails) {
  //       if (props.pairDetails.maxLeverage) {
  //         maxLeverage = props.pairDetails.maxLeverage;
  //       }
  //       if (props.pairDetails.minLeverage) {
  //         minLeverage = props.pairDetails.minLeverage;
  //       }
  //     }
  //     // str_value = event.target.value;
  //     // if (props.range.leverage[0] && (val == "" || val == undefined)) str_value = props.range.leverage[0];
  //     if (val == "" || val == undefined) setBtnHide(true); str_value = val;
  //     if (val > maxLeverage) {
  //       setBtnHide(true)
  //       str_value = maxLeverage; // maxLeverage data fetch on DB
  //     }
  //     // else {
  //     //   setBtnHide(false)
  //     //   str_value = val;
  //     // }
  //     if (val < minLeverage) {
  //       setBtnHide(true)
  //       // str_value = minLeverage; // minLeverage data fetch on DB
  //     } else {
  //       setBtnHide(false)
  //       str_value = val;
  //     };
  //     if (str_value > maxLeverage) str_value = maxLeverage; // maxLeverage data fetch on DB
  //     props.setRange((preProps) => ({ ...preProps, leverage: [str_value] }));
  //     props.calculateValues([str_value], "leverage");
  //     localStorage.setItem('leverageFutures', str_value);
  //   }
  // };

  const handleChange = async (event) => {
    if (event.target.name == "leverage") {
      var maxLeverage = 0, minLeverage = 0, str_value = "", val = event.target.value;
      if (props && props.pairDetails) {
        if (props.pairDetails.maxLeverage) {
          maxLeverage = props.pairDetails.maxLeverage;
        }
        if (props.pairDetails.minLeverage) {
          minLeverage = props.pairDetails.minLeverage;
        }
      }
      // str_value = event.target.value;
      // if (props.range.leverage[0] && (val == "" || val == undefined)) str_value = props.range.leverage[0];
      if (val == "" || val == undefined) setBtnHide(true); str_value = val;
      if (val > maxLeverage) {
        setBtnHide(true)
        str_value = maxLeverage; // maxLeverage data fetch on DB
      }
      // else {
      //   setBtnHide(false)
      //   str_value = val;
      // }
      if (val < minLeverage) {
        setBtnHide(true)
        // str_value = minLeverage; // minLeverage data fetch on DB
      } else {
        setBtnHide(false)
        str_value = val;
      };
      if (str_value > maxLeverage) str_value = maxLeverage; // maxLeverage data fetch on DB
      setLeverageVal(str_value);
      setLeverageArr({ range: [str_value] })
      // props.setRange((preProps) => ({ ...preProps, leverage: [str_value] }));
      // props.calculateValues([str_value], "leverage");
      // localStorage.setItem('leverageFutures', str_value);
    }
  };
  //** leverage confirm function  */
  const handleLevSubmit = async (event) => {
    event.preventDefault();
    // let propLeverage = props.range.leverage[0]
    // if (propLeverage) {
    //   localStorage.setItem('leverageFutures', propLeverage);
    // }
    props.setRange((preProps) => ({ ...preProps, leverage: leverageArr.range }));
    props.calculateValues(leverageArr.range, "leverage");
    localStorage.setItem('leverageFutures', leverageArr.range[0]);
    setLeverageShow(!leverageShow);
  }
  const NavigateTo = (currency, id) => {
    let url = '/deposit-crypto/';
    if (currency == 'INR') {
      url = '/deposit-fiat/';
    }
    navigate(url + '' + id);
  }
  const loadingChange = (data = {}) => {
    const dataCopy = Object.assign({}, isloadingData);
    dataCopy[data.key] = data.value;
    setIsloadingData(dataCopy);
  }

  const NavigateToStaking = (url) => {
    navigate(url);
  }

  const tradeFanTknFeesAuth_call = async () => {
    if (myProfile && myProfile.kycstatus != 1) {
      toast({ type: "error", message: "Please verify your KYC." });
      return false;
    }
    loadingChange({ key: "tradeFanTknFees", value: true });
    await tradeFanTknFeesAuth_API();
    loadingChange({ key: "tradeFanTknFees", value: false });
    setUserProfile();
  }
  // useEffect(() => {
  //   if (props.userTradeDetails && props.userTradeDetails.crossPositionOrders) {
  //     props.userTradeDetails.crossPositionOrders.map((position) => props.orderData.method = position.method)
  //   } else {
  //     props.orderData.method = 'isolated';
  //   }
  // }, [props.userTradeDetails]);

  useEffect(() => {
    const updatedCrossPositionData = props.crossPosition;

    if (updatedCrossPositionData === "isolated" && isolatedButtonRef.current) {
      isolatedButtonRef.current.click();
    } else if (updatedCrossPositionData === "cross" && crossButtonRef.current) {
      crossButtonRef.current.click();
    }
  }, [props.crossPosition]);
  const validOrderMethod = (e) => {
    if (e.target.name === "isolated") setCrossToggle(false);
    else setCrossToggle(true);
    let isValid = true;
    if (props.userTradeDetails && props.userTradeDetails.crossPositionOrders) {
      const isValidMethod = props.userTradeDetails.crossPositionOrders.find((position) => (position.method != e.target.name))
      if (isValidMethod) {
        toast({ type: 'error', message: `The margin mode cannot be changed while you have an open order/position in ${isValidMethod.method}` });
        if (e.target.name === "isolated" && crossButtonRef.current) {
          const timer = setTimeout(() => {
            crossButtonRef.current.click();
          }, 3000);
          return () => clearTimeout(timer);
        } else if (e.target.name === "cross" && isolatedButtonRef.current) {
          const timer = setTimeout(() => {
            isolatedButtonRef.current.click();
          }, 3000);
          return () => clearTimeout(timer);
        }
        isValid = false;
        // return 
      }
      if (isValid) return props.setMethod(e.target.name);
      else return props.setMethod(""), props.orderData.method = 'isolated';
    }
  };

  return (
    <div>
      <div className="d-flex flex-row trading-page-top-section-2 align-items-center top-heading-total-height">
        <div className="ps-2">
          <p className="trade-text-5 mb-0">Place Order</p>
        </div>
      </div>
      <div className="">
        {/* isolated and cross button start */}
        <div className="place-order-buy-sell-tabs">
          <ul className="nav nav-pills nav-justified mx-2 mt-2" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">

              <button
                className={props.orderData.method == "isolated" ? "nav-link active" : "nav-link nav-link-buy"}
                id="pills-buy-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-buy"
                type="button"
                role="tab"
                aria-controls="pills-buy"
                aria-selected="true"
                name="isolated"
                ref={isolatedButtonRef}
                onClick={(e) => validOrderMethod(e)}
              >
                Isolated
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={props.orderData.method == "cross" ? "nav-link active" : "nav-link nav-link-buy"}
                id="pills-buy-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-buy"
                type="button" role="tab"
                aria-controls="pills-buy"
                aria-selected="false"
                name="cross"
                ref={crossButtonRef}
                onClick={(e) => validOrderMethod(e)}
              >
                Cross
              </button>
            </li>
          </ul>
        </div>
        {/* isolated and cross button end */}
        <div className="place-order-buy-sell-tabs">
          <ul className="nav nav-pills nav-justified mx-2 mt-2" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={props.orderData.type == "buy" ? "nav-link active" : "nav-link nav-link-buy"}
                id="pills-buy-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-buy"
                type="button"
                role="tab"
                aria-controls="pills-buy"
                aria-selected="true"
                onClick={() => props.setType('buy')}
              >
                Buy/Long
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={props.orderData.type == "sell" ? "nav-link nav-link-sell active" : "nav-link nav-link-buy"}
                id="pills-buy-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-buy"
                type="button" role="tab"
                aria-controls="pills-buy"
                aria-selected="false"
                onClick={() => props.setType('sell')}
              >
                Sell/Short
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content mx-2 mt-2" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab">
            <div className="dropdown custom-dropdown status_dropdown" data-color="created-status">
              <button
                className="capitalizeText select-dropdown-btn-buysell dropdown-toggle w-100 d-flex align-items-center justify-content-between status__btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-offset="0,12"
              >
                {props.orderData.orderType == "stop" ? "Stop Limit" : props.orderData.orderType}
              </button>
              <ul className="dropdown-menu status_change w-100" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" data-className="limit" href="javascript:void(0)" onClick={(e) => props.setOrderType('limit')}>Limit</a></li>
                <li><a className="dropdown-item" data-className="market" href="javascript:void(0)" onClick={(e) => props.setOrderType('market')}>Market</a></li>
                {/* <li><a className="dropdown-item" data-className="stopLimit" href="javascript:void(0)" onClick={(e) => props.setOrderType('stopLimit')}>Stop Limit</a></li>
                <li><a className="dropdown-item" data-className="stopMarket" href="javascript:void(0)" onClick={(e) => props.setOrderType('stopMarket')}>Stop Market</a></li>
                <li><a className="dropdown-item" data-className="trailingStop" href="javascript:void(0)" onClick={(e) => props.setOrderType('trailingStop')}>Trailing Stop</a></li>
                <li><a className="dropdown-item" data-className="postOnly" href="javascript:void(0)" onClick={(e) => props.setOrderType('postOnly')}>Post Only</a></li>
                <li><a className="dropdown-item" data-className="twap" href="javascript:void(0)" onClick={(e) => props.setOrderType('twap')}>TWAP</a></li> */}
              </ul>
            </div>
            {((props.range && props.range.leverage && props.range.leverage[0] > 0) && (MAX > 0)) ?
              <>
                {/* <p className="trade-text-6 mt-3">Leverage </p> */}
                {/* <div className="mx-1 d-flex align-items-center flex-row justify-content-between">
                  <FaMinusSquare className="plus-minus-css-styling" onClick={() => levValChangePlusMin("min")} />
                  <div className="input-group  px-2">
                    <Range
                      className=""
                      values={props.range.leverage}
                      step={STEP}
                      min={MIN}
                      max={MAX}
                      onChange={(val) => {
                        levValChange(val);
                      }}
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div
                            ref={props.ref}
                            className="percentage-line-section"
                            style={{
                              background: getTrackBackground({
                                values: (range.leverage) ? range.leverage : [0],
                                colors: [
                                  "#00DBAE",
                                  "rgb(220, 224, 229)",
                                ],
                                min: MIN,
                                max: MAX,
                              }),
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          className="percentage-display-section"
                          {...props}
                        >
                          <span className="total-value-price-section">
                            {range && range.leverage}{" "}X
                          </span>
                        </div>
                      )}
                    />

                  </div>
                  <FaPlusSquare className="plus-minus-css-styling" onClick={() => levValChangePlusMin("plus")} />
                </div> */}
                <div className="mt-2">
                  <button
                    // className="place-order-buy-button mt-3"
                    className="capitalizeText select-dropdown-btn-buysell w-100 d-flex align-items-center justify-content-between status__btn"
                    type="button"
                    onClick={() => setLeverageShow(!leverageShow)}
                  >
                    {/* {props.orderData.orderType == "stop" ? "Stop Limit" : props.orderData.orderType} */}
                    {`Leverage - ${range.leverage}x`}
                  </button>
                </div>
              </> : ""
            }

            {props.orderData.orderType == 'stop' &&
              <>
                <p className="trade-text-6 mt-3">Stop Price</p>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    onChange={(event) => props.calculateValues(event.target.value, 'stopPrice')}
                    name="stopPrice"
                    id="stopPrice"
                    value={props.orderData.stopPrice}
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text" id="basic-addon1">{props.toCurrency}</span>
                </div>
              </>
            }
            {props.orderData.orderType != 'market' &&
              <>
                <p className="trade-text-6 mt-3">{props.orderData.orderType == 'stop' ? 'Limit Price' : 'Price'} ({props.toCurrency})</p>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    onChange={(event) => props.calculateValues(event.target.value, 'price')}
                    name="price"
                    id="price"
                    value={props.orderData.price}
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text" id="basic-addon1">{props.toCurrency}</span>
                </div>
              </>
            }

            <p className="trade-text-6 mt-3">Amount ({props.orderData.amountType})</p>
            <div className="input-group mb-3">
              <input
                type="number"
                onChange={(event) => props.calculateValues(event.target.value, 'amount')}
                name='amount'
                id="amount"
                value={props.orderData.amount}
                className="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              {/* <span className="input-group-text" id="basic-addon1">{props.fromCurrency}</span> */}
              <div className="dropdown custom-dropdown status_dropdown" data-color="created-status">
                <button
                  className="capitalizeText select-dropdown-btn-amountType dropdown-toggle w-100 d-flex align-items-center justify-content-between status__btn"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-offset="0,12"
                >
                  {/* {props.orderData.amountType == "BTC" && props.orderData.amountType } */}
                  {props.orderData.amountType}
                </button>
                <ul className="dropdown-menu status_change w-100" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a className="dropdown-item"
                      data-className={props.fromCurrency}
                      href="javascript:void(0)"
                      onClick={() => props.setOrderAmountType(props.fromCurrency)}>
                      {props.fromCurrency}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item"
                      data-className={props.toCurrency}
                      href="javascript:void(0)"
                      onClick={() => props.setOrderAmountType(props.toCurrency)}>
                      {props.toCurrency}
                    </a>
                  </li>

                </ul>
              </div>
            </div>

            {
              (myProfile && myProfile._id) &&
              <div className="place-order-buy-sell-percentage-tabs mb-3">
                <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={props.tradeIndex.buy == '25' ? "nav-link active" : "nav-link"}
                      id="pills-25-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-25"
                      type="button"
                      role="tab" aria-controls="pills-25"
                      aria-selected="true"
                      onClick={(event) => props.percentageChange(props.orderData.type, '25')}
                    >
                      25%
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={props.tradeIndex.buy == '50' ? "nav-link active" : "nav-link"}
                      id="pills-50-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-50"
                      type="button" role="tab"
                      aria-controls="pills-50"
                      aria-selected="false"
                      onClick={(event) => props.percentageChange(props.orderData.type, '50')}
                    >
                      50%
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={props.tradeIndex.buy == '75' ? "nav-link active" : "nav-link"}
                      id="pills-75-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-75"
                      type="button" role="tab"
                      aria-controls="pills-75"
                      aria-selected="false"
                      onClick={(event) => props.percentageChange(props.orderData.type, '75')}
                    >
                      75%
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={props.tradeIndex.buy == '100' ? "nav-link active" : "nav-link"}
                      id="pills-100-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-100"
                      type="button" role="tab"
                      aria-controls="pills-100"
                      aria-selected="false"
                      onClick={(event) => props.percentageChange(props.orderData.type, '100')}
                    >
                      100%
                    </button>
                  </li>
                </ul>
              </div>
            }

            <div className="input-group mb-3 place-order-check">
              <Checkbox
                checked={props.orderData.isTP}
                onChange={(e) => {
                  props.serOrderData((prev) => {
                    return {
                      ...prev,
                      isTP: e.target.checked
                    }
                  })
                }}
                inputProps={{
                  "aria-label": "controlled",
                }}
                label="Take Profit"
              />
              <p className="trade-text-6 mt-3">Take Profit</p>
            </div>

            {
              props.orderData.isTP == true && <>
                <div className="input-group mb-3 place-order-check">
                  <input
                    type="number"
                    onChange={(e) => {
                      props.serOrderData((prev) => {
                        return {
                          ...prev,
                          tpPrice: e.target.value
                        }
                      })
                    }}
                    name='tpPrice'
                    id="tpPrice"
                    value={props.orderData.tpPrice}
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text" id="basic-addon1">{props.toCurrency}</span>
                </div>
              </>
            }

            <div className="input-group mb-3 place-order-check">
              <Checkbox
                checked={props.orderData.isSL}
                onChange={(e) => {
                  props.serOrderData((prev) => {
                    return {
                      ...prev,
                      isSL: e.target.checked
                    }
                  })
                }}
                inputProps={{
                  "aria-label": "controlled",
                }}
                label="Stop Loss"
              />
              <p className="trade-text-6 mt-3">Stop Loss</p>
            </div>

            {
              props.orderData.isSL == true && <>
                <div className="input-group mb-3 place-order-check">
                  <input
                    type="number"
                    onChange={(e) => {
                      props.serOrderData((prev) => {
                        return {
                          ...prev,
                          slPrice: e.target.value
                        }
                      })
                    }}
                    name='slPrice'
                    id="slPrice"
                    value={props.orderData.slPrice}
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text" id="basic-addon1">{props.toCurrency}</span>
                </div>
              </>
            }

            {(myProfile && myProfile._id) ?
              <>
                {props.orderData.type === 'buy' ?
                  <button
                    className="place-order-buy-button mt-3"
                    type="button"
                    onClick={(event) => props.submitTrade()}
                    style={{ backgroundColor: "#14B269" }}
                    disabled={props.socketOrdCreLoad || Config.DERIVATIVES_STATUS == "Disable" ? true : props.orderData.method === "" ? true : false}
                  >
                    {props.socketOrdCreLoad
                      ?
                      <span className="trade-text-15">
                        Loading...
                      </span>
                      :
                      <>Buy/Long</>
                    }
                  </button>
                  :
                  <button
                    className="place-order-buy-button mt-3"
                    type="button"
                    onClick={(event) => props.submitTrade()}
                    style={{ backgroundColor: "#FF4B5E" }}
                    disabled={props.socketOrdCreLoad || Config.DERIVATIVES_STATUS == "Disable" ? true : false}
                  >
                    {props.socketOrdCreLoad
                      ?
                      <span className="trade-text-15">
                        Loading...
                      </span>
                      :
                      <>Sell/Short</>
                    }
                  </button>
                }
              </>
              :
              <button className="place-order-login-button mt-3" type="button">
                <span>
                  <span className='fc-b cursor-poniter' onClick={() => navigate('/login')}>Login </span>
                  (or)
                  <span className='fc-b cursor-poniter' onClick={() => navigate('/register')}> Register Now</span> to trade
                </span>
              </button>
            }
          </div>

          {
            myProfile && myProfile._id && <div className="row mt-4">
              <div className="col-lg-12">
                <div className="d-flex flex-row">
                  <div className="d-flex">
                    <div className="ms-2">
                      <span className="d-block trade-text-7">Order Value</span>
                      <span className="d-block text-spot"></span>
                    </div>
                  </div>
                  <div className="ms-auto ">
                    <span className="d-block trade-text-7">{props.orderData.orderValue ?
                      props.decimalValue(props.orderData.orderValue, props.pairDetails.priceDecimal)
                      : 0} USDT</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="d-flex">
                    <div className="ms-2">
                      <span className="d-block trade-text-7">Order Cost</span>
                      <span className="d-block text-spot"></span>
                    </div>
                  </div>
                  <div className="ms-auto ">
                    <span className="d-block trade-text-7">{props.orderData.orderCost ?
                      props.decimalValue(props.orderData.orderCost, props.pairDetails.priceDecimal)
                      : 0} USDT</span>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="row">
            {
              (props.userTradeDetails && props.userTradeDetails.userId && props.pairDetails && props.pairDetails.fromCurrency) ?
                <div className="col-lg-12">
                  <h6 className="mt-3 trade-text-5 mb-2">Assets</h6>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src={props.pairDetails.toCurrency.image}
                          className="img-fluid-size"
                          alt=""
                        />
                      </div>
                      <div className="ms-2">
                        <span className="d-block trade-text-7">{props.pairDetails.toCurrency.currencySymbol}</span>
                        <span className="d-block text-spot">{props.pairDetails.toCurrency.currencyName}</span>
                      </div>
                    </div>
                    <div>
                      <span className="d-block trade-text-7">{props.decimalValue(props.userTradeDetails.toBalance, props.pairDetails.priceDecimal)}</span>
                      <span className="d-block text-spot text-end">{props.decimalValue((props.userTradeDetails.toBalance * props.pairDetails.toCurrency.USDvalue), 2)}$</span>
                    </div>
                  </div>

                  <div className="row justify-content-between mt-4">
                    <div className="col-6 pe-1">
                      <button className="place-order-buy-button" type="button" style={{ backgroundColor: "#14B269" }} onClick={() => { navigate('/deposit/crypto/' + props.pairDetails.toCurrency.currencySymbol) }} >DEPOSIT</button>
                    </div>
                    <div className="col-6 ps-1">
                      <button className="place-order-buy-button text-dark" type="button" style={{ backgroundColor: "#F5F8FB" }} onClick={() => { navigate('/withdraw/crypto/' + props.pairDetails.toCurrency.currencySymbol) }} >WITHDRAW</button>
                    </div>
                  </div>
                </div>
                :
                <div className="col-lg-12">
                </div>
            }
          </div>
          <hr />
          {/* cross order list of margin details managed */}
          {/* <CrossMarginList
              userTradeDetails={props.userTradeDetails}
              decimalValue={props.decimalValue}
              pairDetails={props.pairDetails}
            /> */}
          {crossToggle && (
            <CrossMarginBalance
              userTradeDetails={props.userTradeDetails}
              pairDetails={props.pairDetails}
              decimalValue={props.decimalValue}
              pairDetailsArr={props.pairDetailsArr}
            />
          )}

          <div className="row" style={{ display: "none" }}>
            <div className="col-lg-12">
              <h6 className="mt-3 trade-text-5 mb-2">Margin Ratio</h6>
              <div className="d-flex flex-row">
                <div className="d-flex">
                  <div className="ms-2">
                    <span className="d-block trade-text-7">Margin Ratio</span>
                    <span className="d-block text-spot"></span>
                  </div>
                </div>
                <div className="ms-auto ">
                  <span className="d-block trade-text-7">0.00%</span>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div className="d-flex">
                  <div className="ms-2">
                    <span className="d-block trade-text-7">Maintenance Margin</span>
                    <span className="d-block text-spot"></span>
                  </div>
                </div>
                <div className="ms-auto ">
                  <span className="d-block trade-text-7">0.0000 USDT</span>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div className="d-flex">
                  <div className="ms-2">
                    <span className="d-block trade-text-7">Margin Balance</span>
                    <span className="d-block text-spot"></span>
                  </div>
                </div>
                <div className="ms-auto ">
                  <span className="d-block trade-text-7">0.0000 USDT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Leverage model start  */}
      <Modal
        size="md"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        show={leverageShow}
        onHide={() => { setLeverageShow(!leverageShow) }}
      >
        <Modal.Header className='' closeButton>
          <Modal.Title>Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3 new-leverage-input-overrall-group">
            <button className="new-leverage-input-btn-1" type="button">
              <AiOutlineMinus onClick={() => levValChangePlusMin("min")} />
            </button>
            <input
              type="text"
              className="new-leverage-form-control-1"
              placeholder=""
              name="leverage"
              // value={props.range.leverage[0]}
              value={leverageArr.range[0]}
              onChange={handleChange}
              disabled
            />
            <button className="new-leverage-input-btn-1" type="button">
              <AiOutlinePlus onClick={() => levValChangePlusMin("plus")} />
            </button>
          </div>
          <div>
            <div
              className="mx-lg-3"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Range
                className=""
                // values={props.range.leverage}
                values={leverageArr.range}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(val) => {
                  levValChange(val);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "36px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      ref={props.ref}
                      className="percentage-line-section"
                      style={{
                        background: getTrackBackground({
                          // values: (range.leverage) ? range.leverage : [0],
                          values: leverageArr.range ? leverageArr.range : [0],
                          colors: [
                            "var(--btnClr)",
                            "rgb(220, 224, 229)",
                          ],
                          min: MIN,
                          max: MAX,
                        }),
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    className="percentage-display-section"
                    {...props}
                  >
                    <span className="total-value-price-section">
                      {/* {range && range.leverage}{" "}X */}
                      {leverageArr.range && leverageArr.range}{" "}X
                    </span>
                  </div>
                )}
              />
            </div>
            {/* <div className="d-flex flex-row justify-content-between ms-lg-2 text-small">
              <span className="ms-lg-1">1x</span>
              <span className="ms-lg-1">25x</span>
              <span className="ms-lg-1">50x</span>
              <span>75x</span>
              <span>100x</span>
              <span>125x</span>
            </div> */}
          </div>
          {/* <div className="d-flex flex-row gap-2 mt-3">
            <div>
              <BsDot />
            </div>
            <div>
              <small className="new-leverage-modal-text-1">Maximum position at current leverage: 50,000 USDT</small>
            </div>
          </div> */}
          <div className="d-flex flex-row gap-2">
            <div>
              <BsDot />
            </div>
            <div>
              <small className="new-leverage-modal-text-1">Please note that leverage changing will also apply for open
                positions and open orders</small>
            </div>
          </div>
          <div className="d-flex flex-row gap-2 text-danger mt-3">
            <div>
              <AiFillInfoCircle />
            </div>
            <div>
              <small className="new-leverage-modal-text-1">Selecting higher leverage, such as [10x], increases your liquidation risk. Always manage your risk levels. See our help article for more information</small>
            </div>
          </div>
          <div className="d-grid mt-3">
            <button
              className="btn text-white"
              style={{ fontSize: "13px" ,backgroundColor:"var(--btnClr)"}}
              type="button"
              onClick={handleLevSubmit}
              disabled={btnHide}
            >Confirm</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
