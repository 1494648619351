// import package
import React, { Fragment, useEffect, useRef } from "react";

// import component
import PositionCloseModal from "./PositionCloseModal";
import UnrealizedPnl from "./UnrealizedPnl";
import UnrealizedPnlVal from "./UnrealizedPnlVal";

// import helper
import { dateFormat, showNumber } from "../../../core/helper/date-format";
import {
  averagePrice,
  initialNewMargin,
  positionMargin,
} from "../../../core/helper/bybit/usdtPerpetual";
import UnrealizedPnlCross from "./UnrealizedPnl-cross";
import UnrealizedPnlValCross from "./UnrealizedPnlVal-cross";
import notFound from "../../../assets/images/no-history.gif";

const PositionTab = (props) => {
  const positionRef = useRef();

  const { positionOrders, socketConnection, userId, pairDetails, crossPositionOrders, pairDetailsArr } = props;
  const showMethod = (method) => {
    //** set the order method show on table */
    if (method == 'cross') return "cross"
    else if (method == 'isolated') return "iso"
  }
  return (
    <Fragment>
      <PositionCloseModal
        ref={positionRef}
        userId={userId}
        socketConnection={socketConnection}
      />
      <table className="table">
        <thead>
          <th>Date</th>
          <th>Pair</th>
          <th>Side</th>
          <th>Order Value</th>
          <th>Market Price</th>
          <th>Entry Price</th>
          <th>Liq. Price</th>
          <th>Amount</th>
          <th>Margin</th>
          <th>Unrealised P&L</th>
          <th>Close By</th>
        </thead>
        <tbody>
          {crossPositionOrders && crossPositionOrders.length == 0 ? (
            <tr colSpan={9} style={{ marginTop: "100px" }}>
              <td className='color-white text-center d-flex flex-column align-items-center gap-2 px-0 border-0' colSpan="7">
                <img src={notFound} alt="order-notfound" style={{ width: 70, height: 70 }} />
                <span>No Active Orders Found!</span>
              </td>
            </tr>
          ) : (
            ""
          )}
          {crossPositionOrders &&
            crossPositionOrders.length > 0 &&
            crossPositionOrders.map((elem, i) => {
              const {
                pair = {},
                pairName = "",
                createdAt = "",
                orderType = "",
                type = "",
                filled = [],
                totalAmount = 0,
                leverage = 1,
                liquidityPrice = 0,
              } = elem;
              const clrClassName = type == "buy" ? "color-green" : "color-red";

              const { decimalValue = 2, priceDecimal = 2, amountDecimal = 2, } = pair;
              console.log({ pair });

              const price = averagePrice(filled);

              return (
                <tr key={i}>
                  <td data-label="date">{dateFormat(createdAt)}</td>
                  <td data-label="pair">
                    {pairName ? pairName.replace("_", "/") : ""} perpetual
                    <span className="trade-text-3">{leverage}X</span>
                  </td>
                  <td
                    data-label="side"
                    className={"capitalizeText " + clrClassName}
                  >
                    {type}
                  </td>
                  <td
                    data-label="orderValue"
                    className={"capitalizeText " + clrClassName}
                  >
                    {props.decimalValue(price * totalAmount, priceDecimal)}
                  </td>
                  <td data-label="marketPrice">
                    {props.marketPriceDecimal(elem, priceDecimal)}
                    {/* {props.decimalValue(pairDetails.marketPrice, priceDecimal)} */}
                  </td>
                  <td data-label="entryPrice">
                    {props.decimalValue(price, priceDecimal)}
                  </td>
                  <td data-label="liquidityPrice">
                    {props.decimalValue(liquidityPrice, priceDecimal)}
                  </td>
                  <td data-label="amount">
                    {props.decimalValue(totalAmount, amountDecimal)}
                  </td>
                  <td data-label="margin">
                    {/* {props.decimalValue(
                      positionMargin(
                        price,
                        totalAmount,
                        leverage,
                        pairDetails.takerFee,
                        type,
                        filled
                      ),
                      priceDecml
                    )} */}
                    <div>
                      {props.decimalValue(
                        initialNewMargin(filled),
                        priceDecimal
                      )}
                    </div>
                    ({showMethod(elem.method)})
                  </td>
                  <td data-label="unPnL" className="capitalizeText">
                    <div>
                      {/* <UnrealizedPnlVal
                        positionDoc={elem}
                        entryPrice={price}
                        pairDetails={pairDetails}
                        decimalValue={props.decimalValue}
                        pair={pair}
                        pairDetailsArr = {pairDetailsArr}
                      /> */}
                      <UnrealizedPnlValCross
                        positionDoc={elem}
                        entryPrice={price}
                        pairDetails={pairDetails}
                        decimalValue={props.decimalValue}
                        pair={pair}
                        pairDetailsArr={pairDetailsArr}
                      />
                    </div>
                    {/* <UnrealizedPnl
                      positionDoc={elem}
                      entryPrice={price}
                      pairDetails={pairDetails}
                      decimalValue={props.decimalValue}
                      pair={pair}
                      pairDetailsArr={pairDetailsArr}
                    /> */}
                    <UnrealizedPnlCross
                      positionDoc={elem}
                      entryPrice={price}
                      pairDetails={pairDetails}
                      decimalValue={props.decimalValue}
                      pair={pair}
                      pairDetailsArr={pairDetailsArr}
                    />
                  </td>
                  <td data-label="Action" className="d-flex flex-column gap-2">
                    <button
                      className="market-trade-button-small market-trade-button-2"
                      // className="market-trade-button"
                      onClick={() =>
                        positionRef.current.show({
                          leverage: leverage,
                          amount: totalAmount,
                          pair: pairName,
                          type: type == "buy" ? "sell" : "buy",
                          orderType: "market",
                          pairDetails: pairDetails,
                        })
                      }
                    >
                      Market
                    </button>
                    <button
                      className="market-trade-button-small market-trade-button-2"
                      // className="market-trade-button"
                      onClick={() =>
                        positionRef.current.show({
                          leverage: leverage,
                          amount: totalAmount,
                          pair: pairName,
                          type: type == "buy" ? "sell" : "buy",
                          orderType: "limit",
                          pairDetails: pairDetails,
                        })
                      }
                    >
                      Limit
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Fragment>
  );
};

export default PositionTab;
