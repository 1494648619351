import React, { useState, useEffect, useRef } from "react";
import "../../assets/style.css";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { BiCaretDown } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useContextData } from "../../core/context/index";
import axios from "axios";
import Config from "../../core/config/index";
import moment from "moment";
import { toast } from "../../core/lib/toastAlert";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { makeRequest } from "../../core/services/v1/request";
import { dateFormat } from "../../core/helper/date-format";
import RedeemModal from "./RedeemModal";
import { GoChevronLeft } from "react-icons/go";
import notFound from "../../assets/images/file.png"

export default function Simpleearn(props) {
  const navigate = useNavigate();
  const { myProfile } = useContextData();
  const [stakeHistory, setStakeHistory] = useState([]);
  const [redeemedData, setRedeemedData] = useState({});
  const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
  const [earnType, setEarnType] = useState("Fixed");
  const [redeemReward, setRedeemReward] = useState(0);
  const [termsAndCond, setTermsAndCond] = useState(false);
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalDocs, setTotalDocs] = useState(0);

  const modelCloseRef = useRef();
  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };
  const clickinputbox = async () => {
    if (termsAndCond == false) {
      setTermsAndCond(true);
    } else {
      setTermsAndCond(false);
    }
  };
  const columns = [
    {
      id: 0,
      name: "Currency",
      selector: (row) => (
        console.log("row", row),
        <div className="">
          <img className="simpleearn-table-coin-img" src={row.stakeData.currencyimage} />
        </div>
      ),
    },
    {
      id: 1,
      name: "Asset",
      selector: (row) => row.currencySymbol,
    },
    {
      id: 2,
      name: "Invest",
      selector: (row) => {
        return `${row.investAmount} ${row.currencySymbol}`;
      },
    },
    {
      id: 3,
      name: "Subscription Date(UTC)",
      selector: (row) => dateFormat(row.startingDate),
    },
    {
      id: 4,
      name: "Est.APR",
      selector: (row) => row.aprPercentage + "%",
    },
    {
      id: 5,
      name: "Locked(Days)",
      selector: (row) => earnType === "Fixed" ? row.lockedDays : "∞",
    },
    {
      id: 6,
      name: "Cumulative Real-Time APR Rewards",
      selector: (row) => {
        const currentDate = new Date().getTime();
        const startDate = new Date(row.startingDate).getTime();
        const diffDays = (currentDate - startDate) / 86400000;
        const totalReward = (row.aprPercentage / 100) * row.investAmount;
        const actualOneDayReward = totalReward / 365.25;
        const originalDays = parseInt(diffDays);

        const formatWithoutRounding = (value, decimals) => {
          const factor = Math.pow(10, decimals);
          return Math.floor(value * factor) / factor;
        };

        if (row.type === "Fixed") {
          if (diffDays >= row.lockedDays && diffDays >= 1) {            
            return formatWithoutRounding(actualOneDayReward * row.lockedDays, row.currencyDet.siteDecimal).toFixed(row.currencyDet.siteDecimal);         
            // return parseFloat(actualOneDayReward * row.lockedDays).toFixed(row.currencyDet.siteDecimal)
          } else if (diffDays >= 1) {
            return formatWithoutRounding(actualOneDayReward * originalDays, row.currencyDet.siteDecimal).toFixed(row.currencyDet.siteDecimal);         
            // return parseFloat(actualOneDayReward * originalDays).toFixed(row.currencyDet.siteDecimal)
          } else {
            return 0
          }
        } else {
          if (diffDays >= 1) {
            return formatWithoutRounding(actualOneDayReward * originalDays, row.currencyDet.siteDecimal).toFixed(row.currencyDet.siteDecimal);         
            // return parseFloat(actualOneDayReward * originalDays).toFixed(row.currencyDet.siteDecimal)
          } else {
            return 0
          }
        }
      },
    },
    {
      id: 7,
      name: "Expire Date(UTC)",
      selector: (row) => row.expireDate != null ? dateFormat(row.expireDate) : "-",
    },
    {
      id: 8,
      name: "USD Value",
      selector: (row) => parseFloat(Number(row.currencyDet.USDvalue * (row.investAmount))).toFixed(4),
    },
    {
      id: 9,
      name: "Action",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="bg-transparent border-0 simpleearn-redeem"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => redeemfunction(row)}
          >
            Redeem
          </button>
        </div>
      ),
    },
  ];

  const redeemfunction = async (rewardDatas) => {
    setRedeemedData(rewardDatas);
    const investAmount = rewardDatas.investAmount;
    const currentDate = new Date().getTime();
    const startDate = new Date(rewardDatas.startingDate).getTime();
    const differentedDays = (currentDate - startDate) / 86400000;
    const currectDays = parseInt(differentedDays);
    const lockedDays = rewardDatas.lockedDays;    

    if (rewardDatas.type === "Fixed") {
      if (currectDays >= lockedDays) {        
        const reward = (rewardDatas.aprPercentage / 100) * investAmount;
        const actualOneDayReward = reward / 365.25;
        const totalReward = differentedDays >= rewardDatas.lockedDays && differentedDays >= 1 ?
        parseFloat(investAmount + (actualOneDayReward * rewardDatas.lockedDays)).toFixed(rewardDatas.currencyDet.siteDecimal)
          : differentedDays >= 1 ?
          parseFloat(actualOneDayReward * currectDays).toFixed(rewardDatas.currencyDet.siteDecimal)
            : 0            
        setRedeemReward(totalReward);
      } else {
        setRedeemReward(investAmount);
      };
    } else {
      if (differentedDays >= 1) {
        const currectDays = parseInt(differentedDays);
        const investAmount = rewardDatas.investAmount;
        const reward = (rewardDatas.aprPercentage / 100) * investAmount;
        const actualOneDayReward = reward / 365.25;
        const totalReward = currectDays * actualOneDayReward;
        setRedeemReward(rewardDatas.investAmount + totalReward);
      } else {
        setRedeemReward(rewardDatas.investAmount);
        toast({ type: "success", message: "You Can Claim You'r Reward After One Day!" });
      };
    };
  };

  const handleRedeem = async () => {
    try {
      if (!termsAndCond) {
        return toast({ type: "error", message: "Agree to Terms and Conditions" })
      }
      const redeemData = {
        earnId: redeemedData._id,
        currencyId: redeemedData.currencyId,
        amount: redeemReward
      };
      const params = {
        url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/redeemStakeReward`,
        method: "post",
        data: redeemData
      }
      const res = await makeRequest(params);
      if (res.status) {
        toast({ type: "success", message: res.message });
        await getMyActiveOrderHis();
        if (modelCloseRef.current) {
          modelCloseRef.current.click();
        };
      } else {
        toast({ type: "error", message: res.message });
        await getMyActiveOrderHis();
      }
    } catch (error) {
      console.log("error", error)
      toast({ type: "error", message: "Something Went Wroung!" })
    };
  };

  const getMyActiveOrderHis = async () => {
    const params = {
      url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/userActiveStakeHistory?limit=${rowsPerPage}&page=${currentPage}`,
      method: "POST",
      data: { earnType }
    };
    const response = await makeRequest(params)
    if (response.status) {
      setStakeHistory(response.data);
      setTotalDocs(response.total);
    };
  };
  const [estimatedValue, setEstimatedValue] = useState({});
  console.log({ estimatedValue });

  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        estimateINR = 0,
        estimateUSD = 0,
        estimateSimpleEarnUSD = 0,
      } = response;
      setEstimatedValue({
        estimateINR,
        estimateUSD,
        estimateSimpleEarnUSD,
      });
    };
  };


  useEffect(() => {
    getMyActiveOrderHis();
    getWalletCurrency()
  }, [myProfile, earnType, currentPage, rowsPerPage]);

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="accountearn-top-banner-section">
        <div className="container py-3">
          <div className="row align-items-center">
            <div className="col">
              <a href="/simple-earn">
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Back</span>
              </a>
            </div>
          </div>
        </div>
        <div className="simpleearn-top-value-section">

          <div className="accountearn-top-value-section bg-transparent">

            <div className="container ">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div class="d-flex flex-lg-row flex-column align-items-lg-center">
                    <div>
                      <p className="accountearn-text-1 mb-0">Active Stakes</p>
                    </div>
                    <div class="ms-auto">
                      <button
                        type="button"
                        className="border-0 accountearn-all-assest"
                        onClick={() => navigate("/simple-earn-history")}
                      >
                        All History
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accountearn-top-value-section-2 mt-4">
            <div className="container ">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <p className="accountearn-text-2 mb-2">
                    Est. Total Value{" "}
                    <button className="bg-transparent border-0">
                      {balShow ? (
                        <AiFillEye onClick={() => balShowHideCall()} />
                      ) : (
                        <AiFillEyeInvisible onClick={() => balShowHideCall()} />
                      )}
                    </button>
                  </p>
                  {balShow ? (
                    <p>
                      {estimatedValue && estimatedValue.estimateUSD > 0 ? parseFloat(estimatedValue.estimateUSD).toFixed(4) : 0}&nbsp;
                      USD{" "}
                    </p>
                  ) : (
                    <p>***Balance hidden***</p>
                  )}
                  <div className="mt-5">
                    <a className="accountearn-all-assest" href="/my/wallet">View All Assets</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accountearn-top-value-section-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 accountearn-staking-tabs-section">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <Link to="/simple-earn" className="nav-link active" id="pills-earn-staking-1-tab" role="tab" aria-controls="pills-earn-staking-1" aria-selected="true">Simple Earn</Link>
                  </li>
                </ul>
                {/* <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-earn-staking-1"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-1-tab"
                    tabindex="0"
                  >
                    <div className="row py-3">
                      <div className="col-lg-3 col-6">
                        <p className="mb-1 mt-3 fs-12">Estimated Positions</p>
                        <p className="mb-0 accountearn-text-4">
                         0 USD
                        </p>
                      </div>
                      <div className="col-lg-3 col-6">
                        <p className="mb-1 mt-3 fs-12">Total Profit</p>
                        <p className="mb-0 accountearn-text-4">
                          0 USD
                        </p>
                      </div>
                      <div className="col-lg-3 col-6">
                        <p className="mb-1 mt-3 fs-12">Last Day Profit</p>
                        <p className="mb-0 accountearn-text-4">
                          0 USD
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-2"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-2-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-3"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-3-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-4"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-4-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-5"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-5-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-6"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-6-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-7"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-7-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                </div> */}
              </div>

              <div className="col-lg-12 accountearn-staking-table-tabs-section mt-3">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      className={`fixedOrFlex ${earnType === "Fixed" && "fixedOrFlex-active"}`}
                      id="pills-earn-staking-table-1-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-earn-staking-table-2"
                      type="button"
                      role="tab"
                      aria-controls="pills-earn-staking-table-2"
                      aria-selected="false"
                      onClick={() => {
                        setEarnType("Fixed");
                      }}
                    >
                      Fixed
                    </button>
                  </li>&nbsp;&nbsp;&nbsp;
                  <li class="nav-item" role="presentation">
                    <button
                      className={`fixedOrFlex ${earnType === "Flexible" && "fixedOrFlex-active"}`}
                      onClick={() => {
                        setEarnType("Flexible");
                      }}>
                      Flexible
                    </button>
                  </li>
                </ul>

                <div className="my-4 simpleearn-staking-subscribe-table">
                  <DataTable
                    keyField="id"
                    columns={columns}
                    data={stakeHistory}
                    pagination
                    paginationServer
                    persistTableHead
                    paginationPerPage={rowsPerPage}
                    paginationTotalRows={totalDocs}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    onChangeRowsPerPage={newRowsPerPage => {
                      setRowsPerPage(newRowsPerPage);
                      setCurrentPage(1); // Reset to first page when rows per page changes
                    }}
                    onChangePage={page => setCurrentPage(page)}
                    noDataComponent={
                      <div className="text-center py-2">
                        <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                        <br />
                        <span>No Records Found</span>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RedeemModal
        redeemedData={redeemedData}
        redeemReward={redeemReward}
        handleRedeem={handleRedeem}
        earnType={earnType}
        modelCloseRef={modelCloseRef}
        clickinputbox={clickinputbox}
      />
      <Footer />
    </div>
  );
};