import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import "../../assets/competition.css";
import { GoChevronLeft } from "react-icons/go";
import { HiOutlineArrowRight } from "react-icons/hi";
import NavbarOne from "../../Pages/siteTheme/NavbarOne";
import Footer from "../../Pages/siteTheme/Footer";
import $ from "jquery";
import axios from "axios";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Config from "../../core/config/index";
import { useContextData } from "../../core/context/index";
import Banner1 from '../../assets/Nexchange-images/competition/banner-2.png';
import { PiTicketLight } from "react-icons/pi";
import { CiViewList } from "react-icons/ci";

export default function Tradingcompetitioncompletion(props) {
  const [swipedata, setswipedata] = useState([]);
  const [cmsbannertitle, setcmsbannertitle] = useState("");
  const [cmsbannerdescription, setcmsbannerdescription] = useState("");
  const { myProfile } = useContextData();
  const navigate = useNavigate()

  useEffect(() => {
    const gettotalvolume = async () => {
      const gettotalvol = await axios.get(
        `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolume`
      );
      const getcompetiton = await axios.get(
        `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcurrencycompetion`
      );
      const firstarr = gettotalvol.data;
      const secondarr = getcompetiton.data;
      const monthpush = [];
      for (var i = 0; i < secondarr.length; i++) {
        var car = {};
        const tokensuserstart = Number(
          (new Date(firstarr[i].dateTime).getTime() / 1000).toFixed()
        );
        const mytokenstartDate =
          new Date(secondarr[i].tokenstartdate).getTime() / 1000;
        const mytokenendDate =
          new Date(secondarr[i].tokenenddate).getTime() / 1000;
        const currentUnixTime = Math.floor(Date.now() / 1000);
        if (currentUnixTime >= mytokenendDate) {
          const currencypair = secondarr[i].currency;
          const tradepair = { currencypair };
          const tradespair = await axios.post(
            `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolumeemail`,
            tradepair
          );
          const arr3 = tradespair.data.countarrs;
          if (arr3 == undefined) {
            car["tokensymbol"] = secondarr[i].tokensymbol;
            car["tradeamount"] = firstarr[i].amount;
            car["image"] = secondarr[i].image
            car["prizepool"] = secondarr[i].prizepool
            car["totalwinners"] = secondarr[i].totalwinners
            car["usdprice"] = firstarr[i].usdPrice;
            car["currency"] = secondarr[i].currency;
            car["totalwinners"] = secondarr[i].totalwinners
            car["competitionimage"] = secondarr[i].competitionimage
            car["userid"] = firstarr[i].userId;
            car["totalparticipant"] = 0;
            car["_id"] = secondarr[i]._id;
            monthpush.push(car);
          } else {
            const sortedArr = arr3
              .sort((a, b) => b.totalvolumeuser - a.totalvolumeuser)
              .map((item, index) => ({ ...item, serial: index + 1 }));
            car["tokensymbol"] = secondarr[i].tokensymbol;
            car["tradeamount"] = firstarr[i].amount;
            car["image"] = secondarr[i].image
            car["prizepool"] = secondarr[i].prizepool
            car["totalwinners"] = secondarr[i].totalwinners
            car["usdprice"] = firstarr[i].usdPrice;
            car["currency"] = secondarr[i].currency;
            car["totalwinners"] = secondarr[i].totalwinners
            car["competitionimage"] = secondarr[i].competitionimage
            car["userid"] = firstarr[i].userId;
            car["totalparticipant"] = sortedArr.length;
            car["_id"] = secondarr[i]._id;
            monthpush.push(car);
          }
        }
      }
      const countObj = monthpush.reduce((acc, obj) => {
        if (!acc[obj.pairname]) {
          acc[obj.pairname] = {
            ...obj,
            count: 0,
          };
        } else {
          acc[obj.pairname].count++;
        }
        return acc;
      }, {});
      const countArr = Object.values(monthpush);
      console.log("countArr", countArr);
      const mergedArr = countArr.map((obj1) => {
        const obj2 = secondarr.find((obj2) => obj2._id === obj1.competitionId);
        if (obj2) {
          return { ...obj1, ...obj2 };
        }
        return obj1;
      });
      setswipedata(countArr);
    };
    const getcmsdata = async () => {
      const getcms = await axios.get(
        `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcmstandc`
      );
      setcmsbannertitle(getcms.data[2]?.title);
      setcmsbannerdescription(getcms.data[2]?.description);
    };
    getcmsdata();
    gettotalvolume();
  }, [myProfile]);
  const moreDetails = async (symbol, currency, id) => {
    navigate(`/trading-competition-dashboard/${symbol}/${currency}`, { state: { competitionId: id } })
  }
  console.log("swipe", swipedata);
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      {/* <div className="deposit-page-top-banner ">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <a href="/">
                  <GoChevronLeft className="deposit-back-button-icon" />
                  <span className="deposit-text-1">Back</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section className="trading-competition-banner-section py-5 deposit-page-top-banner tournament-active-nav-class">
        <div className="container-fluid col-lg-10">
          <div className="row justify-content-center align-items-center flex-lg-row-reverse">
            <div className="col-lg-5 text-center">
              <img src={Banner1} alt="Banner1" className="w-75" />
            </div>
            <div className="col-lg-6">
              <p className="trading-competition-text-1 mb-4 text-start">{cmsbannertitle}</p>
              <div
                className="trading-competition-text-2 text-start"
                dangerouslySetInnerHTML={{ __html: cmsbannerdescription }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <div class="glow-effect-1"></div>
      <div class="glow-effect-2"></div>

      <div className="container">
        {swipedata.length === 1 && (<p className="trading-competition-text-1 text-center">COMPLETED</p>)}
        {/* {swipedata.length === 0 && (
          <div className="text-center mt-4">
            <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
            <p className="mt-2">No data Available</p>
          </div>
        )} */}
        <div className="row row-cols-lg-3 row-cols-1 g-4 align-items-center">

          {swipedata.map((item) => (
            //   <Link
            //     to={`/trading-competition-dashboard/${item.tokensymbol}/${item.currency}`}
            //   >
            <div className="col" key={item.i}>
              <div className="post-d-card-1 border p-3">
                <div className="row align-items-center">
                  <div className="col-lg-12 pb-3">

                    <div className="d-flex flex-row gap-2 align-items-center mb-3">
                      <img
                        className="trading-competition-image-1"
                        src={item.image}
                        alt="slide1"
                      />
                      <span className="trading-competition-text-3">
                        {" "}
                        {item.currency}
                      </span>
                      <div class="countdown-el ms-auto">
                        <p class="big-text" style={{ width: "fit-content" }}>Finished</p>
                      </div>
                    </div>

                    <div className="post-d-card-1-grd p-1">
                      <div className="d-flex flex-row gap-3 align-items-center mt-1">
                        <div>
                          <h2><PiTicketLight /></h2>
                        </div>
                        <div>
                          <p class="f-12 mb-0">
                            Prize Pool : <span class="f-14 fw-600  mb-0">{item.prizepool} {item.prizetoken}</span>
                          </p>
                          <p class="f-12 mb-0">
                            Places : <span class="f-16 fw-600  mb-0">{item.totalwinners}</span>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-row gap-3 align-items-center mt-1">
                        <div>
                          <h2><CiViewList /></h2>
                        </div>
                        <div>
                          <p class="f-12 mb-0">
                            Total participants : <span class="f-14 fw-600  mb-0">{item.totalparticipant}</span>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="d-flex justify-content-end pt-3">
                    <div style={{ cursor: 'pointer' }} onClick={() => moreDetails(item.tokensymbol, item.currency, item._id)}
                      class="mb-1 view-m-btn">
                      View More
                    </div>
                    {/* <a
                      href={`/trading-competition-dashboard/${item.tokensymbol}/${item.currency}`}
                      class="trading-competition-text-5 mb-1 "
                    >
                      View More <HiOutlineArrowRight />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            //   </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
