import React, { useState, useEffect } from "react";
function RedeemModal(props) {
    const { redeemedData, redeemReward, handleRedeem, modelCloseRef, clickinputbox } = props;
    return (
        <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                            Redeem {redeemedData.currencySymbol}
                        </h1>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={modelCloseRef}
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div className="d-flex flex-row align-items-end">
                            <p className="mb-1 simpleearn-text-11">Redemption Amount</p>
                            <p className="fs-12 mb-1 ms-auto">
                                Available for Redemption :
                                <span className="text-muted">
                                    {parseFloat(redeemReward).toFixed(redeemedData?.currencyDet?.siteDecimal)} {redeemedData.currencySymbol}
                                </span>
                            </p>
                        </div>

                        <div class="simpleearn-subscription-amount input-group mb-0">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Amount"
                                aria-label="Enter Amount"
                                aria-describedby="basic-addon2"
                                value={parseFloat(redeemReward).toFixed(redeemedData?.currencyDet?.siteDecimal)}
                                disabled={true}
                                readOnly={true}
                            />
                            <span
                                class="input-group-text subscription-amount-group-2"
                                id="basic-addon2"
                            >
                                <img
                                    className="simpleearn-subscribe-img"
                                    src={redeemedData?.stakeData?.currencyimage}
                                />
                                <span className="ms-1 fs-14">{redeemedData.currencySymbol}</span>
                            </span>
                        </div>

                        <p className="fs-12 my-3">
                            The daily redemption limit is 20000000 {redeemedData.currencySymbol}. You will get the funds the same day you redeem it
                        </p>
                        <p className="fs-12 text-muted">
                            * Holdings that are currently accruing rewards will be redeemed
                            first.
                        </p>

                        <div class="form-check mt-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={clickinputbox}
                            />
                            <label class="form-check-label fs-12" for="flexCheckDefault">
                                I have read and agreed to{" "}
                                <span style={{ color: "var(--btnClr)" }}>
                                    Bitnevex Simple Earn Service Agreement
                                </span>
                            </label>
                        </div>

                        <div className="row g-3 mt-2 mb-3">
                            <div className="col-lg-6">
                                <div class="d-grid">
                                    <button
                                        class="accountearn-redeem-1"
                                        type="button"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="d-grid">
                                    <button
                                        class="accountearn-redeem-1"
                                        type="button"
                                        onClick={() => handleRedeem()}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RedeemModal;