import Config from "../config/";
import { makeRequest } from "../services/v1/request";
import { getCookie, deleteCookie } from '../helper/cookie';
import axios from "axios";
import { userSessionLogout } from "../helper/common";
import { publicIpv4 } from 'public-ip';
import iplocation from 'iplocation';

export async function getSiteSettings() {
  const params = {
    url: `${Config.V1_API_URL}common/siteSettings`,
    method: 'GET',
  }
  return (await makeRequest(params));
}
export async function getP2PSettings() {
  const params = {
    url: `${Config.V1_API_URL}p2p/getP2PSettings`,
    method: 'GET',
  }
  return (await makeRequest(params));
}

export async function getMyProfile() {
  let token = "";
  token = getCookie("userToken");
  if (token) {
    const params = {
      url: `${Config.V1_API_URL}user/getMyProfile`,
      method: 'GET',
    }
    const myProfileResp = (await makeRequest(params));
    if (!myProfileResp || !myProfileResp.status || myProfileResp.status === "error") {
      await userSessionLogout();
      deleteCookie('userToken');
      return null
    }
    return myProfileResp;
  }
};

let a
// setInterval(async () => {
//   console.log("location", await iplocation(await publicIpv4()),a++)
// },1445)

export async function getIpAddress() {
  let ipDetails = {};
  const osName = navigator.platform;
  const { userAgent } = navigator;
  const isMobile = /Mobile/.test(userAgent);
  const isTablet = /Tablet/.test(userAgent);
  const ipdata = await axios.get("https://ipinfo.io/?token=bef222d4bffb02");
  ipDetails["country_name"] = ipdata.data.country;
  ipDetails["region"] = ipdata.data.region;
  ipDetails["city"] = ipdata.data.city;
  ipDetails["ip"] = ipdata.data.ip;
  ipDetails["device"] = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop';
  ipDetails["os"] = osName;
  return ipDetails
}

//** launchpad USDT user balance fetch */
export async function getLaunchPadBalance() {
  // if(Config.LAUNCHPAD_STATUS === "Enable") {
  //   let token = "";
  //   token = getCookie("userToken");
  //   if (token) {
  //     const params = {
  //       method: "GET",
  //       url: `${Config.LAUNCHPAD_V2_API_URL}launchPad/currency/getWalletCurrency`
  //     };
  //     const data = await makeRequest(params);
  //     return data;
  //   }

  // }

}