import React, { useState, useEffect, useRef } from "react";

import { BrowserRouter as Router, Route, Routes, Link, useParams, useNavigate } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import { HiArrowNarrowRight } from "react-icons/hi";

import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import TransactionhistoryCrypto from "./separate/transaction-history-crypto";
import TransactionhistoryFiat from "./separate/transaction-history-fiat";
import TransactionhistoryTransfer from "./separate/transaction-history-transfer";
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import TransactionhistoryPi from "./separate/Transaction-history-Pi";
import AdminTransfer from "./separate/AdminTransfer";
import LaunchpadHistory from './separate/launchpadHistory'
import CompetitionTransactionHistory from "./separate/CompetitionTransactionHistory";

export default function Transactionhistory(props) {

  const navigate = useNavigate();
  let { tabName } = useParams();
  const cryptoTab = useRef(null);
  const [listTab, setListTab] = useState("crypto");
  const [inrCurrencyList, setinrCurrencyList] = useState({});

  useEffect(() => {
    if (tabName) {
      setListTab(tabName.toLowerCase());
    }
  }, [tabName]);

  useEffect(() => {
    getinrCurrency();
  }, []);

  async function getinrCurrency() {
    try {
      const value = { CurrencyID: "" }
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: 'POST',
        body: value
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setinrCurrencyList(response.data);
      }
    } catch (err) { }
  }

  async function backButton() {
    try {
      const length = window.history.length;
      if (length > 1) {
        window.history.back();
      } else {
        navigate("/");
      }
    } catch (err) { }
  }

  useEffect(() => {
    if (cryptoTab.current) {
      cryptoTab.current.click();
    };
  }, []);
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                {/* <GoChevronLeft className="deposit-back-button-icon" onClick={() => backButton()} /> */}
                <span className="deposit-text-1">Transactions History</span>
              </div>
              <div className="col-lg-6 text-end">
                {/* {Config.depositFiatStatus == "Enable" ?
                  <button type="button" className="deposit-fiat-button" onClick={() => navigate("/deposit/fiat/" + inrCurrencyList._id)}>
                    Deposit Fiat <HiArrowNarrowRight />
                  </button> : ""} */}
              </div>
            </div>
          </div>
        </div>

        <div className="transaction-history-second-section py-3 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="transaction-history-tabs-section">
                  <ul className="nav nav-pills " id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation" onClick={() => { setListTab("crypto"); }}>
                      <button
                        className={"nav-link " + (listTab === 'crypto' ? 'active' : '')}
                        id="pills-crypto-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-crypto"
                        type="button"
                        role="tab"
                        aria-controls="pills-crypto"
                        aria-selected="true"
                        ref={cryptoTab}
                      >
                        Crypto
                      </button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => { setListTab("fiat"); }}>
                      <button
                        className={"nav-link " + (listTab === 'fiat' ? 'active' : '')}
                        id="pills-fiat-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-fiat"
                        type="button"
                        role="tab"
                        aria-controls="pills-fiat"
                        aria-selected="false"
                      >
                        Fiat
                      </button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => { setListTab("Pi"); }}>
                      <button
                        className={"nav-link " + (listTab === 'pi' ? 'active' : '')}
                        id="pills-fiat-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-fiat"
                        type="button"
                        role="tab"
                        aria-controls="pills-fiat"
                        aria-selected="false"
                      >
                        Pi
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation" onClick={() => { setListTab("transfer"); }}>
                      <button
                        className={"nav-link " + (listTab === 'transfer' ? 'active' : '')}
                        id="pills-transfer-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-transfer"
                        type="button"
                        role="tab"
                        aria-controls="pills-transfer"
                        aria-selected="false"
                      >
                        Transfer
                      </button>
                    </li> */}
                    <li className="nav-item" role="presentation" onClick={() => { setListTab("admin-transfer"); }}>
                      <button
                        className={"nav-link " + (listTab === 'admin-transfer' ? 'active' : '')}
                        id="pills-transfer-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-transfer"
                        type="button"
                        role="tab"
                        aria-controls="pills-transfer"
                        aria-selected="false"
                      >
                        Admin Transfer
                      </button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => { setListTab("launchpad"); }}>
                      <button
                        className={"nav-link " + (listTab === 'launchpad' ? 'active' : '')}
                        id="pills-transfer-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-transfer"
                        type="button"
                        role="tab"
                        aria-controls="pills-transfer"
                        aria-selected="false"
                      >
                        Launchpad Token History
                      </button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => { setListTab("Competition"); }}>
                      <button
                        className={"nav-link " + (listTab === 'Competition' ? 'active' : '')}
                        id="pills-transfer-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-transfer"
                        type="button"
                        role="tab"
                        aria-controls="pills-transfer"
                        aria-selected="false"
                      >
                        Trading Competition Rewards
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          listTab && <>
            {
              listTab == "crypto" &&
              <TransactionhistoryCrypto tabName="tabName" />
            }

            {
              listTab == "fiat" &&
              <TransactionhistoryFiat tabName="tabName" />
            }
            {
              listTab == "Pi" &&
              <TransactionhistoryPi tabName="tabName" />
            }
            {/* {
              listTab == "transfer" &&
              <TransactionhistoryTransfer tabName="tabName" />
            } */}
            {
              listTab == "admin-transfer" &&
              <AdminTransfer tabName="tabName" /> 
            }
            {
              listTab == "launchpad" &&
              <LaunchpadHistory tabName="tabName" /> 
            }
            {
              listTab == "Competition" &&
              <CompetitionTransactionHistory tabName="tabName" /> 
            }
          </>
        }

      </div>
      <Footer />
    </div>
  );
}
