import moment from "moment";

export const dateAddFormat = (date, data = {}) => {
  try {
    const actualDate = new Date(date);
    const result = actualDate.setDate(actualDate.getDate() + data.addCount);
    return dateFormat(result)
  } catch (e) {
    return "Invalid Date"
  }
}

export const dateFormat = (date) => {
  try {
    const orgDate = new Date(date).toUTCString();
    // console.log('orgDate', orgDate)
    const hours = new Date(date).getUTCHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedDate = orgDate.split(",")[1].split("GMT")[0] + amOrPm
    return formattedDate;
  } catch (e) {
    return "Invalid Date Format"
  }
};

export const dateSelector = (paramsData) => {
  try {
    const currentDate = new Date(paramsData);
    const year = currentDate.getUTCFullYear();
    const month = currentDate.getUTCMonth() + 1;
    const date = currentDate.getUTCDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
    return formattedDate
  } catch (e) {
    return new Date
  }
};

export const shortenString = (str, maxLength) => {
  if (str?.length > maxLength) {
    var shortened = str.substring(0, maxLength - 3) + '...';
    var reversed = str.split('').reverse().join('');
    var shortenedReversed = reversed.substring(0, maxLength - 3);
    return shortened + shortenedReversed.split('').reverse().join('');
  } else {
    return str;
  }
}
export const yearmonthdateFormat = (date) => {
  try {
    let result = moment(date).format("YYYY-MM-DD");
    return result;
  } catch (e) { }
}

export const blogDateFormat = (date) => {
  try {
    var result = moment(date).format("MMM D Y");
    return result;
  } catch (e) { }
}

export const shortAdrress = (str) => {
  try {
    return str.length > 29 ? str.substring(0, 29) + "..." : str;
  } catch (e) { }
}

export const showEmail = (email) => {
  try {
    if (email) {
      const splitEmail = email.split('@');
      let middleEmail = "";
      if (splitEmail[0] && splitEmail[0].length > 7) {
        middleEmail = splitEmail[0].substr(splitEmail[0].length - 1);
      }
      return splitEmail[0].substring(0, 2) + '**' + middleEmail + '@**.' + splitEmail[1].split('.')[1];
    }
    else {
      return "-";
    }
  } catch (e) { }
}

export const showPhone = (phoneno) => {
  try {
    if (phoneno) {
      const first2Str = String(phoneno).slice(0, 5);
      const lastnum = String(phoneno).slice(9, 13);
      const value = first2Str + "****" + lastnum
      return value;
    }
    else {
      return "-";
    }
  } catch (e) { }
}

export const showPairName = (pairName = "") => {
  if (pairName) {
    return pairName.replace("_", "/");
  }
  else {
    return "";
  }
}

// export const showNumber = (x = 0) => {
//   var parts = x.toString().split(".");
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   return parts.join(".");
// }
export const showNumber = (value = 0) => {
  let formattedNumber = value.toLocaleString('en-US');
  return formattedNumber
};