import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavbarOne from "../../../siteTheme/NavbarOne";
import Footer from "../../../siteTheme/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import '../../assets/styles/ido-style.css';
import { toast } from '../../../../core/lib/toastAlert';
import Config from '../../../../core/config/index';
import moment from "moment/moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeRequest } from "../../../../core/services/v1/request";
import { useContextData } from "../../../../core/context/index";
import { getCookie } from "../../../../core/helper/cookie";
import $ from 'jquery';

function Home(props) {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { myProfile, launchPadForm } = useContextData();
    const [decimal, setDecimal] = useState();
    const [tokenData, setTokenData] = useState({
        token_name: launchPadForm != null ? launchPadForm.token_name : "",
        token_symbol: launchPadForm != null ? launchPadForm.token_symbol : "",
        token_supply: launchPadForm != null ? launchPadForm.token_supply : "",
        initial_supply: launchPadForm != null ? launchPadForm.initial_supply : "",
        token_price: launchPadForm != null ? launchPadForm.token_price : "",
        start_date: launchPadForm != null ? launchPadForm.start_date : new Date(),
        end_date: launchPadForm != null ? launchPadForm.end_date : new Date(),
        hard_cap_value: launchPadForm != null ? launchPadForm.hard_cap_value : "",
        soft_cap_value: launchPadForm != null ? launchPadForm.soft_cap_value : "",
        contact_address: launchPadForm != null ? launchPadForm.contact_address : "",
        token_contract_decimal: launchPadForm != null ? launchPadForm.token_contract_decimal : ""
    });
    const [loader, setLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);

    const launchPad = useFormik({
        initialValues: {
            token_name: "",
            token_symbol: "",
            token_supply: "",
            initial_supply: "",
            token_price: "",
            start_date: new Date(),
            end_date: new Date(),
            hard_cap_value: "",
            soft_cap_value: "",
            contact_address: "",
            token_contract_decimal: ""
        },
        //** form validation functions */
        validationSchema: Yup.object().shape({
            token_name: Yup.string().required("Token Name is required!"),
            token_symbol: Yup.string().required("Token symbol is required!"),
            token_supply: Yup.number().required("Token supply is required!"),
            initial_supply: Yup.number().test('is-lesser', 'Initial supply must be less that token supply', function (values) {
                const { token_supply } = this.parent;
                return values < token_supply
            }).required("Initial supply is required!"),
            token_price: Yup.number().test('is-lesserthan', 'Token price must be less that token supply', function (values) {
                const { initial_supply } = this.parent;
                return values < initial_supply
            }).required("Total price is required!"),
            hard_cap_value: Yup.string().test('isless', `it exceeds greaterthan the value of this token in USDT`, function (values) {
                const { initial_supply, token_price } = this.parent;
                const totalUsdt = initial_supply * token_price;
                return values <= totalUsdt
            }).required("Hard cap field is required!"),
            soft_cap_value: Yup.string().test('islesser', 'Soft cap value should be less than hard cap value', function (values) {
                const { hard_cap_value } = this.parent;
                return Number(values) < Number(hard_cap_value)
            }).required("Soft cap field is required!"),
            contact_address: Yup.string().required("Token contract address field is required!"),
            start_date: Yup.string().required("Start date is required"),
            end_date: Yup.string().required("End date is required"),
            token_contract_decimal: Yup.number().required("Token Contract Decimal is required")
        }),
        //** submit functionality */
        onSubmit: async (values) => {
            setLoader(true);
            setButtonDisable(true);
            let cookies = getCookie("userToken");
            if (cookies != null) {
                try {
                    const payload = {
                        projectId: Math.floor(Math.random() * 1000000000),
                        userName: state.data.userName,
                        email: state.data.email,
                        projectName: state.data.projectName,
                        projectInfo: state.data.projectInfo,
                        blockChainSelect: state.data.blockChainSelect,
                        tokenName: state.data.tokenName,
                        userTeam: state.data.userTeam,
                        userId: myProfile._id,
                        total_whitelisted_users: [myProfile._id],
                        investors: state.data.investors,
                        smartContractAudited: state.data.smartContractAudited,
                        paper_link: state.data.paper_link,
                        websiteLink: state.data.websiteLink,
                        gitLink: state.data.gitLink,
                        telegramGrpLink: state.data.telegramGrpLink,
                        telegramUserName: state.data.telegramUserName,
                        twitterLink: state.data.twitterLink,
                        image: state.data.image,
                        projectPic: state.data.projectPic,
                        token_name: values.token_name,
                        token_symbol: values.token_symbol,
                        token_supply: values.token_supply,
                        initial_supply: values.initial_supply,
                        available_token: values.initial_supply,
                        token_price: values.token_price,
                        start_date: moment(start_date).format('YYYY-MM-DDTHH:mm:ss'),
                        end_date: moment(end_date).format('YYYY-MM-DDTHH:mm:ss'),
                        hard_cap_value: values.hard_cap_value,
                        soft_cap_value: values.soft_cap_value,
                        contact_address: state.data.blockChainSelect == "TRON" ? values.contact_address : values.contact_address.toLowerCase(),
                        token_contract_decimal: values.token_contract_decimal,
                        token_listing_from: "",
                        token_listing_to: ""
                    };
                    
                    const params = {
                        method: 'POST',
                        url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/create`,
                        data: payload
                    }
                    const response = await makeRequest(params);
                    const { data, code, errorMessage, error, success } = response;
                    if (success == true) {
                        launchPad.resetForm();
                        toast({ type: "success", message: "FORM SUBMITTED SUCCESSFULLY WAIT FOR ADMIN APPROVAL" });
                        navigate("/ido-project")
                        setLoader(false);
                        setButtonDisable(false);
                    } else {
                        toast({ type: "error", message: errorMessage ? errorMessage : "Something went wrong" });
                    }
                } catch (error) {
                    toast({ type: "error", message: "Your form field is required!" });
                }
            } else {
                toast({ type: "error", message: "Please login to create project" });
            }
        }
    })

    //** submit functionality */
    const { start_date, end_date } = tokenData;
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (window.location.pathname === "/idoformtoken") {
            $("#classy-navbar-mobile").css("background-color", "transparent");
            $(".theme-mode-dropdown").hide();
        }
    }, []);
    useEffect(() => {
        if (
            launchPad.values.token_name !== "" &&
            launchPad.values.token_symbol !== "" &&
            launchPad.values.token_supply !== "" &&
            launchPad.values.initial_supply !== "" &&
            launchPad.values.initial_supply !== "" &&
            launchPad.values.token_price !== "" &&
            launchPad.values.start_date !== "" &&
            launchPad.values.end_date !== "" &&
            launchPad.values.hard_cap_value !== "" &&
            launchPad.values.soft_cap_value !== "" &&
            launchPad.values.contact_address !== "" &&
            launchPad.values.token_contract_decimal !== ""
        ) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [launchPad.values]);
    return (
        <div className="Ido-App-lanchpad top-alt-new-ido-section">
            <ToastContainer limit={1} />
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />

            <div className="hero-section-ido-launchpad-banner py-5 launchpad-active-nav-class">
                <div className="container">
                    <div className="row g-4 justify-content-around">
                        <div className="col-lg-8 application-form-ido-section">
                            <p className="ido-text-1 mb-5">BitNevex Application Form</p>
                            <form className="" onSubmit={(e) => {
                                e.preventDefault();
                                launchPad.handleSubmit();
                                return false;
                            }}>

                                <div className="mb-4">
                                    {/* <label className="form-label">Token Supply</label> */}
                                    <p className="ido-active-text-2 mb-1">Token Name <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="token_name"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a token name"
                                        name="token_name"
                                        value={launchPad.values.token_name}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        invalid={launchPad.touched.token_name && launchPad.errors.token_name ? true : false}
                                    />
                                    {
                                        launchPad.touched.token_name && launchPad.errors.token_name && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.token_name}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Token Supply</label> */}
                                    <p className="ido-active-text-2 mb-1">Token Symbol <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="token_symbol"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a token symbol"
                                        name="token_symbol"
                                        value={launchPad.values.token_symbol}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        invalid={launchPad.touched.token_symbol && launchPad.errors.token_symbol ? true : false}
                                    />
                                    {
                                        launchPad.touched.token_symbol && launchPad.errors.token_symbol && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.token_symbol}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Token Supply</label> */}
                                    <p className="ido-active-text-2 mb-1">Token Supply <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="token_supply"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a number"
                                        name="token_supply"
                                        value={launchPad.values.token_supply}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        onKeyPress={(event) => {
                                            const keyCode = event.which || event.keyCode;
                                            const keyValue = String.fromCharCode(keyCode);
                                            
                                            // Get the current value of the input field
                                            const inputValue = event.target.value;
                                            
                                            // Regular expression to allow only numbers and one decimal point
                                            const validKey = /^[0-9\b]+$/;
                                            const isDecimal = keyValue === '.';
                                            const containsDecimal = inputValue.indexOf('.') !== -1;
                                        
                                            // Allow only numeric values, the backspace key, and one decimal point
                                            if (!validKey.test(keyValue) && !(isDecimal && !containsDecimal)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        invalid={launchPad.touched.token_supply && launchPad.errors.token_supply ? true : false}
                                    />
                                    {
                                        launchPad.touched.token_supply && launchPad.errors.token_supply && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.token_supply}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Initial Supply</label> */}
                                    <p className="ido-active-text-2 mb-1">Initial Supply <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="initial_supply"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a number"
                                        name="initial_supply"
                                        value={launchPad.values.initial_supply}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        onKeyPress={(event) => {
                                            const keyCode = event.which || event.keyCode;
                                            const keyValue = String.fromCharCode(keyCode);
                                            
                                            // Get the current value of the input field
                                            const inputValue = event.target.value;
                                            
                                            // Regular expression to allow only numbers and one decimal point
                                            const validKey = /^[0-9\b]+$/;
                                            const isDecimal = keyValue === '.';
                                            const containsDecimal = inputValue.indexOf('.') !== -1;
                                        
                                            // Allow only numeric values, the backspace key, and one decimal point
                                            if (!validKey.test(keyValue) && !(isDecimal && !containsDecimal)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        invalid={launchPad.touched.initial_supply && launchPad.errors.initial_supply ? true : false}
                                    />
                                    {
                                        launchPad.touched.initial_supply && launchPad.errors.initial_supply && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.initial_supply}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Token Price</label> */}
                                    <p className="ido-active-text-2 mb-1">Token Price <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="token_price"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a number"
                                        name="token_price"
                                        value={launchPad.values.token_price}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        onKeyPress={(event) => {
                                            const keyCode = event.which || event.keyCode;
                                            const keyValue = String.fromCharCode(keyCode);
                                            
                                            // Get the current value of the input field
                                            const inputValue = event.target.value;
                                            
                                            // Regular expression to allow only numbers and one decimal point
                                            const validKey = /^[0-9\b]+$/;
                                            const isDecimal = keyValue === '.';
                                            const containsDecimal = inputValue.indexOf('.') !== -1;
                                        
                                            // Allow only numeric values, the backspace key, and one decimal point
                                            if (!validKey.test(keyValue) && !(isDecimal && !containsDecimal)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        invalid={launchPad.touched.token_price && launchPad.errors.token_price ? true : false}
                                    />
                                    {
                                        launchPad.touched.token_price && launchPad.errors.token_price && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.token_price}
                                            </small>
                                        )
                                    }
                                    <div className="form-text">1 {launchPad.values.token_symbol} = {launchPad.values.token_price ? launchPad.values.token_price : 0} USDT</div>
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Start Date</label> */}
                                    <p className="ido-active-text-2 mb-1">Start Date <span className="text-danger">*</span></p>

                                    <DatePicker
                                        selected={tokenData.start_date}
                                        className="form-control"
                                        selectsStart
                                        startDate={tokenData.start_date}
                                        endDate={tokenData.end_date}
                                        minDate={new Date()}
                                        // name="start_date"
                                        // id="start_date"
                                        onChange={(date) => setTokenData((prevDate) => ({ ...prevDate, start_date: date }))}
                                    />
                                    <div>
                                        <small>*Note: The project will start on date that is approved by admin in available date and time</small>
                                    </div>
                                    {
                                        launchPad.touched.start_date && launchPad.errors.start_date && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.start_date}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">End Date</label> */}
                                    <p className="ido-active-text-2 mb-1">End Date <span className="text-danger">*</span></p>

                                    <DatePicker
                                        selected={tokenData.end_date}
                                        className="form-control"
                                        selectsEnd
                                        startDate={tokenData.start_date}
                                        endDate={tokenData.end_date}
                                        minDate={tokenData.start_date}
                                        // name="end_date"
                                        // id="end_date"
                                        onChange={(date) => setTokenData((prevDate) => ({ ...prevDate, end_date: date }))}
                                    // onChange={this.handleChange}
                                    />
                                    <div>
                                        <small>*Note: The project will end on date that is approved by admin in available date and time</small>
                                    </div>
                                    {
                                        launchPad.touched.end_date && launchPad.errors.end_date && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.end_date}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Hard Cap Value</label> */}
                                    <p className="ido-active-text-2 mb-1">Hard Cap Value <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="hard_cap_value"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a number"
                                        name="hard_cap_value"
                                        value={launchPad.values.hard_cap_value}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        onKeyPress={(event) => {
                                            const keyCode = event.which || event.keyCode;
                                            const keyValue = String.fromCharCode(keyCode);
                                            
                                            // Get the current value of the input field
                                            const inputValue = event.target.value;
                                            
                                            // Regular expression to allow only numbers and one decimal point
                                            const validKey = /^[0-9\b]+$/;
                                            const isDecimal = keyValue === '.';
                                            const containsDecimal = inputValue.indexOf('.') !== -1;
                                        
                                            // Allow only numeric values, the backspace key, and one decimal point
                                            if (!validKey.test(keyValue) && !(isDecimal && !containsDecimal)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        invalid={launchPad.touched.hard_cap_value && launchPad.errors.hard_cap_value ? true : false}
                                    />
                                    {
                                        launchPad.touched.hard_cap_value && launchPad.errors.hard_cap_value && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.hard_cap_value}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Soft Cap Value</label> */}
                                    <p className="ido-active-text-2 mb-1">Soft Cap Value <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="hard_cap_value"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter a number"
                                        name="soft_cap_value"
                                        value={launchPad.values.soft_cap_value}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        onKeyPress={(event) => {
                                            const keyCode = event.which || event.keyCode;
                                            const keyValue = String.fromCharCode(keyCode);
                                            
                                            // Get the current value of the input field
                                            const inputValue = event.target.value;
                                            
                                            // Regular expression to allow only numbers and one decimal point
                                            const validKey = /^[0-9\b]+$/;
                                            const isDecimal = keyValue === '.';
                                            const containsDecimal = inputValue.indexOf('.') !== -1;
                                        
                                            // Allow only numeric values, the backspace key, and one decimal point
                                            if (!validKey.test(keyValue) && !(isDecimal && !containsDecimal)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        invalid={launchPad.touched.soft_cap_value && launchPad.errors.soft_cap_value ? true : false}
                                    />
                                    {
                                        launchPad.touched.soft_cap_value && launchPad.errors.soft_cap_value && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.soft_cap_value}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Contact Address</label> */}
                                    <p className="ido-active-text-2 mb-1">Token Contract Address <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="contact_address"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter the token contract address"
                                        name="contact_address"
                                        value={launchPad.values.contact_address}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        invalid={launchPad.touched.contact_address && launchPad.errors.contact_address ? true : false}
                                    />
                                    <div>
                                        <small>*Note: Enter only verified contracts of select blockchain in previous form</small>
                                    </div>
                                    {
                                        launchPad.touched.contact_address && launchPad.errors.contact_address && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.contact_address}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    {/* <label className="form-label">Contact Address</label> */}
                                    <p className="ido-active-text-2 mb-1">Token Contract Decimal <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="token_contract_decimal"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        placeholder="Please enter the token decimal"
                                        name="token_contract_decimal"
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        onKeyPress={(event) => {
                                            const keyCode = event.which || event.keyCode;
                                            const keyValue = String.fromCharCode(keyCode);
                                            // Allow only numeric values (0-9) and the backspace key (8)
                                            if (!/^[0-9\b]+$/.test(keyValue)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        value={launchPad.values.token_contract_decimal}
                                        onChange={launchPad.handleChange}
                                        onBlur={launchPad.handleBlur}
                                        invalid={launchPad.touched.token_contract_decimal && launchPad.errors.token_contract_decimal ? true : false}
                                    />
                                    {
                                        launchPad.touched.token_contract_decimal && launchPad.errors.token_contract_decimal && (
                                            <small className="invalid-email error password-text-33">
                                                {launchPad.errors.token_contract_decimal}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="text-center">
                                    <button type="submit" disabled={buttonDisable} className="btn ido-launchpad-button">
                                        Submit
                                        {
                                            loader == true ?
                                                <div class="spinner-border spinner-border-sm ms-2" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                :
                                                ''
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
