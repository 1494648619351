import React, { useEffect, useRef, useState } from "react";
import "../../assets/style.css";
import NavbarOne from "../../Pages/siteTheme/NavbarOne";
import Footer from "../../Pages/siteTheme/Footer";
import { BsArrowRight } from "react-icons/bs";
import { HiSpeakerphone } from "react-icons/hi";
import { AiFillEye, AiOutlineSearch, AiFillInfoCircle, AiFillEyeInvisible, } from "react-icons/ai";
import { BiCaretDown } from "react-icons/bi";
import { BsXCircleFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import Config from "../../core/config/index";
import { useContextData } from "../../core/context/index";
import axios from "axios";
import { toast } from "../../core/lib/toastAlert";
import { makeRequest } from "../../core/services/v1/request";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { useNavigate } from "react-router-dom";
import notFound from "../../assets/images/file.png"
import Icon1 from '../../assets/Nexchange-images/simple-earn-icon/icon1.png';
import Icon2 from '../../assets/Nexchange-images/simple-earn-icon/icon2.png';
import Icon3 from '../../assets/Nexchange-images/simple-earn-icon/icon3.png';
import Icon4 from '../../assets/Nexchange-images/simple-earn-icon/icon4.png';
import { dateFormat } from "../../core/helper/date-format";
import ReactTooltip from "react-tooltip";


export default function Simpleearn(props) {
  const navigate = useNavigate();
  const { myProfile } = useContextData();
  const [earnType, setEarnType] = useState("Fixed");
  const [stakeCurrencies, setStakeCurrencies] = useState([]);
  const [subscribeModal, setSubscribeModal] = useState({});
  const [rewardsapr, setrewardsapr] = useState([]);
  const [userbalance, setuserbalance] = useState(0);
  const [investAmount, setInvestAmount] = useState("");
  const [termsAndCond, setTermsAndCond] = useState("FALSE");
  const [expDate, setExpDate] = useState("");
  const [estimatedValue, setEstimatedValue] = useState({});
  const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
  const [message, setMessage] = useState('');
  const [aprPercentage, setAprPercentage] = useState(0);
  const [selectedAPR, setSelectedAPR] = useState({});
  const [stakeId, setStackId] = useState(null);
  const [aprIdx, setAprIdx] = useState(0);
  const [innerSubModelIdx, setInnerSubModelIdx] = useState(0);
  const subModelCloseRef = useRef();
  const currentDate = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const [simpleEarnWallet, setWalletBalance] = useState({});
console.log("simpleEarnWallet--",simpleEarnWallet);


  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCurrencies, setTotalCurrencies] = useState(0);
  const [activeTab, setActiveTab] = useState("subscribe");
  const [userId, setUserId] = useState("");


  useEffect(() => {
    if (Config.SIMPLEEARN_STATUS == "Enable") {
      if (myProfile && myProfile._id) {
        setUserId(myProfile._id);
      }
    }
  }, [myProfile]);


  useEffect(() => {
    getWalletCurrency();
  }, [myProfile, earnType]);

  // Function to switch to Product Rules tab
  const switchToProductRules = () => {
    setActiveTab("productRules");
  };


  const resetForm = () => {
    setInvestAmount("");
    setAprIdx(0);
    setTermsAndCond("FALSE");
  };

  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };

  // console.log("simpleEarnWallet", simpleEarnWallet);


  useEffect(() => {
    const getWalletBalance = async () => {
      try {
        const params = {
          url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/simpleEarnWallet`,
          method: "POST",
        };
        const response = await makeRequest(params);        
        if (response.status) {
          setWalletBalance(response.simpleEarnWallet);
        } else {
          setWalletBalance({});
        }
      } catch (error) {
        console.log("getWalletBalance", error);
      };
    };
    if (Object.keys(myProfile).length > 0) {
      getWalletBalance();
    }
  }, [myProfile]);

  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    // console.log("response==>", response)
    if (response) {
      const {
        estimateINR = 0,
        estimateUSD = 0,
        estimateSimpleEarnUSD = 0,
      } = response;
      setEstimatedValue({
        estimateINR,
        estimateUSD,
        estimateSimpleEarnUSD,
      });
    }
  }

  const fixedEarnColumns = [
    {
      id: 0,
      name: "symbol",
      selector: (row) => <img className="simpleearn-table-coin-img" src={row.currencyimage} />,
    },
    {
      id: 1,
      name: "Coin",
      selector: (row) => row.currencysymbol,
    },
    {
      id: 2,
      name: "APR",
      selector: (row) => `${selectedAPR[row._id]?.apr || ""}%`,
    },
    {
      id: 3,
      name: "Duration(Days)",
      width: "300px",
      selector: (row) => {
        const rewardsApr = row.rewardsapr;
        return (
          <ul
            class="simpleearn-table-tabs-duration nav nav-pills gap-2"
            id="pills-tab"
            role="tablist"
          >
            {rewardsApr.map((ids, index) => (
              ids.idx = index,
              <li className="nav-item" role="presentation" key={index} onClick={() => setAprIdx(index)}>
                <button
                  className={index === 0 ? "nav-link active" : "nav-link"}
                  role="tab" type="button"
                  data-bs-toggle="pill"
                  id={`pills-duration${index + 1}-tab`}
                  data-bs-target={`#pills-duration${index + 1}`}
                  aria-controls={`pills-duration${index + 1}`}
                  aria-selected="false"
                  onClick={() => {
                    setSelectedAPR((prevData) => ({ ...prevData, [row._id]: ids }));
                    handleChangeAPR(ids.apr, ids.days)
                  }}
                >
                  {ids.days}
                </button>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      id: 4,
      name: "Action",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn-simpleearn-filter-table"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => subscribeearn(row)}
          >
            Subscribe
          </button>
        </div>
      ),
    },
  ];
  const flexibleEarnColumn = [
    {
      id: 0,
      name: "symbol",
      selector: (row) => <img className="simpleearn-table-coin-img" src={row.currencyimage} />,
    },
    {
      id: 1,
      name: "Coin",
      selector: (row) => row.currencysymbol,
    },
    {
      id: 2,
      name: "Earn Type",
      selector: (row) => "Flexible",
    },
    {
      id: 3,
      name: "APR Percentage",
      selector: (row) => `${row.aprPercentage || ""}%`,
    },
    {
      id: 4,
      name: "Action",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn-simpleearn-filter-table"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => subscribeearn(row)}
          >
            Subscribe
          </button>
        </div>
      ),
    },
  ];

  const subscribeearn = async (subsc) => {
    setStackId(subsc._id);
    setSubscribeModal(subsc);
    if (subsc.type === "Fixed") {
      const currentDate = new Date()
      const expireDate = currentDate.setDate(currentDate.getDate() + selectedAPR[subsc._id].days)
      setAprPercentage(selectedAPR[subsc._id].apr);
      setExpDate(expireDate);
      setrewardsapr(subsc.rewardsapr);
      setInnerSubModelIdx(selectedAPR[subsc._id].idx)
    } else {
      setAprPercentage(subsc.aprPercentage);
    }
    const userdetails = {
      currencyId: subsc.currencyId,
    };
    const getuserbal = await axios.post(
      `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getuserbal`,
      userdetails
    );
    const usramount = parseFloat((getuserbal.data.balance?.simpleEarnAmount)?.toFixed(8));
    setuserbalance(usramount);
  };

  const handleChangeAPR = async (apr, days) => {
    const cur = new Date
    const expireDate = cur.setDate(cur.getDate() + days)
    setAprPercentage(apr);
    setExpDate(expireDate);
  };

  const clickinputbox = (event) => {
    setTermsAndCond(event.target.checked ? "TRUE" : "FALSE");
  };

  useEffect(() => {
    const getCurrencyList = async () => {
      try {
        const params = {
          url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/stakingCurrencies?earnType=${earnType}&searchQuery=${message}&limit=${rowsPerPage}&page=${currentPage}`,
          method: "GET"
        };
        const response = await makeRequest(params);
        if (response.status) {
          setStakeCurrencies(response.data);
          setTotalCurrencies(response.totalCurrencies);
          const tableData = response.data;
          if (Array.isArray(tableData)) {
            tableData.forEach((row) => {
              if (row.rewardsapr && row.rewardsapr.length > 0) {
                setSelectedAPR((prev) => ({
                  ...prev,
                  [row._id]: row.rewardsapr[0]
                }));
              };
            });
          };
        };
      } catch (error) {
        console.log("Can't Get Simpleearn Currencies list", error);
      };
    };
    getCurrencyList();
  }, [message, earnType, rowsPerPage, currentPage]);

  const handleClick = () => {
    setMessage('');
    // window.location.reload();
  };

  const confirmstake = async () => {
    if (investAmount == "") {
      let type = "error";
      return toast({ type, message: "First Enter Your Amount " });
    } else if (investAmount > userbalance) {
      let type = "error";
      return toast({ type, message: "Insufficient balance. Please enter an amount within your balance." });
    } else if (termsAndCond == "FALSE") {
      let type = "error";
      return toast({ type, message: "Please Accept the Terms and Conditions to proceed" });
    } else if (investAmount < subscribeModal.minAmount) {
      let type = "error";
      return toast({ type, message: `Minimum amount should be ${subscribeModal.minAmount}` });
    } else if (investAmount > subscribeModal.maxAmount) {
      let type = "error";
      return toast({ type, message: `Maximum amount should be ${subscribeModal.maxAmount}` });
    } else {
      let userdetails = {
        investAmount: investAmount,
        stakeId: stakeId,
        aprIdx: aprIdx
      };
      if (userdetails) {
        const response = await axios.post(
          `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/stackSimpleEarn`,
          userdetails
        );
        // console.log("response", response)
        if (response.data.status) {
          let type = "success";
          toast({ type, message: response.data.message });
          setInvestAmount("");
          setAprIdx(0);
          setTermsAndCond("FALSE");
          if (subModelCloseRef && subModelCloseRef.current) {
            subModelCloseRef.current.click();
            resetForm();
          };
          // navigate("/account-earn");
        } else {
          let type = "error";
          toast({ type, message: response.data.message });
          setInvestAmount("");
          setAprIdx(0);
          setTermsAndCond("FALSE");
        }
      }
    }
  };

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="simpleearn-top-banner-section finance-active-nav-class">
        <div className="simpleearn-top-value-section">
          <div className="container">
            <div className="row align-items-center g-4">
              <div className="col-lg-8">
                <div>
                  <p className="simpleearn-text-1">Simple Earn</p>
                  <p className="simpleearn-text-2 mb-4">
                    The easiest method to deposit cryptocurrencies and enjoy consistent earnings
                  </p>
                  <button class="accountearn-all-assest border-0" onClick={() => navigate("/account-earn")}>History</button>
                  <button class="accountearn-all-assest border-0 ms-2" data-bs-toggle="modal" data-bs-target="#whatissimpleearn" >What is Simple Earn?</button>
                </div>
              </div>
              <div className="col-lg-4">
                <div class="card">
                  <div className="d-flex flex-row gap-2 mb-4 align-items-center">
                    <h5 className="mb-0">My Holdings</h5>
                    {balShow ? (
                      <AiFillEye className="cursor-pointer" onClick={() => balShowHideCall()} />
                    ) : (
                      <AiFillEyeInvisible className="cursor-pointer" onClick={() => balShowHideCall()} />
                    )}
                  </div>
                  <div>

                    <div >
                      <div>
                        <p className="simpleearn-text-5 mb-1 fw-light">Total Balance</p>
                        {balShow ? (
                          <b className="border-dot password-text-44">
                            {simpleEarnWallet != undefined && simpleEarnWallet?.earnAmountInUSD > 0
                              ? simpleEarnWallet?.earnAmountInUSD.toFixed(6)
                              : 0}{" "}
                            $
                          </b>
                        ) : (
                          <b className="password-text-44">******</b>
                        )}
                      </div>
                      <hr className="mt-1 mb-3" />
                      <div>
                        <p className="simpleearn-text-5 mb-1 fw-light">Cumulative Profit</p>
                        {balShow ? (
                          <b
                            className="password-text-44"
                            style={{
                              color:
                                simpleEarnWallet?.walletProfit?.totalInUSD > 0 ? "green" : "inherit",
                            }}>
                            ≈ $
                            {simpleEarnWallet != undefined &&
                              simpleEarnWallet?.walletProfit &&
                              simpleEarnWallet?.walletProfit.totalInUSD
                              && simpleEarnWallet?.walletProfit.totalInUSD > 0 ?
                              parseFloat(simpleEarnWallet?.walletProfit.totalInUSD).toFixed(6) : '0.00'
                            }
                          </b>
                        ) : (
                          <b className="password-text-44">******</b>
                        )}
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 pb-lg-0 py-4 simpleearn-top-filter-section">
          <div className="row g-2 justify-content-end">
            <div className="col-lg-4 col-12">
              <div class="input-group simpleearn-top-filter-srch">
                <span
                  class="input-group-text input-group-text-1 border-0"
                  id="basic-addon1"
                >
                  <AiOutlineSearch />
                </span>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />

                <span
                  class="input-group-text input-group-text-2 border-0"
                  id="basic-addon1"
                  onClick={handleClick}
                >
                  <BsXCircleFill style={{ cursor: "pointer" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-4">
          <div className="d-flex flex-row gap-2 align-items-center">
            <div>
              <button
                className={`fixedOrFlex ${earnType === "Fixed" && "fixedOrFlex-active"}`}
                onClick={() => {
                  setEarnType("Fixed");
                }}>Fixed</button>&nbsp;&nbsp;&nbsp;
              <button
                className={`fixedOrFlex ${earnType === "Flexible" && "fixedOrFlex-active"}`}
                onClick={() => {
                  setEarnType("Flexible");
                }}>Flexible</button>
            </div>
            <div className="">
              <AiFillInfoCircle data-tip data-for="noOvertoolspoti" style={{ color: "var(--btnClr)" }} />
              <ReactTooltip
                id="noOvertoolspoti"
                place="right"
                className="tooltip-text-Theme"
              >
                <span className="tooltip-text-1">
                  <strong>Fixed</strong> locks assets for a set period with higher interest. <strong>Flexible</strong> allows anytime withdrawal but offers lower Interest.<br/> Choose <strong>Fixed</strong> for higher returns and <strong>Flexible</strong> for quick access.
                </span>
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <p className="simpleearn-text-7 mb-0">Protected Products</p>
          <p className="simpleearn-text-8 mb-4">
            Get stable earnings by depositing assets
          </p>
          <div className="simpleearn-staking-subscribe-table">
            <DataTable
              columns={earnType === "Fixed" ? fixedEarnColumns : flexibleEarnColumn}
              data={stakeCurrencies}
              pagination
              paginationServer
              persistTableHead
              paginationPerPage={rowsPerPage}
              paginationTotalRows={totalCurrencies}
              paginationRowsPerPageOptions={[5, 10, 15]}
              onChangeRowsPerPage={newRowsPerPage => {
                setRowsPerPage(newRowsPerPage);
                setCurrentPage(1); // Reset to first page when rows per page changes
              }}
              onChangePage={page => setCurrentPage(page)}
              noDataComponent={
                <div className="text-center py-2">
                  <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                  <br />
                  <span>No Records Found</span>
                </div>
              }

            />
          </div>
        </div>

        <section>
          <div className="container p2p-bottom-section-container">
            <div className="row">
              <div className="col">
                <p className="p2p-bottom-section-heading">Learn More</p>
              </div>
            </div>
            <div className="row p2p-bottom-buy-sell-tabs">
              <div className="col">
                <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1">
                  <div className="col">
                    <div className="row p2p-bottom-buy-sell-tabs-content-image">
                      <img src={Icon1} alt="Earn" />
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title">
                      <p>Flexible Product Accessibility</p>
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title-paragrah">
                      <p>Users can redeem Flexible Products at any time they choose.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row p2p-bottom-buy-sell-tabs-content-image">
                      <img src={Icon2} alt="Earn" />
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title">
                      <p>Higher Returns with Locked Products</p>
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title-paragrah">
                      <p>Locked Products come with specific durations and generally provide higher APRs than Flexible options.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row p2p-bottom-buy-sell-tabs-content-image">
                      <img src={Icon3} alt="Earn" />
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title">
                      <p>One-Click Reward Compounding</p>
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title-paragrah">
                      <p>Automatically subscribe daily to Flexible Products or Locked Products upon maturity to effortlessly compound your rewards.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row p2p-bottom-buy-sell-tabs-content-image">
                      <img src={Icon4} alt="Earn" />
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title">
                      <p>Earn Various Rewards</p>
                    </div>
                    <div className="row p2p-bottom-buy-sell-tabs-content-title-paragrah">
                      <p>Eligible users subscribing to Simple Earn products will automatically join Launchpool and Megadrop for bonus rewards.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          class="modal fade simp-earn-subs-modal"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
         data-bs-backdrop="static" data-bs-keyboard="false" 
        >
          <div class="modal-dialog modal-dialog-centered modal-lg" >
            <div class="modal-content">
              <div class="modal-header">

                <ul className="nav nav-pills gap-4" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "subscribe" ? "active" : ""}`}
                      onClick={() => setActiveTab("subscribe")}
                      type="button"
                      role="tab"
                      aria-selected={activeTab === "subscribe"}
                    >
                      <h1 className="modal-title" id="exampleModalLabel">
                        Subscribe{" "}
                        <img
                          className="simpleearn-subscribe-img"
                          src={subscribeModal.currencyimage}
                        />
                        <span className="ms-1 fs-14">
                          {subscribeModal.currencysymbol}
                        </span>
                      </h1>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "productRules" ? "active" : ""}`}
                      onClick={() => setActiveTab("productRules")}
                      type="button"
                      role="tab"
                      aria-selected={activeTab === "productRules"}
                    >
                      <h1 className="modal-title" id="exampleModalLabel">
                        Product Rules
                      </h1>
                    </button>
                  </li>
                </ul>

                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={subModelCloseRef}
                  onClick={resetForm}
                ></button>
              </div>
              <div class="modal-body">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${activeTab === "subscribe" ? "show active" : ""}`}
                    id="pills-simp-subs-1"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        {earnType === "Fixed" && (<>
                          <p className="mb-1 simpleearn-text-11">Duration (Days)</p>
                          <ul
                            class="simpleearn-table-tabs-duration nav nav-pills gap-2 fs-12"
                            id="pills-tab"
                            role="tablist"
                          >
                            {
                              rewardsapr.map((aprs, index) => (
                                <li key={aprs._id} class="nav-item" role="presentation" onClick={() => setAprIdx(index)}>
                                  <button
                                    className={index == innerSubModelIdx ? "nav-link active" : "nav-link"}
                                    data-bs-toggle="pill"
                                    role="tab" type="button"
                                    id={`pills-duration${index + 1}-tab`}
                                    data-bs-target={`#pills-duration${index + 1}`}
                                    aria-controls={`pills-duration${index + 1}`}
                                    aria-selected="false"
                                    onClick={() =>
                                      handleChangeAPR(aprs.apr, aprs.days)
                                    }
                                  >
                                    {aprs.days}
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </>)}
                        <p className="mb-1 simpleearn-text-11 mt-3">
                          Subscription Amount
                        </p>
                        <div class="simpleearn-subscription-amount input-group mb-0">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Amount"
                            aria-label="Enter Amount"
                            aria-describedby="basic-addon2"
                            // onChange={(e) => setInvestAmount(e.target.value)}
                            value={investAmount}
                            onChange={(e) => {
                              let targetValue = e.target.value;
                              if (targetValue === ".") {
                                targetValue = "0.";
                              }
                              if (/^\d*\.?\d*$/.test(targetValue)) {
                                setInvestAmount(targetValue);
                              }
                            }}
                          />
                          <span
                            class="input-group-text subscription-amount-group-1"
                            id="basic-addon2"
                          >
                            <button
                              className="simpleearn-max-button"
                              onClick={() => setInvestAmount(userbalance)}
                            >
                              Max
                            </button>
                          </span>
                          <span
                            class="input-group-text subscription-amount-group-2"
                            id="basic-addon2"
                          >
                            <img
                              className="simpleearn-subscribe-img"
                              src={subscribeModal.currencyimage}
                            />
                            <span className="ms-1 fs-14">
                              {subscribeModal.currencysymbol}
                            </span>
                          </span>
                        </div>
                        <div className="d-flex flex-row">
                          <div>
                            <p className="fs-12 mb-0">
                              {userbalance.toFixed(simpleEarnWallet.siteDecimal)} {subscribeModal.currencysymbol}{" "}
                              <span className="text-muted">Available</span>
                            </p>
                          </div>
                          <div className="ms-auto">
                            <p className="fs-12 mb-0">
                              <a
                                className="simpleearn-text-12 cursor-poniter"
                                onClick={() => {
                                  if (subModelCloseRef && subModelCloseRef.current) {
                                    subModelCloseRef.current.click();
                                    resetForm();
                                  };
                                  // navigate(`/deposit/crypto/${subscribeModal.currencysymbol}`)
                                  navigate("/my/wallet")
                                }}
                              >
                                Buy Now
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center gap-1 simpleearn-enough-crypto text-white py-2">
                          <div>
                            <AiFillInfoCircle className="simpleearn-text-13" />
                          </div>
                          <div>
                            <span className="fs-12">Don’t have enough crypto?</span>
                          </div>
                          <div className="ms-auto fs-12">
                            <a
                              className="simpleearn-text-14 cursor-poniter"
                              onClick={() => {
                                if (subModelCloseRef && subModelCloseRef.current) {
                                  subModelCloseRef.current.click();
                                  resetForm();
                                };
                                navigate(`/my/dashboard`)
                              }}
                            >
                              {" "}
                              convert <BsArrowRight />
                            </a>
                          </div>
                        </div>
                        <p className="mb-0 mt-3 fs-12">Amount Limits</p>
                        <p className="mb-0 simpleearn-text-15">
                          Minimum:{" "}
                          <span className="text-muted">
                            {subscribeModal.minAmount} {subscribeModal.currencysymbol}
                          </span>
                        </p>
                        <p className="mb-0 simpleearn-text-15">
                          Maximum:{" "}
                          <span className="text-muted">
                            {subscribeModal.maxAmount} {subscribeModal.currencysymbol}
                          </span>
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div class="container-timeline">

                          <div class="timeline-block timeline-block-right">
                            <div class="marker active">
                              <i class="fa fa-check active" aria-hidden="true"></i>
                            </div>
                            <div class="timeline-content d-flex align-items-center">
                              <h3 className="simpleearn-text-17">
                                Subscription UTC Date
                                {earnType === "Flexible" && (
                                  <a className="fs-12 ms-1 curPointer" style={{ textDecoration: "underline" }} onClick={switchToProductRules}>
                                    View rules
                                  </a>
                                )}
                              </h3>
                              <span className="ms-auto simpleearn-text-16">
                                {dateFormat(currentDate)}
                                {/* {`${currentDate.toLocaleDateString('en-US', options)}  ${currentDate.toLocaleTimeString()}`} */}
                              </span>
                            </div>
                          </div>

                          {earnType === "Fixed" && (<>
                            <div class="timeline-block timeline-block-right">
                              <div class="marker active">
                                <i class="fa fa-check active" aria-hidden="true"></i>
                              </div>
                              <div class="timeline-content d-flex align-items-center">
                                <h3 className="simpleearn-text-17">
                                  Interest End Date
                                  <a className="fs-12 ms-1 curPointer" style={{ textDecoration: "underline" }} onClick={switchToProductRules}>
                                    View rules
                                  </a>
                                </h3>
                                <span className="ms-auto simpleearn-text-16">
                                  {dateFormat(expDate)}
                                  {/* {`${new Date(expDate).toLocaleDateString('en-US', options)}  ${new Date(expDate).toLocaleTimeString().toString("h:mm:ss a")}`} */}
                                </span>
                              </div>
                            </div>

                            <div class="timeline-block timeline-block-left">
                              <div class="marker active">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div class="timeline-content d-flex align-items-center">
                                <h3 className="simpleearn-text-17">
                                  Next Subscription Date
                                </h3>
                                <span className="ms-auto simpleearn-text-16">
                                  {dateFormat(expDate)}
                                  {/* {`${new Date(expDate).toLocaleDateString('en-US', options)}  ${new Date(expDate).toLocaleTimeString()}`} */}
                                </span>
                              </div>
                            </div>
                          </>)}
                        </div>

                        <div className="d-flex flex-row mt-3">
                          <div>
                            <p className="mb-0 simpleearn-text-15">Est. APR </p>
                          </div>
                          <div className="ms-auto fc-g">
                            {aprPercentage}%
                          </div>
                        </div>


                        <div className="simpleearn-auto-Subscribe mt-1 py-1">
                          <ul className="simpleearn-text-16 mb-0">
                            <li>
                              The APR is subject to change on a daily basis. Please refer to the details on the product rules page.
                            </li>
                            <li>
                              APR does not mean the actual or predicted returns in fiat currency.
                            </li>
                            <li>
                              Upon expiry, your digital assets will be automatically subscribed to {earnType} Products. If you choose to redeem early, your digital assets will be returned to the simple earn wallet.
                            </li>
                          </ul>
                        </div>

                        <div class="form-check mt-2">
                          <input
                            class="form-check-input curPointer"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={(e) => clickinputbox(e)}
                            checked={termsAndCond === "TRUE"}
                          />
                          <label
                            class="form-check-label fs-12"
                            for="flexCheckDefault"
                          >
                            I have read and agreed to{" "}
                            <a
                              href="/terms"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "var(--btnClr)" }}>
                              BitNevex Terms and Conditions
                            </a>
                          </label>
                        </div>

                        <div class="d-grid mt-3">
                          {userId ? (
                            <button
                              class="simpleearn-subscribe-button"
                              type="button"
                              onClick={() => confirmstake()}
                            >
                              Confirm
                            </button>
                          ) : (
                            <button
                              class="simpleearn-subscribe-button"
                              type="button"
                              onClick={() => {
                                navigate("/login")
                              }}
                            >
                              Login
                            </button>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${activeTab === "productRules" ? "show active" : ""}`}
                    id="pills-simp-subs-2"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row g-4 productRules-tabs-sec">
                      <ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="pills-Flexible-simp-tab" data-bs-toggle="pill" data-bs-target="#pills-Flexible-simp" type="button" role="tab" aria-controls="pills-Flexible-simp" aria-selected="true">Flexible</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-Locked-simp-tab" data-bs-toggle="pill" data-bs-target="#pills-Locked-simp" type="button" role="tab" aria-controls="pills-Locked-simp" aria-selected="false">Fixed</button>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-Flexible-simp" role="tabpanel" aria-labelledby="pills-Flexible-simp-tab" tabindex="0">
                          <div>
                            <h5 className="mb-3">Flexible Duration</h5>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                1.
                              </div>
                              <div>
                                <p className="mb-1">Instant APR Benefits</p>
                                <p className="simpleearn-text-16 mb-0">
                                  The total amount you deposit in each Flexible Product determines the Real-Time APR. After subscribing, rewards from Real-Time APR begin accumulating directly in your Simple Earn Wallet from the following Days.
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                2.
                              </div>
                              <div>
                                <p className="mb-1">Incentive-Based Tiered APR Rewards</p>
                                <p className="simpleearn-text-16 mb-0">Subscription Date </p>
                                <p className="simpleearn-text-16 mb-2">Interest End Date </p>

                                <p className="simpleearn-text-16 mb-0">
                                  The total amount you deposit in each Flexible Product determines the Real-Time APR. After subscribing, rewards from Real-Time APR begin accumulating directly in your Simple Earn Wallet from the following Days.
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                3.
                              </div>
                              <div>
                                <p className="mb-1">Asset Redemption Process</p>
                                <p className="simpleearn-text-16 mb-2">
                                  Upon successful processing of your redemption requests, the assets from your Flexible Product will be immediately returned to your Spot Wallet.
                                </p>
                                <p className="simpleearn-text-16 mb-2">Please be aware that there are daily redemption limits for each Flexible Product.</p>
                                <p className="simpleearn-text-16 mb-2">Your deposits in Simple Earn may also be lent to other Bitnevex users through Margin and Crypto Loan products. A surge in redemption requests may cause temporary delays in processing your redemption. </p>

                                <p className="simpleearn-text-16 mb-0">Redemptions will resume once liquidity is restored, which may occur due to actions such as closing borrower positions.</p>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-Locked-simp" role="tabpanel" aria-labelledby="pills-Locked-simp-tab" tabindex="0">
                          <div>
                            <h5 className="mb-3">Fixed Duration(locked)</h5>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                1.
                              </div>
                              <div>
                                <p className="mb-1">Fixed Rate Rewards</p>
                                <p className="simpleearn-text-16 mb-2">
                                  The APR applied to your subscriptions is a fixed rate that ensures consistent rewards. Accumulation of rewards begins the day after you subscribe, starting at 00:00 UTC. You will see the reward distribution in your Spot Wallet two days after subscription.
                                </p>
                                <p className="simpleearn-text-16 mb-0">Daily Reward Formula: Deposited Amount * APR/365.25 (Rounded down to 8 decimal points).</p>
                              </div>
                            </div>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                2.
                              </div>
                              <div>
                                <p className="mb-1">Subscription and Redemption Window</p>
                                <p className="simpleearn-text-16 mb-0">You can subscribe or redeem your assets anytime daily.</p>

                              </div>
                            </div>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                3.
                              </div>
                              <div>
                                <p className="mb-1">Flexible Early Redemption</p>
                                <p className="simpleearn-text-16 mb-2">
                                  Some products offer the option to redeem early before the term ends. If you choose to redeem early, all previously earned rewards will be forfeited. Your principal amount will be returned to your Simple Earn Wallet within 24 hours, excluding the rewards accrued. In cases of high market volatility or unexpected network congestion, processing times for redemptions might be extended.
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-row gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                              <div>
                                4.
                              </div>
                              <div>
                                <p className="mb-1">Cancellation Policy</p>
                                <p className="simpleearn-text-16 mb-2">
                                  If you decide to cancel your subscription, you can do so within the same day and will need to follow the early redemption procedure to access your funds.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="whatissimpleearn" tabindex="-1" aria-labelledby="whatissimpleearnLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg simple-earn-ex-modal">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">What is Simple Earn?</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className="row g-4">
                  <div className="col-lg-6">
                    <p style={{ fontSize: "14px" }}>Bitnevex Simple Earn allows you to escalate your investment portfolio by earning rewards on digital assets. Deposit your assets in flexible or locked timeframes to begin earning immediately. You can subscribe to Simple Earn Products whenever you want, with Locked Products available in different terms to accommodate various use cases, enabling you to earn rewards every day.</p>
                  </div>
                  <div className="col-lg-6">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-simp2-tab" data-bs-toggle="pill" data-bs-target="#pills-simp2" type="button" role="tab" aria-controls="pills-simp2" aria-selected="false">Risk</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-simp3-tab" data-bs-toggle="pill" data-bs-target="#pills-simp3" type="button" role="tab" aria-controls="pills-simp3" aria-selected="false">Source of Yield</button>
                      </li>
                    </ul>
                    <div class="tab-content mt-4" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-simp2" role="tabpanel" aria-labelledby="pills-simp2-tab" tabindex="0">
                        <div>
                          <p className="mb-3">Minimal Risk Exposure</p>
                          <p className="fs-12 opacity-75">Your assets are kept safe and secure, even though digital asset prices can fluctuate significantly. A sudden surge in redemption requests for a particular token may lead to a temporary shortfall in available redemption balances, which could delay your redemption.</p>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="pills-simp3" role="tabpanel" aria-labelledby="pills-simp3-tab" tabindex="0">
                        <div>
                          <p className="mb-3">Yield Generation Sources</p>
                          <p className="fs-12 opacity-75">Promotional yields may be offered by Bitnevex through various marketing initiatives. Deposits in Simple Earn Products might be used for staking on Proof-of-Stake (PoS) networks or loaned to other users of Bitnevex, such as for Margin and Loan products.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}