import React, { useEffect, useRef } from 'react';
let tvScriptLoadingPromise;
export default function Chart(props) {
  const { pair,theme } = props;
  const onLoadScriptRef = useRef();
  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }
      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
      return () => onLoadScriptRef.current = null;
      function createWidget() {
        if (document.getElementById('tradingview_bc691') && 'TradingView' in window) {
          new window.TradingView.widget({
            width: "100%",
            height: 530,
            autosize: true,
            symbol: `BINANCE:${pair}.P`,
            interval: "D",
            timezone: "Etc/UTC",
            theme: theme == 'dark-new' ? "Dark" : "Light",
            locale: "en",
            toolbar_bg: "#010215",
            backgroundColor:theme == 'dark-new' ? "#010215" : "#ffffff" , 
            gridColor: theme == 'dark-new' ? "#010215" : "#ffffff", 
            hide_volume: false,
            hide_top_toolbar: false,
            withdateranges: false,
            hide_legend: false,
            allow_symbol_change: false,
            enablezoom: true,
            showMA: false,
            hide_side_toolbar: false, 
            container_id: "tradingview_bc691",
            overrides: {
              'mainSeriesProperties.areaStyle.color2': '#010215',
              'mainSeriesProperties.areaStyle.color1': '#010215',
            },
          });
        }
      }
    },
    [pair,theme]
  );

  return (
    <div className='child-graph widget-container'>
      <div className='tradingview-widget-container' style={{ height: "65vh", width: "100%", borderRadius: "20px" }}>
        <div id='tradingview_bc691' style={{ height: "calc(100% - 32px)", width: "100%" }} />
      </div>
    </div>
  );
};