import React, { useState, useEffect } from "react";
import "../assets/style-coin-list.css";
import NavbarOne from "../Pages/siteTheme/NavbarOne";
import Footer from "../Pages/siteTheme/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { makeRequest } from "../core/services/v1/request";
import Config from '../core/config/index';
import * as Yup from "yup";
import { toast } from '../core/lib/toastAlert';

export default function Withdraw(props) {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [netList, setNetList] = useState([]);
    const [otherOptionSelected, setOtherOptionSelected] = useState(false);
    useEffect(() => {
        networkList()
    }, []);
    const networkList = async () => {
        let params = {
            method: "GET",
            url: `${Config.V1_API_URL}user/getNetworkList`
        }
        const response = await makeRequest(params);
        console.log("networkList--", response);
        if (Array.isArray(response.message)) {
            // Filter out undefined or null values and sort the array alphabetically by coinName
            const coinsToFilter = ["Coin", "ERC20", "TRC20", "BEP20", "DCX20"];
            const sortedList = response.message
                .filter(item => item && item.basecoin && coinsToFilter.includes(item.basecoin))
                .sort((a, b) => a.basecoin.localeCompare(b.basecoin));
            setNetList(sortedList);
        }
    }
    const handleNetworkTypeChange = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption === 'Other') {
            setOtherOptionSelected(true);
            setFieldValue('networkType', ''); // Clear the networkType field
        } else {
            setOtherOptionSelected(false);
            handleChange(event);
        }
    };
    const { handleChange, handleBlur, handleSubmit, touched, errors, values, setFieldValue } = useFormik({
        initialValues: {
            email: "",
            contactPersonName: "",
            projectName: "",
            abbrevation: "",
            siteUrl: "",
            networkType: "",
            contactPersonEmail: "",
            contactPersonTelegram: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Please enter valid email').required("Email field is required!"),
            contactPersonName: Yup.string().required(),
            projectName: Yup.string().required(),
            abbrevation: Yup.string().required(),
            siteUrl: Yup.string()
                .matches(
                    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/,
                    'Invalid URL format. Ex. https://www.example.com'
                )
                .required('Official Site URL is required. Ex. https://www.example.com'),
            networkType: Yup.string().notOneOf(["Select Network"], 'Please select a valid network type.').required('Network Type is required.'),
            contactPersonEmail: Yup.string().email('Please enter valid email').required("Email field is required!"),
            contactPersonTelegram: Yup.string().matches(/^(https?:\/\/)?(www\.)?(t\.me\/|telegram\.me\/)?(@)?[a-zA-Z0-9_]{5,32}$/, "Invalid Telegram URL or username")
                .required("Telegram name field is required!"),
        }),
        onSubmit: async (value) => {
            const payload = {
                email: values.email,
                contactPersonName: values.contactPersonName,
                projectName: values.projectName,
                abbrevation: values.abbrevation,
                siteUrl: values.siteUrl,
                networkType: values.networkType,
                contactPersonEmail: values.contactPersonEmail,
                contactPersonTelegram: values.contactPersonTelegram,
                coinName: state.data.coinName,
                contactNumber: state.data.contact
            }

            let params = {
                method: "POST",
                url: `${Config.V1_API_URL}user/addTokenCoin`,
                data: payload
            }
            const response = await makeRequest(params);
            toast({ type: "success", message: "Details submitted Successfully" });
            navigate("/coin-listing")
        }
    })

    return (
        <div className="coinlist">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="deposit-page-top-banner">
                <section className="whl-gtex-gg-form-ctn-01">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mx-auto my-4 getx-form-whl-sec-001">
                                <div className="gtex-gg-form-ctn-01">
                                    <h2 className="gg-ctn-content-01">Join BitNevex today!</h2>
                                    <p>Fill out the form to get started. Your information is private but may be disclosed later according to our policy. Please submit only one application per project.  Kindly don't reapply. </p>
                                    <p><b>Note:</b> Due to the high volume of applications, we can only provide feedback if your project is at the right stage.</p>
                                    <p className="my-2"><span className="text-danger me-1">*</span>Indicates required question</p>
                                    <form className="my-3" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">Email<span className="text-danger ms-1">*</span></p>
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Your email address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                value={values.email || ""}
                                            />
                                            {
                                                touched.email && errors.email && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.email}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">Project contact person name:
                                                <span className="text-danger ms-1">*</span></p>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Your answer"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="contactPersonName"
                                                value={values.contactPersonName}
                                            />
                                            {
                                                touched.contactPersonName && errors.contactPersonName && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.contactPersonName}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">Project Name <span className="text-danger">*</span></p>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Your answer"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.projectName}
                                                name="projectName"
                                            />
                                            {
                                                touched.projectName && errors.projectName && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.projectName}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">
                                                Token/Coin Abbreviation (Ticker):
                                                <span className="text-danger ms-1">*</span>
                                            </p>
                                            <textarea
                                                class="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows="3"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.abbrevation}
                                                name="abbrevation"
                                            ></textarea>
                                            {
                                                touched.abbrevation && errors.abbrevation && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.abbrevation}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">Official Site URL:
                                                <span className="text-danger ms-1">*</span></p>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Your answer"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.siteUrl}
                                                name="siteUrl"
                                            />
                                            {
                                                touched.siteUrl && errors.siteUrl && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.siteUrl}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4 whl-ntw-typ-01">
                                            <p className="ido-active-text-2 mb-1">Network Type
                                                <span className="text-danger ms-1">*</span></p>
                                            <select
                                                className="form-select"
                                                placeholder="Your answer"
                                                option={netList}
                                                onChange={handleNetworkTypeChange}
                                                onBlur={handleBlur}
                                                value={otherOptionSelected ? 'Other' : values.networkType}
                                                name="networkType"
                                            >
                                                <option className=" new-select-options">Select Network</option>
                                                {
                                                    netList.map((data) => {
                                                        return (
                                                            <option className="new-select-options" value={data.basecoin}>
                                                                {data.basecoin}
                                                            </option>
                                                        )
                                                    })
                                                }
                                                <option className="new-select-options" >Other</option>
                                            </select>
                                            {otherOptionSelected && (
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    placeholder="Enter Coin Name"
                                                    name="networkType"
                                                    value={values.networkType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            )}
                                            {
                                                touched.networkType && errors.networkType && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.networkType}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">Project contact person email:
                                                <span className="text-danger ms-1">*</span></p>
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Your answer"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.contactPersonEmail}
                                                name="contactPersonEmail"
                                            />
                                            {
                                                touched.contactPersonEmail && errors.contactPersonEmail && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.contactPersonEmail}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="mb-4">
                                            <p className="ido-active-text-2 mb-1">Project contact person Telegram account:
                                                <span className="text-danger ms-1">*</span></p>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Your answer"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.contactPersonTelegram}
                                                name="contactPersonTelegram"
                                            />
                                            {
                                                touched.contactPersonTelegram && errors.contactPersonTelegram && (
                                                    <small className="invalid-email error password-text-33">
                                                        {errors.contactPersonTelegram}
                                                    </small>
                                                )
                                            }
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" disabled="" class="nxt-form-btn"> Submit </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



            <Footer />
        </div>
    );
}


