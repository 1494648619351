import React, { useEffect, useState } from "react";
import "../../assets/competition.css";
import { GoChevronLeft } from "react-icons/go";
import NavbarOne from "../../Pages/siteTheme/NavbarOne";
import Footer from "../../Pages/siteTheme/Footer";
import TradingCompetition from "../../assets/images/Trading-Competition.gif";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useContextData } from "../../core/context/index";
import Config from "../../core/config/index";
import { useNavigate } from 'react-router-dom'
import sharewithfriends from '../../assets/images/sharewith friends.png';
import Countdown from 'react-countdown';
import moment, { min } from 'moment';
import Price1 from "../../assets/images/price1.png";
import Price2 from "../../assets/images/price2.png";
import notFound from "../../assets/images/file.png";
import { showEmail } from "../../core/helper/date-format";

export default function TradingcompetitiondashboardLive(props) {
  const [currencyssymbol, setcurrencyssymbol] = useState("");
  const [currencyspair, setcurrencyspair] = useState("");
  const [winnerslist, setwinnerslist] = useState([]);
  const [prizelistlength, setprizelistlength] = useState("");
  const [tradedashboard, settradedashboard] = useState([]);
  const [abouttour, setabouttour] = useState("");
  const [timer, setTimer] = useState([0]);
  const [cmsdisclaimer, setcmsdisclaimer] = useState("");
  const [totalprizepool, settotalprizepool] = useState("");
  const [datepool, setdatepool] = useState([]);
  const [livebannerimage, setlivebannerimage] = useState("");
  const [enddatetimer, setenddatetimer] = useState("");
  const [currentTime, setCurrentTime] = useState();
  const [countDown, setCountDown] = useState({});
  const { myProfile } = useContextData();
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const columns = [
    {
      name: "Rank",
      selector: (row) => row.rank,
      sortable: true,
    },
    {
      name: "Rewards",
      selector: (row) => row.prizepool,
    },
  ];

  const columnsdashboard = [
    {
      name: "Rank",
      selector: (row) => {
        if (row.serial === 1) {
          return (
            <div className="d-flex align-items-center">
              {/* <p className="mb-0 me-1">{row.serial}</p> */}
              <img src={Price1} style={{ height: "30px", width: "30px" }} alt="Price 1" />
            </div>
          );
        } else if (row.serial === 2) {
          return (
            <div className="d-flex align-items-center">
              {/* <p className="mb-0 me-1">{row.serial}</p> */}
              <img src={Price2} style={{ height: "30px", width: "30px" }} alt="Price 2" />
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center">
              <p className="mb-0 me-1">{row.serial}</p>
            </div>
          );
        }
      },
      width: "80px",
    },
    {
      name: "Email",
      selector: (row) => showEmail(row.useremail),
      width: "240px",
    },
    {
      name: "Trades",
      selector: (row) => row.count,
    },
    {
      name: "Volume",
      selector: (row) => row.totalvolumeuser.toFixed(8),
    },
  ];

  createTheme(
    "solarized",
    {
      context: {
        background: "red",
        text: "red",
      },
      divider: {
        default: "#ccc",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      striped: {
        default: "red",
      },
    },
    "dark"
  );

  const getreferral = async () => {
    const splitpath = window.location.href.split("/");
    const currencySymbol = splitpath[4];
    const compid = state.competitionId;
    const setdata = { currencySymbol, compid };
    const getcompetiton = await axios.post(
      `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcurrencycompdash`,
      setdata
    );
    setcurrencyssymbol(getcompetiton.data.comp[0]?.prizetoken);
    const firstarr = getcompetiton.data.comp;
    const secondarr = getcompetiton.data.curren;

    setlivebannerimage(firstarr[0]?.tradingdashimage);
    const mergedArr = firstarr.map((obj1) => {
      const obj2 = secondarr.find(
        (obj2) => obj2.currencySymbol === obj1.tokensymbol
      );
      if (obj2) {
        return { ...obj1, ...obj2 };
      }
      return obj1;
    });
    setprizelistlength(mergedArr[0]?.winnerslist.length);
    const UTCHours = new Date(mergedArr[0]?.tokenenddate).getUTCHours();
    const UTCMinutes = new Date(mergedArr[0].tokenenddate).getUTCMinutes();
    const formattedEndHours = (UTCHours % 12 || 12).toString().padStart(2, '0');
    const formattedEndMinutes = UTCMinutes.toString().padStart(2, '0');
    const periodend = UTCHours >= 12 ? "PM" : "AM";
    const timeStringend = `${formattedEndHours}:${formattedEndMinutes} ${periodend}`;
    const UTCStartHours = new Date(mergedArr[0]?.tokenstartdate).getUTCHours();
    const UTCStartMinutes = new Date(mergedArr[0]?.tokenstartdate).getUTCMinutes();
    const formattedHours = (UTCStartHours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = UTCStartMinutes.toString().padStart(2, '0');
    // Determine AM or PM
    const period = UTCStartHours >= 12 ? "PM" : "AM";
    const timeStringstart = `${formattedHours}:${formattedMinutes} ${period}`;
    var userenddate = new Date(mergedArr[0]?.tokenenddate).getUTCDate();
    var userstartdate = new Date(mergedArr[0]?.tokenstartdate).getUTCDate();
    const monthNumberstart = new Date(mergedArr[0]?.tokenenddate).getUTCMonth();
    const monthNumberend = new Date(mergedArr[0]?.tokenenddate).getUTCMonth();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const monthNamestart = monthNames[monthNumberstart];
    const monthNameend = monthNames[monthNumberend];
    const mytokenendDate = new Date(Date.UTC(
      new Date(mergedArr[0]?.tokenenddate).getUTCFullYear(),
      new Date(mergedArr[0]?.tokenenddate).getUTCMonth(),
      new Date(mergedArr[0]?.tokenenddate).getUTCDate(),
      new Date(mergedArr[0]?.tokenenddate).getUTCHours(),
      new Date(mergedArr[0]?.tokenenddate).getUTCMinutes(),
      new Date(mergedArr[0]?.tokenenddate).getUTCSeconds())).getTime()
    const currentUnixTime = Date.now();
    const totalSeconds = mytokenendDate - currentUnixTime;
    if (totalSeconds >= 0) {
      const days = Math.floor(totalSeconds / 3600 / 24);
      const hours = Math.floor(totalSeconds / 3600) % 24;
      const mins = Math.floor(totalSeconds / 60) % 60;
      const seconds = Math.floor(totalSeconds) % 60;
      setTimer({ days, hours, mins, seconds });
      setCountDown({ days, hours, mins, seconds })
      setwinnerslist(mergedArr[0]?.winnerslist);
      setenddatetimer(mergedArr[0]?.tokenenddate);
      setabouttour(mergedArr[0]?.tokendescription);
      settotalprizepool(mergedArr[0]?.prizepool);
      setdatepool({
        userstartdate,
        userenddate,
        monthNamestart,
        monthNameend,
        timeStringend,
        timeStringstart,
      });
    }
  };

  useEffect(() => {
    const getpairvolume = async () => {
      try {
        const splitpath = window.location.href.split("/");
        const currencypair = splitpath[5];
        const compid = state.competitionId;
        const tradepair = { currencypair, compid };
        const tradespair = await axios.post(
          `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolumeemail`,
          tradepair
        );
        const arr1 = tradespair.data.winnerlist;
        const arr2 = tradespair.data.arraysvol;
        const arr3 = tradespair.data.countarrs;
        setcurrencyssymbol(arr2[0]?.prizetoken);
        const sortedArr = arr3
          .sort((a, b) => b.totalvolumeuser - a.totalvolumeuser)
          .map((item, index) => ({ ...item, serial: index + 1 }));
        const foundObj = sortedArr.find(
          (obj) => obj.useremail === myProfile?.email
        );
        if (foundObj) {
          const index = sortedArr.indexOf(foundObj);
          sortedArr.splice(index, 1);
          sortedArr.unshift(foundObj);
        }

        settradedashboard(sortedArr);
      } catch (e) {
        console.log("err");
      }
    };
    const getcmsdata = async () => {
      const getcms = await axios.get(
        `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcmstandc`
      );
      if (getcms && getcms.data && getcms.data.length > 0) {
        setcmsdisclaimer(getcms.data[1]?.description);
      }

      const splitpath = window.location.href.split("/");
      const currencySymbol = splitpath[4];
      const currencyPair = splitpath[5];
      setcurrencyspair(currencyPair);
    };
    getpairvolume();
    getcmsdata();
  }, [myProfile]);

  useEffect(() => {
    getreferral();
  }, []);

  const handleClick = () => {
    var url = `${Config.FRONEND_URL}/prediction-trade/`;
    window.open(url, "_blank");
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>completed</span>;
    } else {
      return (
        <div class="countdown-container d-flex flex-wrap gap-4 justify-content-center text-center">
          <div class="countdown-el days-c">
            <p class="big-text">{days}</p>
            <span>DAYS</span>
          </div>
          <div class="countdown-el hours-c">
            <p class="big-text">{hours}</p>
            <span>HOURS</span>
          </div>
          <div class="countdown-el mins-c">
            <p class="big-text">{minutes}</p>
            <span>MINS</span>
          </div>
          <div class="countdown-el seconds-c">
            <p class="big-text">{seconds}</p>
            <span>SECONDS</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <a href="/trading-competition">
                  <GoChevronLeft className="deposit-back-button-icon" />
                  <span className="deposit-text-1">Back</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="trading-competion-section py-5">
        <div className="container-fluid col-xl-10 col-lg-12 col-md-12 col-sm-8">
          <div className="row row-cols-lg-2 row-cols-1 justify-content-between">
            <div className="col-lg-5">
              <div className="trading-competion-section-1">
                <p className="mb-0 competion-text-1">ENDS IN</p>
                <Countdown
                  date={new Date(enddatetimer)}
                  renderer={renderer}
                />
                <p className="competion-text-2 my-4">{currencyssymbol} Token</p>
                <div className="row">
                  <div className="col-lg-5">
                    <p className="competion-text-3">Prize pool</p>
                    <p className="competion-text-4">
                      {totalprizepool} {currencyssymbol}
                    </p>
                  </div>
                  <div className="col-lg-7">
                    <p className="competion-text-3">Dates</p>
                    <p className="competion-text-4">
                      {datepool.monthNamestart} {datepool.userstartdate}{" "}
                      {datepool.timeStringstart} UTC - {datepool.monthNameend}{" "}
                      {datepool.userenddate} {datepool.timeStringend} UTC
                    </p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <p className="competion-text-3">Pairs</p>
                    <p className="competion-text-5">
                      <span 
                        style={{ cursor: "pointer" }} 
                        onClick={() => handleClick()}
                      >
                        {currencyspair}
                      </span>
                    </p>
                    <span>
                      <button
                        className="btn click-here-button mt-3 "
                        onClick={() => handleClick()}
                        style={{ cursor: "pointer" }}
                      >
                        Click Here
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="trading-competion-section-1 mt-4">
                <div className="row">
                  <div className="col-lg-6">
                    <p className="competion-text-1 mb-0">PRIZE FUND</p>
                  </div>
                  <div className="col-lg-6 text-lg-end">
                    <p className="competion-text-1 mb-0">
                      {totalprizepool} {currencyssymbol}
                    </p>
                  </div>
                </div>
                <div className="trading-competion-table-1">
                  <DataTable
                    columns={columns}
                    data={winnerslist}
                    defaultSortFieldId
                    noDataComponent={
                      <div className="text-center mt-3">
                        <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 55, height: 55 }} />
                        <br />
                        <span className="text-center">No Data Available</span>
                      </div>
                    }
                    pagination={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                    highlightOnHover
                    theme="solarized"
                    persistTableHead
                  />
                </div>
              </div>
              <div className="trading-competion-section-1 mt-4">
                <div className="row">
                  <div className="col">
                    <p className="competion-text-1 mb-3">ABOUT</p>
                    <div
                      dangerouslySetInnerHTML={{ __html: abouttour }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="trading-competition-dashboard-column-2-bg"
                style={{ background: `url(${livebannerimage})` }}
              >
                <div className="row align-items-center justify-content-start">
                  <div className="col-lg-5 mx-lg-4">
                    {/* <p className="competion-text-7 mb-3">{currencyssymbol} Token ({currencyssymbol})</p> */}
                    <div className="trading-competion-section-4">
                      <h5 className="competion-text-2 mt-0 mb-2">Prize pool</h5>
                      <h4 className="mb-0">
                        {totalprizepool} <span>{currencyssymbol}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <p className="competion-text-1 mb-3 mt-2">LEADERBOARD</p>
              <div className="trading-competion-section-1">
                <div className="row">
                  <div className="col">
                    <div className="trading-competion-table-1">
                      <DataTable
                        columns={columnsdashboard}
                        data={tradedashboard}
                        defaultSortFieldId
                        noDataComponent={
                          <div className="text-center mt-3">
                            <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 55, height: 55 }} />
                            <br />
                            <span className="text-center">No Data Available</span>
                          </div>
                        }
                        pagination={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                        highlightOnHover
                        theme="solarized"
                        persistTableHead
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="trading-competion-section-1 mt-4">
                <div className="row">
                  <div className="col">
                    <p className="competion-text-1 mb-3">DISCLAIMER</p>
                    <div
                      className="row"
                      dangerouslySetInnerHTML={{ __html: cmsdisclaimer }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
