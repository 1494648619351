import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import {
  AiFillEdit,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineSearch,
} from "react-icons/ai";
import { Modal, Button, Form } from "react-bootstrap";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";
import * as yup from "yup";
import { Formik } from "formik";
import $ from "jquery";
import { useContextData } from "../core/context/index";
import { dateFormat, showEmail } from "../core/helper/date-format";
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import WalletListDashboardComp from "./separate/WalletListDashboardComp";
import PairListComp from "./separate/PairListComp";
import { toast } from "../core/lib/toastAlert";
import { getCookie, setCookie } from "../core/helper/cookie";
import DatatableWallet from './DatatableWallet';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

const validationSchema = yup.object({
  username: yup
    .string("Please enter the username")
    .required("Username is required"),
});

export default function Dashboard() {

  const navigate = useNavigate();
  const { myProfile, setUserProfile } = useContextData();

  const initialValues = () => {
    return {
      username: myProfile && myProfile.username,
    };
  };

  const [tabName, setTabName] = useState("All");
  const [walletData, setWalletData] = useState([]);
  const [spotWalletData, setSpotWalletData] = useState([]);
  const [favPairData, setFavPairData] = useState([]);
  const [estimatedValue, setEstimatedValue] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [nicknameformOpen, setnicknameformOpen] = useState(false);
  const [balShow, setBalShow] = useState(
    getCookie("userBalShow") ? getCookie("userBalShow") : false
  );
  const [searchWallet, setSearchWallet] = useState("");

  useEffect(() => {
    localStorage.removeItem("networkbase")
    localStorage.removeItem("networkId")
    getWalletCurrency();
    // depositETHTRX();
  }, []);

  // async function depositETHTRX() {
  //   try {
  //     const params = {
  //       url: `${Config.V1_API_URL}wallet/depositETHTRX`,
  //       method: "GET",
  //     };
  //     const response = await makeRequest(params);
  //     getWalletCurrency();
  //   } catch (err) { }
  // }

  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        status = false,
        data = [],
        estimateINR = 0,
        estimateUSD = 0,
      } = response;
      setWalletData(data);
      setEstimatedValue({
        estimateINR,
        estimateUSD,
      });
    }
  }

  async function getSpotWalletCurrency(holdChk = "spot") {
    const params = {
      url: `${Config.V2_API_URL}wallet/getSpotHoldings?holdChk=` + holdChk,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response) {
      const { status = false, data = [] } = response;
      setWalletData(data);
    }
  }

  const onSubmit = async (values) => {
    setisLoading(true);
    const params = {
      url: `${Config.V1_API_URL}user/updateMyProfile`,
      method: "POST",
      body: values,
    };
    const response = await makeRequest(params);
    setisLoading(false);
    let type = "error";
    if (response.status) {
      type = "success";
      setnicknameformOpen(false);
      toast({ type, message: "Username Updated Successfully!" });
    } else {
      toast({ type, message: response.message });
    }
    setUserProfile();
    setisLoading(false);
  };

  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };

  const toNavigateFirstCurrency = (type = "") => {
    if (
      walletData &&
      walletData.length > 0 &&
      (type === "deposit" || type === "withdraw")
    ) {
      let firstData = walletData.filter((e) => e.curnType == "Crypto");
      if (firstData && firstData.length > 0) {
        navigate("/" + type + "/crypto/" + firstData[0].currencySymbol);
      }
    }
  };
  const handleSearch = (e) => {
    try {
      let searchVal = e.target.value;
      if (searchVal != "") {
        searchVal = searchVal.replace(/[^a-zA-Z0-9\.]/g, "");
        searchVal = searchVal !== "" ? searchVal.toUpperCase() : "";
      }
      setSearchWallet(searchVal);
    } catch (err) { }
  };
  return (
    <div>
      {/* tab one start  */}
      <div className="container-fluid px-0">
        <div className="row ">
          <div className="col-lg-1 text-lg-center">

            {" "}
            {myProfile &&
              myProfile.username &&
              myProfile &&
              myProfile.username.charAt(0) ? (
              <p className="custom-letterAvatar mx-lg-auto">
                {myProfile.username.charAt(0).toUpperCase()}
              </p>
            ) : (
              <img
                src={
                  myProfile && myProfile.profileimage != ""
                    ? myProfile.profileimage
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
                alt="logo"
                className="img-user"
              />
            )}
          </div>
          <div className="col-lg">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-12">
                  <p className="password-text-44 d-flex flex-wrap align-items-center">
                    <b>

                      UID :{myProfile ?
                        (<>
                          {
                            myProfile.uid
                          }
                        </>) : null}
                    </b>
                    {myProfile && myProfile.level ? (
                      <span className="btn-muted ms-1">
                        Level:{" "}
                        {myProfile.level}
                      </span>
                    ) : (
                      <span className="btn-muted ms-1">Level: Un-Verified User</span>
                    )}
                    {/* <AiFillEdit onClick={()=>setnicknameformOpen(true)}
                      // data-bs-toggle="modal"
                      // data-bs-target="#selectcoinModal" 
                    /> */}
                  </p>
                </div>
                {myProfile && (
                  <div className="col-md">
                    <small className="d-block tittle-text">
                      Two-Factor Authentication
                    </small>
                    <small
                      className={
                        "d-block data-info " +
                        (myProfile.tfaenablekey ? "color-green" : "color-red")
                      }
                    >
                      {myProfile.tfaenablekey ? "Enabled" : "Disabled"}
                    </small>
                  </div>
                )}
                <div className="col-md">
                  <small className="d-block tittle-text">
                    Identity Verification
                  </small>
                  {myProfile && myProfile.kycStatusDetail != undefined ? (
                    <>
                      {myProfile.kycStatusDetail.selfie?.status == 1 ? (
                        <small className="color-green d-block data-info">
                          Verified(3 0f 3 Verified)
                        </small>
                      ) : myProfile.kycStatusDetail.aadhaar?.status == 1 ? (
                        <small className="color-yellow d-block data-info">
                          Verified(2 0f 3 Verified)
                        </small>
                      ) : myProfile.kycStatusDetail.pan?.status == 1 ? (
                        <small className="color-blue d-block data-info">
                          Verified(1 0f 3 Verified)
                        </small>
                      ) : myProfile.kycStatusDetail.selfie?.status == 3 && myProfile.kycStatusDetail.aadhaar?.status == 3 && myProfile.kycStatusDetail.pan?.status == 3 ? (
                        <small className="color-blue d-block data-info">
                          Not Verified
                        </small>
                      ) : (
                        <small className="color-yellow d-block data-info">
                          Waiting For Approval
                        </small>
                      )}
                    </>
                  ) : (
                    <small className="color-green d-block data-info"></small>
                  )}
                </div>
                {/* <div className="col-md ">
                  <small className="d-block tittle-text">Twitter</small>
                  <small className="d-block data-info">not linked</small>
                </div> */}
                {myProfile &&
                  myProfile.lastLoginBy &&
                  myProfile.lastLoginBy.from && (
                    <div className="col-md ">
                      <small className="d-block tittle-text">
                        Last login by
                      </small>
                      <small className="d-block data-info">
                        {myProfile.lastLoginBy.from} -{" "}
                        {myProfile.lastLoginBy.from.toLowerCase() === "email"
                          ? showEmail(myProfile.lastLoginBy.val)
                          : myProfile.lastLoginBy.val}
                      </small>
                    </div>
                  )}
                <div className="col-md ">
                  <small className="d-block tittle-text">Last login time</small>
                  <small className="d-block data-info">
                    {dateFormat(myProfile && myProfile?.lastLoginTime)}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-5">
            <div className="conatiner-fluid">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="password-text-55">
                    Estimated Balance
                    {balShow ? (
                      <AiFillEye onClick={() => balShowHideCall()} className="cursor-pointer ms-1" />
                    ) : (
                      <AiFillEyeInvisible onClick={() => balShowHideCall()} className="cursor-pointer ms-1" />
                    )}
                  </h4>
                  <div>
                    {balShow ? (
                      <p>
                        <span>
                          <b className="border-dot password-text-44">
                            $ {estimatedValue.estimateUSD
                              ? estimatedValue.estimateUSD.toFixed(2)
                              : 0}{" "}

                          </b>
                        </span>
                        <span className="textCol">
                          {" "}
                          <b>
                            ≈ ₹
                            {estimatedValue.estimateINR
                              ? estimatedValue.estimateINR.toFixed(2)
                              : 0}
                          </b>
                        </span>
                      </p>
                    ) : (
                      <p>***Balance hidden***</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 text-md-center">
                  {/* onClick={() => navigate("/wallet")} */}
                  {/* <button className="unset btnfont mb-2 password-text-33 fw-bold">Wallet</button> */}
                  <button

                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold"
                    onClick={() => navigate("/my/wallet")}
                  >
                    Wallet
                  </button>
                  <button
                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold"
                    onClick={() => toNavigateFirstCurrency("deposit")}
                  >
                    Deposit
                  </button>
                  <button
                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold"
                    onClick={() => toNavigateFirstCurrency("withdraw")}
                  >
                    Withdraw
                  </button>
                </div>


                {/* <div className="col-lg-6">
                  <span className="text-info-css">7 Days </span>
                  <span className="ms-4 text-info-css"> 30 Days</span>
                </div>
                <div className="col-lg-6 ">
                  <button className="unset-btn mb-2 ">
                    {" "}
                    <SlPieChart /> Portfolio
                  </button>
                  <button className="unset-btn mb-2 ">
                    <SlChart /> Wallet Overview
                  </button>
                </div> */}
                <div className="col-lg-12">
                  {/* <div className="text-muted para-small">
                    <small>
                      The daily asset change data will be updated before 08:00 UTC the next day.
                    </small>
                  </div>
                  <div className="col-lg-6 ">
                    <Line data={data} className="line-chart" />
                  </div> */}
                  <div className="col-lg-12 mt-4">
                    {/* <h4>Wallet</h4> */}
                    {/* nav and tab start  */}
                    <DatatableWallet
                      balShow={balShow}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* tab one end  */}
    </div>
  );
}
