import React, { useEffect, useState } from "react";
import notFound from "../../assets/images/file.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat } from '../../core/helper/date-format';
import $ from "jquery";
import '../../pagination.css';
import DataTable from 'react-data-table-component';
import { useContextData } from "../../core/context";


export default function CompetitionTransactionHistory(){
    const [historyList, setHistoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPurchase, setTotalPurchase] = useState(0);
    const { myProfile } = useContextData()

    useEffect(() => {
        $(".status_change .dropdown-item").click(function () {
            const generateStatusClass = `${$(this).attr("data-class")}-status`;
            $(this)
                .closest(".status_dropdown")
                .attr("data-color", `${generateStatusClass}`);
        });
    }, []);

    useEffect(() => {
        getHistory();
    }, [currentPage, rowsPerPage, myProfile]);

    async function getHistory() {
        try {
            const params = {
                url: `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcompetitiontransactionuser`,
                method: 'POST',
                data: {
                    userId: myProfile._id,
                    limit: rowsPerPage,
                    page: currentPage
                }
            };
            const response = await makeRequest(params);
            console.log("response", response);
            if (response.status) {
                setHistoryList(response.complist);
                setTotalPurchase(response.total)
            }
        } catch (err) {
            console.log("Something Wrong!");
        }
    }

    const columns = [
        {
            name: "Sl.No",
            selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
            width: "70px",
        },
        {
            name: "Date",
            selector: row => dateFormat(row.datetime),
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
        },
        {
            name: "Old Balance",
            selector: row => row.oldbalance,
            sortable: true,
        },
        {
            name: "Currency Symbol",
            selector: row => row.currencysymbol,
            sortable: true,
        },
        {
            name: "Amount",
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: "Type",
            selector: row => row.type,
            sortable: true,
        },
    ];

    return (
        <div className="container deposit-fourth-section">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <DataTable
                        columns={columns}
                        data={historyList}
                        persistTableHead
                        pagination
                        paginationServer
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                        paginationTotalRows={totalPurchase}
                        onChangeRowsPerPage={newRowsPerPage => {
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(1);
                        }}
                        onChangePage={page => setCurrentPage(page)}
                        noDataComponent={
                            <div className="text-center py-2">
                                <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                                <br />
                                <span className="text-center">No Records Found</span>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
}

