import React, { useEffect, useState } from "react";
import "../../assets/competition.css";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import NavbarOne from "../../Pages/siteTheme/NavbarOne";
import Footer from "../../Pages/siteTheme/Footer";
import axios from "axios";
import { useContextData } from "../../core/context/index";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Config from "../../core/config/index";
import { makeRequest } from "../../core/services/v1/request";
import Countdown from "react-countdown";
import Banner1 from "../../assets/Nexchange-images/competition/banner-1.png";
import { dateFormat } from "../../core/helper/date-format";
import jwt_decode from "jwt-decode";
import { getCookie } from "../../core/helper/cookie";
import { CiCalendar, CiViewList } from "react-icons/ci";
import { PiTicketLight } from "react-icons/pi";
import { Link } from "react-router-dom";

export default function Tradingcompetitionbanner(props) {
  const [swipedata, setswipedata] = useState([]);
  const [cmsdes, setcmsdes] = useState("");
  const [cmsbannertitle, setcmsbannertitle] = useState("");
  const [cmsbannerdescription, setcmsbannerdescription] = useState("");
  const { myProfile } = useContextData();
  const [userLogin, setUserLogin] = useState(false);
  const [enddatetimer, setenddatetimer] = useState("");
  const [currentTime, setCurrentTime] = useState();
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = getCookie("userToken");
    setToken(token);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const loginChk = async (event) => {
    const token = getCookie("userToken");
    const decodedToken = token ? jwt_decode(token, { header: true }) : {};
    if (decodedToken.typ === "JWT") {
      setUserLogin(true);
    }
  };
  useEffect(() => {
    loginChk();
  }, [myProfile]);
  const gettotalvolume = async () => {
    try {
      const params = {
        url: `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolume`,
        method: "GET",
      };

      const params1 = {
        url: `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcurrencycompetion`,
        method: "GET",
      };
      const firstarr = await makeRequest(params);
      const secondarr = await makeRequest(params1);
      if (firstarr.length <= 0) {
        const monthpush = [];
        for (var i = 0; i < secondarr.length; i++) {
          var car = {};
          const mytokenendDates = new Date(secondarr[i].tokenenddate);
          const mytokenstartDate = new Date(secondarr[i].tokenstartdate);
          const mytokenendDate = new Date(secondarr[i].tokenenddate);
          const currentUnixTime = new Date();
          const currencypair = secondarr[i].currency;
          const compId = secondarr[i]._id;
          const tradepair = { currencypair, compId };
          const tradespair = await axios.post(
            `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolumeemail`,
            tradepair
          );
          if (
            currentUnixTime >= mytokenstartDate &&
            currentUnixTime <= mytokenendDate
          ) {
            const arr3 = tradespair.data.countarrs;
            if (myProfile?.email == undefined) {
              car["setuservolumne"] = 0;
            } else {
              const foundObjuservolume = arr3.find(
                (obj) => obj.useremail === myProfile?.email
              );
              car["setuservolumne"] =
                foundObjuservolume.totalvolumeuser.toFixed(2);
            }
            if (arr3 == undefined) {
              car["totalparticipant"] = 0;
            } else {
              var sortedArr = arr3
                .sort((a, b) => b.totalvolumeuser - a.totalvolumeuser)
                .map((item, index) => ({ ...item, serial: index + 1 }));
              car["totalparticipant"] = sortedArr.length;
            }
            const endTime = dateFormat(secondarr[i].tokenenddate);
            car["_id"] = secondarr[i]._id;
            car["tokensymbol"] = secondarr[i].tokensymbol;
            car["image"] = secondarr[i].image;
            car["prizepool"] = secondarr[i].prizepool;
            car["totalwinners"] = secondarr[i].totalwinners;
            car["pairname"] = secondarr[i].currency;
            car["competitionimage"] = secondarr[i].competitionimage;
            car["endtime"] = endTime;
            car["status"] = "Active";
            car["totalparticipant"] = sortedArr.length;
            car["enddatetimer"] = new Date(secondarr[i].tokenenddate);
            monthpush.push(car);
          } else if (currentUnixTime <= mytokenstartDate) {
            const currencypair = secondarr[i].currency;
            const compId = secondarr[i]._id;
            const tradepair = { currencypair, compId };
            const tradespair = await axios.post(
              `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolumeemail`,
              tradepair
            );
            const endTime = dateFormat(secondarr[i].tokenenddate);
            car["_id"] = secondarr[i]._id;
            car["tokensymbol"] = secondarr[i].tokensymbol;
            car["image"] = secondarr[i].image;
            car["prizepool"] = secondarr[i].prizepool;
            car["totalwinners"] = secondarr[i].totalwinners;
            car["pairname"] = secondarr[i].currency;
            car["competitionimage"] = secondarr[i].competitionimage;
            car["endtime"] = endTime;
            car["status"] = "Upcoming";
            car["totalparticipant"] = sortedArr.length;
            car["enddatetimer"] = new Date(secondarr[i].tokenenddate);
            car["totalparticipant"] = 1;
            car["setuservolumne"] = 0;
            monthpush.push(car);
          }
        }
        setswipedata(monthpush);
      } else {
        const monthpush = [];
        for (var i = 0; i < secondarr.length; i++) {
          var car = {};
          const mytokenendDates = new Date(secondarr[i].tokenenddate);
          setenddatetimer(mytokenendDates);
          const tokensuserstart = new Date(firstarr[i].dateTime);
          const mytokenstartDate =
            new Date(secondarr[i].tokenstartdate).getTime() / 1000;
          console.log(
            "mytokenstartDate",
            new Date(secondarr[i].tokenstartdate)
          );
          const mytokenendDate =
            new Date(secondarr[i].tokenenddate).getTime() / 1000;
          const currentUnixTime = new Date().getTime() / 1000;
          console.log(
            { currentUnixTime },
            { mytokenstartDate },
            { mytokenendDate },
            currentUnixTime >= mytokenstartDate,
            mytokenstartDate <= mytokenendDate
          );
          if (
            currentUnixTime >= mytokenstartDate &&
            currentUnixTime <= mytokenendDate
          ) {
            const currencypair = secondarr[i].currency;
            const compId = secondarr[i]._id;
            const tradepair = { currencypair, compId };
            const tradespair = await axios.post(
              `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/gettotalvolumeemail`,
              tradepair
            );
            const arr3 = tradespair.data.countarrs;
            if (myProfile?.email != undefined) {
              if (arr3 == undefined) {
                car["setuservolumne"] = 0;
              } else {
                const foundObjuservolume = arr3.find(
                  (obj) => obj.useremail === myProfile?.email
                );
                if (foundObjuservolume) {
                  car["setuservolumne"] =
                    foundObjuservolume.totalvolumeuser.toFixed(2);
                }
              }
            } else {
              car["setuservolumne"] = 0;
            }
            if (arr3 == undefined) {
              car["totalparticipant"] = 3;
            } else {
              var sortedArr = arr3
                .sort((a, b) => b.totalvolumeuser - a.totalvolumeuser)
                .map((item, index) => ({ ...item, serial: index + 1 }));
              car["totalparticipant"] = sortedArr.length;
            }
            const endTime = dateFormat(secondarr[i].tokenenddate);
            car["_id"] = secondarr[i]._id;
            car["tokensymbol"] = secondarr[i].tokensymbol;
            car["image"] = secondarr[i].image;
            car["tradeamount"] = firstarr[i].amount;
            car["usdprice"] = firstarr[i].usdPrice;
            car["pairname"] = secondarr[i].currency;
            car["prizepool"] = secondarr[i].prizepool;
            car["totalwinners"] = secondarr[i].totalwinners;
            car["competitionimage"] = secondarr[i].competitionimage;
            car["userid"] = firstarr[i].userId;
            car["endtime"] = endTime;
            car["status"] = "Active";
            car["enddatetimer"] = new Date(secondarr[i].tokenenddate);
            monthpush.push(car);
          } else if (currentUnixTime <= mytokenstartDate) {
            const startTime = dateFormat(secondarr[i].tokenstartdate);
            car["_id"] = secondarr[i]._id;
            car["tokensymbol"] = secondarr[i].tokensymbol;
            car["image"] = secondarr[i].image;
            car["prizepool"] = secondarr[i].prizepool;
            car["totalwinners"] = secondarr[i].totalwinners;
            car["pairname"] = secondarr[i].currency;
            car["competitionimage"] = secondarr[i].competitionimage;
            car["status"] = "Upcoming";
            car["startdate"] = startTime;
            car["totalparticipant"] = 0;
            car["setuservolumne"] = 0;
            car["enddatetimer"] = new Date(secondarr[i].tokenstartdate);
            monthpush.push(car);
          }
        }
        console.log("monthpush", monthpush);
        const countArr = Object.values(monthpush);
        countArr.forEach((item) => {
          let filtered = secondarr.find(
            (element) => (item.pairname = element.currency)
          );
          if (filtered) {
            item.currency = filtered.currency;
          }
        });
        // const mergedArr = countArr.map(obj1 => {
        //   const obj2 = secondarr.find(
        //     obj2 => obj2.currency === obj1.pairname
        //     );
        //   if (obj2) {
        //     return { ...obj1, ...obj2 };
        //   }
        //   return obj1;
        // });
        // console.log("mergedArr", mergedArr);
        const activeItems = countArr.filter((item) => item.status === "Active");
        const nonActiveItems = countArr.filter(
          (item) => item.status !== "Active"
        );
        const newmergedArr = [...activeItems, ...nonActiveItems];
        console.log("newmergedArr", newmergedArr);
        setswipedata(newmergedArr);
      }
    } catch (error) {}
  };
  useEffect(() => {
    gettotalvolume();
  }, [myProfile]);

  useEffect(() => {
    const getcmsdata = async () => {
      const getcms = await axios.get(
        `${Config.TRADINGCOMPETITION_V1_URL}tradecompetion/getcmstandc`
      );
      setcmsdes(getcms.data[0]?.description);
      setcmsbannertitle(getcms.data[2]?.title);
      setcmsbannerdescription(getcms.data[2]?.description);
    };
    getcmsdata();
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed == true) {
      gettotalvolume();
    } else {
      return (
        <div className="col ">
          <div className="ms-auto left-days-card-sectio mt-2">
            <div class="d-flex flex-row gap-1">
              <div class="" style={{ width: "16%" }}>
                <h4 className="fw-bold mb-0">{days}</h4>
                <span className="fs-12">Days</span>
              </div>
              <div class="" style={{ width: "16%" }}>
                <h4 className="fw-bold mb-0">{hours}</h4>
                <span className="fs-12">Hours</span>
              </div>
              <div class="" style={{ width: "16%" }}>
                <h4 className="fw-bold mb-0">{minutes}</h4>
                <span className="fs-12">Minutes</span>
              </div>
              <div class="" style={{ width: "16%" }}>
                <h4 className="fw-bold mb-0">{seconds}</h4>
                <span className="fs-12">Seconds</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const moreDetails = async (symbol, currency, id) => {
    navigate(`/trading-competition-dashboard-live/${symbol}/${currency}`, {
      state: { competitionId: id },
    });
  };

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      {/* <div className="deposit-page-top-banner ">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <a href="/">
                  <GoChevronLeft className="deposit-back-button-icon" />
                  <span className="deposit-text-1">Back</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section className="trading-competition-banner-section py-5 deposit-page-top-banner tournament-active-nav-class trading-comp-home-1">
        <div className="container-fluid col-lg-10">
          <div className="row justify-content-center align-items-center flex-lg-row-reverse">
            <div className="col-lg-4 text-center">
              <img src={Banner1} alt="Banner1" className="" />
            </div>
            <div className="col-lg-6">
              <h1 className="trading-competition-text-1 mb-4 text-start">
                {cmsbannertitle}
              </h1>
              <div
                className="trading-competition-text-2 text-start"
                dangerouslySetInnerHTML={{ __html: cmsbannerdescription }}
              ></div>
              {userLogin == false ? (
                <>
                  <button
                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold px-3 py-2"
                    onClick={() => navigate("/register")}
                  >
                    Register
                  </button>
                  <button
                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold px-3 py-2"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </button>
                </>
              ) : (
                <button
                  className="unset-unselected btnfont mb-2 password-text-33 fw-bold px-3 py-2"
                  onClick={() => navigate("/prediction-trade")}
                >
                  Participate Now
                </button>
              )}
            </div>
          </div>
        </div>
      </section>

      <div class="glow-effect-1"></div>
      <div class="glow-effect-2"></div>

      {/* =======================================DESIGN-CHANGE=========================================== */}
      <div className="container">
        {swipedata.length === 1 && (
          <p className="trading-competition-text-1 text-center">LIVE</p>
        )}
        {/* {swipedata.length === 0 && (
          <div className="text-center mt-4">
            <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
            <p className="mt-2">No data Available</p>
          </div>
        )} */}
        <div className="row row-cols-lg-3 row-cols-1 g-4 align-items-center">
          {swipedata.map((item) => (
            <div className="col" key={item.i}>
              <div className="post-d-card-1 border p-3">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-12 align-items-start mb-3">
                    <div className="d-flex flex-row">
                      <div className="d-flex align-items-center">
                        <img
                          className="trading-competition-image-1"
                          src={item.image}
                          alt="slide1"
                        />
                        <span className="trading-competition-text-3 ms-2">
                          {item.currency}
                        </span>
                      </div>
                      <p className="active-button-styling mb-0 mx-2 top-0 ms-auto">
                        {item.status}
                      </p>
                    </div>
                    {/* <div className="trading-competition-banner-column-2-bg " style={{ backgroundImage: `url(${item.image})` }}> </div>*/}
                  </div>
                  <div>
                    <div className="post-d-card-1-grd p-1">
                      <div className="d-flex flex-row gap-3 align-items-center mt-1">
                        <div>
                          <h2>
                            <CiCalendar />
                          </h2>
                        </div>
                        <div>
                          {item.status == "Active" ? (
                            <p class="f-14 mb-0">
                              Ends on :{" "}
                              <span class="f-14 fw-600  mb-0">
                                {item.endtime} UTC
                              </span>
                            </p>
                          ) : (
                            <p class="f-14 mb-0">
                              Starts on :{" "}
                              <span class="f-14 fw-600  mb-0">
                                {item.startdate} UTC
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-row gap-3 align-items-center mt-1">
                        <div>
                          <h2>
                            <PiTicketLight />
                          </h2>
                        </div>
                        <div>
                          <p class="f-12 mb-0">
                            Prize Pool :{" "}
                            <span class="f-14 fw-600  mb-0">
                              {item.prizepool} {item.prizetoken}
                            </span>
                          </p>
                          <p class="f-12 mb-0">
                            Places :{" "}
                            <span class="f-16 fw-600  mb-0">
                              {item.totalwinners}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-row gap-3 align-items-center mt-1">
                        <div>
                          <h2>
                            <CiViewList />
                          </h2>
                        </div>
                        <div>
                          <p class="f-12 mb-0">
                            My Trade Volume :{" "}
                            <span class="f-14 fw-600 mb-0">
                              {item.setuservolumne ? item.setuservolumne : "-"}
                            </span>
                          </p>

                          <p class="f-12 mb-0">
                            Total participants :{" "}
                            <span class="f-16 fw-600 mb-0">
                              {item.totalparticipant}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4 pb-2">
                    <p class="f-12 mb-0">
                      {item.status == "Active" && "Ends in:"}
                      {item.status == "Upcoming" && "Time Until Start:"}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <Countdown
                        date={new Date(item.enddatetimer)}
                        renderer={renderer}
                      />
                    </div>
                    <div className="d-flex flex-lg-row flex-column justify-content-end align-items-lg-center pt-3">
                      {item.status == "Active" ? (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            moreDetails(
                              item.tokensymbol,
                              item.currency,
                              item._id
                            )
                          }
                          class=" mb-1 view-m-btn"
                        >
                          View More
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col">
            <h4 className="mb-4 fw-bold">
              TRADING COMPETITION TERMS AND CONDITIONS
            </h4>
            <div className="row">
              <div dangerouslySetInnerHTML={{ __html: cmsdes }}></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
